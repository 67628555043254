<template>
  <div>
    <b-modal
        id="modal-personAddress-list-edit"
        size="lg"
        centered
        title="Kişi Adres Bilgisini Düzenle"
        ok-title="Kaydet"
        ok-variant="success"
        cancel-title="İptal"
        cancel-variant="outline-secondary"
        @ok.prevent="update"
        @hidden="clearForm">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Adres Bilgisi</label>
            <!--:class="{ 'is-invalid': $v.form.documentTypeId.$error }"-->
            <select
                :class="{ 'is-invalid': $v.form.addressTypeId.$error }"
                v-select
                class="form-control"
                v-model="form.addressTypeId"
            >
              <option :value="null">Seçiniz</option>
              <option
                  :value="item.id"
                  v-for="(item, index) in this.addressTypeList"
                  :key="index"
              >
                {{ item.text }}
              </option>
            </select>
            <template v-if="$v.form.addressTypeId.$error">
              <small class="text-danger" v-if="!$v.form.addressTypeId.required">
                Bu alan boş bırakılamaz.
              </small>
            </template>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label>Adres İsmi</label>
            <input
                type="text"
                :class="{ 'is-invalid': $v.form.name.$error }"

                class="form-control"
                v-model="form.name"
                maxlength="100"
            />
            <template v-if="!this.$v.form.name.minLength">
              <small class="text-danger" v-if="!$v.form.name.minLength">
                Adres adı adı en az 2 karakter içermelidir.</small
              >
            </template>

            <template v-if="$v.form.name.$error">
              <small class="text-danger" v-if="!$v.form.name.required">
                Bu alan boş bırakılamaz.
              </small>
            </template>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label>Açıklama</label>
            <input
                type="text"
                :class="{ 'is-invalid': $v.form.description.$error }"

                class="form-control"
                v-model="form.description"
                maxlength="100"
            />
            <template v-if="!this.$v.form.description.minLength">
              <small class="text-danger" v-if="!$v.form.description.minLength">
                Açıklama en az 5 karakter içermelidir.</small
              >
            </template>

            <template v-if="$v.form.description.$error">
              <small class="text-danger" v-if="!$v.form.description.required">
                Bu alan boş bırakılamaz.
              </small>
            </template>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Alıcı Adı</label>
            <input
                type="text"
                :class="{ 'is-invalid': $v.form.receiverName.$error }"

                class="form-control"
                v-model="form.receiverName"
                maxlength="100"
            />
            <template v-if="!this.$v.form.receiverName.minLength">
              <small class="text-danger" v-if="!$v.form.receiverName.minLength">
                Alıcı adı en az 2 karakter içermelidir.</small
              >
            </template>


          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Alıcı Soyadı</label>
            <input
                type="text"
                :class="{ 'is-invalid': $v.form.receiverSurname.$error }"

                class="form-control"
                v-model="form.receiverSurname"
                maxlength="100"
            />
            <template v-if="!this.$v.form.receiverSurname.minLength">
              <small class="text-danger" v-if="!$v.form.receiverSurname.minLength">
                Alıcı adı en az 2 karakter içermelidir.</small
              >
            </template>


          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label>E-Mail</label>
            <input
                type="text"
                :class="{ 'is-invalid': $v.form.eMail.$error }"

                class="form-control"
                v-model="form.eMail"
                maxlength="100"
            />
            <template v-if="!this.$v.form.eMail.minLength">
              <small class="text-danger" v-if="!$v.form.eMail.minLength">
                eMail en az 5 karakter içermelidir.</small
              >
            </template>


          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Telefon Numarası</label>
            <input
                type="text"
                :class="{ 'is-invalid': $v.form.receiverName.$error }"

                class="form-control"
                v-model="form.mobileNumber"
                maxlength="100"
            />
            <template v-if="!this.$v.form.mobileNumber.minLength">
              <small class="text-danger" v-if="!$v.form.mobileNumber.minLength">
                Telefon numarası 10 karakter içermelidir.</small
              >
            </template>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Adres</label>
            <input
                type="text"
                :class="{ 'is-invalid': $v.form.address.$error }"

                class="form-control"
                v-model="form.address"
                maxlength="100"
            />
            <template v-if="!this.$v.form.address.minLength">
              <small class="text-danger" v-if="!$v.form.address.minLength">
                Adress en az 25 karakter içermelidir.</small
              >
            </template>

            <template v-if="$v.form.address.$error">
              <small class="text-danger" v-if="!$v.form.address.required">
                Bu alan boş bırakılamaz.
              </small>
            </template>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Ülke Bilgisi</label>
            <!--:class="{ 'is-invalid': $v.form.documentTypeId.$error }"-->
            <select
                :class="{ 'is-invalid': $v.form.countryId.$error }"
                v-select
                class="form-control"
                v-model="form.countryId"
            >
              <option :value="null">Seçiniz</option>
              <option
                  :value="item.id"
                  v-for="(item, index) in this.countryList"
                  :key="index"
              >
                {{ item.text }}
              </option>
            </select>
            <template v-if="$v.form.countryId.$error">
              <small class="text-danger" v-if="!$v.form.countryId.required">
                Bu alan boş bırakılamaz.
              </small>
            </template>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label>Şehir Bilgisi</label>
            <!--:class="{ 'is-invalid': $v.form.documentTypeId.$error }"-->
            <select
                :class="{ 'is-invalid': $v.form.cityId.$error }"
                v-select
                class="form-control"
                v-model="form.cityId"
            >
              <option :value="null">Seçiniz</option>
              <option
                  :value="item.id"
                  v-for="(item, index) in this.cityList"
                  :key="index"
              >
                {{ item.text }}
              </option>
            </select>
            <template v-if="$v.form.cityId.$error">
              <small class="text-danger" v-if="!$v.form.cityId.required">
                Bu alan boş bırakılamaz.
              </small>
            </template>
          </div>
        </div>

      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label>Posta Kodu</label>
            <input
                type="text"
                :class="{ 'is-invalid': $v.form.postalCode.$error }"

                class="form-control"
                v-model="form.postalCode"
                maxlength="100"
            />
            <template v-if="!this.$v.form.postalCode.minLength">
              <small class="text-danger" v-if="!$v.form.postalCode.minLength">
                Posta kodu en az 5 karakter içermelidir.</small
              >
            </template>


          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Semt</label>
            <input
                type="text"
                :class="{ 'is-invalid': $v.form.district.$error }"

                class="form-control"
                v-model="form.district"
                maxlength="100"
            />
            <template v-if="!this.$v.form.district.minLength">
              <small class="text-danger" v-if="!$v.form.district.minLength">
                Semt en az 3 karakter içermelidir.</small
              >
            </template>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Şirket Adı</label>
            <input
                type="text"
                :class="{ 'is-invalid': $v.form.companyName.$error }"

                class="form-control"
                v-model="form.companyName"
                maxlength="100"
            />
            <template v-if="!this.$v.form.companyName.minLength">
              <small class="text-danger" v-if="!$v.form.companyName.minLength">
                Şirket Adı en az 2 karakter içermelidir.</small
              >
            </template>
          </div>
        </div>

      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label>Vergi NO </label>
            <input
                type="text"
                :class="{ 'is-invalid': $v.form.taxNumber.$error }"

                class="form-control"
                v-model="form.taxNumber"
                maxlength="100"
            />
            <template v-if="!this.$v.form.taxNumber.minLength">
              <small class="text-danger" v-if="!$v.form.taxNumber.minLength">
                Vergi No en az 11 karakter içermelidir.</small
              >
            </template>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Vergi Dairesi </label>
            <input
                type="text"
                :class="{ 'is-invalid': $v.form.taxOffice.$error }"

                class="form-control"
                v-model="form.taxOffice"
                maxlength="100"
            />
            <template v-if="!this.$v.form.taxOffice.minLength">
              <small class="text-danger" v-if="!$v.form.taxOffice.minLength">
                Vergi Dairesi en az 5 karakter içermelidir.</small
              >
            </template>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Internet Sitesi </label>
            <input
                type="text"
                :class="{ 'is-invalid': $v.form.webSite.$error }"

                class="form-control"
                v-model="form.webSite"
                maxlength="100"
            />
            <template v-if="!this.$v.form.webSite.minLength">
              <small class="text-danger" v-if="!$v.form.webSite.minLength">
                Web Sitesi ismi en az 5 karakter içermelidir.</small
              >
            </template>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label class="d-block">Aktif</label>
            <label class="label_switch">
              <input type="checkbox" v-model="form.isInUse"/>
              <span class="mr-2"></span>
            </label>
          </div>
        </div>
      </div>

    </b-modal>
    <table
        v-if="this.personAddressList.length > 0"
        class="table table-hover">
      <thead>
      <tr>
        <th class="fit">Adres Adı</th>
        <th class="fit">Adres Tipi</th>
        <th class="fit">Adres</th>
        <th class="fit text-right">Aktif</th>
        <th class="fit text-right">
          <PlusCircleIcon v-b-modal.modal-personAddress-list-edit/>
        </th>
        <th class="fit text-right">
          <ShowHistoryIcon @iconClicked="showHistoryClicked" :disabled="disableHistoryButton"/>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
          @click="showEditModal(item)"
          v-for="(item, index) in this.personAddressList"
          :key="index"
          class="cursor-pointer"
      >
        <td class="fit">{{ item.name }}</td>
        <td class="fit">{{
          $store.state.dtAeo.addressTypes.find(
          (d) => d.id === item.addressTypeId
          ).text
          }}
        </td>
        <td class="fit">{{ item.address }}</td>
        <td class="fit text-right">
          <font-awesome-icon icon="check" size="lg" class="text-danger" v-if="item.isInUse"/>
          <font-awesome-icon icon="ban" size="lg" class="text-danger" v-else/>
        </td>
        <td class="fit text-right"></td>
        <td class="fit text-right"></td>
      </tr>
      </tbody>
    </table>


  </div>
</template>

<script>
import {VueEditor} from "vue2-editor"
import {mapActions, mapGetters} from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/tr";
import moment from "moment";
import {minLength, required, requiredIf} from "vuelidate/lib/validators";
import PlusCircleIcon from "@/components/PlusCircleIcon.vue";
import ShowHistoryIcon from "@/components/ShowHistoryIcon.vue";

export default {
  name: "PersonAddress",
  components: {
    ShowHistoryIcon,
    PlusCircleIcon,
    VueEditor,
    DatePicker,
    moment,
  },
  delimiters: ['${', '}'], // Avoid Twig conflicts
  tabIndex: 0,
  data() {
    return {
      disableHistoryButton: false,
      prevIsInUse: true,
      countryList: [],
      cityList: [],
      addressTypeList: [],
      personAddressList: [],
      shouldDisable: true,
      form: {
        id: null,
        memberRegistrationStateTypeId: 1,
        personId: null,
        addressTypeId: null,
        name: null,
        description: null,
        receiverName: null,
        receiverSurname: null,
        eMail: null,
        mobileNumber: null,
        address: null,
        cityId: null,
        countryId: null,
        postalCode: null,
        district: null,
        companyName: null,
        taxNumber: null,
        taxOffice: null,
        webSite: null,
        isInUse: null,

      },

    };
  },
  created() {

    this.cityList = this.getterCities().length === 0
        ? this.getCity()
        : this.getterCities();

    this.countryList = this.getterCountries().length === 0
        ? this.getCountry()
        : this.getterCountries();


    this.addressTypeList = this.getterAddressTypes().length === 0
        ? this.getAddressType()
        : this.getterAddressTypes();


    if (this.getterCities().length && this.getterCountries().length && this.getterAddressTypes().length) {
      this.getPersonAddresses(true);
    }
  },

  validations: {
    form: {
      addressTypeId: {
        required,
      },
      countryId: {
        required,
      },
      cityId: {
        required,
      },
      name: {
        required,
        minLength: minLength(2)
      },
      description: {
        required,
        minLength: minLength(5),
      },
      receiverName: {
        minLength: minLength(5),
      },
      receiverSurname: {
        minLength: minLength(5),
      },
      eMail: {
        minLength: minLength(5),
      },
      taxOffice: {
        minLength: minLength(5),
      },
      postalCode: {
        minLength: minLength(5),
      },
      taxNumber: {
        minLength: minLength(5),
      },
      district: {
        minLength: minLength(5),
      },
      companyName: {
        minLength: minLength(5),
      },
      webSite: {
        minLength: minLength(5),
      },
      mobileNumber: {
        minLength: minLength(10)
      },
      address: {
        required,
        minLength: minLength(11)
      },

    }
  },

  methods: {
    ...mapActions("membershipAeo", ["savePersonAddress", "updatePersonState",
      "getPersonAddress", "updatePersonAddress"]),
    ...mapActions("dtAeo", ["getCity", "getCountry", "getAddressType"]),
    ...mapGetters("dtAeo", ["getterCountries", "getterCities", "getterAddressTypes"]),
    ...mapActions("general", ["showModal"]),

    showEditModal(item) {
      const {
        id,
        addressTypeId,
        name,
        description,
        receiverName,
        receiverSurname,
        eMail,
        mobileNumber,
        address,
        cityId,
        countryId,
        postalCode,
        district,
        companyName,
        taxNumber,
        taxOffice,
        webSite,
        isInUse,
      } = item;
      Object.assign(this.form, {
        id: id,
        addressTypeId: addressTypeId,
        name: name,
        description: description,
        receiverName: receiverName,
        receiverSurname: receiverSurname,
        address: address,
        eMail: eMail,
        mobileNumber: mobileNumber,
        cityId: cityId,
        countryId: countryId,
        postalCode: postalCode,
        district: district,
        companyName: companyName,
        taxNumber: taxNumber,
        taxOffice: taxOffice,
        webSite: webSite,
        isInUse: isInUse,
      });
      this.$bvModal.show("modal-personAddress-list-edit");
    },
    showHistoryClicked() {
      this.prevIsInUse = !this.prevIsInUse;
      this.getPersonAddresses(this.prevIsInUse)
    },
    getPersonAddresses(isInUse) {
      this.disableHistoryButton = !this.disableHistoryButton

      this.$store.state.general.showOverlay = true;
      let payload = {
        isInUse: isInUse,
        personId: this.$route.params.personId
      };
      this.getPersonAddress(payload).then(res => {
        if (res.hasError) {
          this.showModal({
            title: "Hata!",
            message: res.errorMessage,
            type: "danger",
          });
        } else {
          if (res.data.length > 0) {
            this.personAddressList = res.data;
            if (this.form.personId == null) {
              this.form.personId = this.personAddressList[0].personId;
            }
          } else {
            this.prevIsInUse = !this.prevIsInUse;
            this.showModal({
              title: "Uyarı!",
              message: "Kayıt bulunamadı.",
              type: "danger",
            });
          }

        }
      }).catch((reason) => {
        this.showModal({
          title: "Hata!",
          message: reason.toString(),
          type: "danger",
        });
      }).finally(() => {
        this.$store.state.general.showOverlay = false;
        this.disableHistoryButton = !this.disableHistoryButton
      });
    },
    save: function () {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.form.memberRegistrationStateTypeId === -1) {
          this.showModal({
            title: "Hata!",
            message: "Bir sonraki aşamaya geçilemiyor!",
            type: "danger",
          });
          return;
        }
        this.$store.state.general.showOverlay = true;
        this.savePersonAddress(this.form).then(res => {
          if (res.hasError) {
            this.showModal({
              title: "Hata!",
              message: res.errorMessage,
              type: "danger",
            });
          } else {
            this.showModal({
              title: "Başarıylı!",
              message: "Adress Bilgisi kayıt edildi.",
              type: "success",
            });
            this.personAddressList = res.data;
            if (this.form.personId == null) {
              this.form.personId = this.personAddressList[0].personId;
            }
            console.log(this.personAddressList)
            this.clearForm();
          }
        }).catch((reason) => {
          this.showModal({
            title: "Hata!",
            message: reason.toString(),
            type: "danger",
          });
        }).finally(() => {
          this.$store.state.general.showOverlay = false;

        });
      }
    },
    update: function () {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store.state.general.showOverlay = true;
        this.updatePersonAddress(this.form).then(res => {
          if (res.hasError) {
            this.showModal({
              title: "Hata!",
              message: res.errorMessage,
              type: "danger",
            });
          } else {
            this.showModal({
              title: "Başarıylı!",
              message: "Adres Bilgisi kayıt edildi.",
              type: "success",
            });
            this.personAddressList = res.data;
            if (this.form.personId == null) {
              this.form.personId = this.personAddressList[0].personId;
            }
          }
        }).catch((reason) => {
          this.showModal({
            title: "Hata!",
            message: reason.toString(),
            type: "danger",
          });
        }).finally(() => {
          this.$store.state.general.showOverlay = false;
          //this.$nextTick(()=>{}); if necessary
          this.$bvModal.hide("modal-personAddress-list-edit");
        })
      }
    },
    clearForm() {
      const {
        addressTypeId,
        name,
        description,
        receiverName,
        receiverSurname,
        eMail,
        mobileNumber,
        address,
        cityId,
        countryId,
        postalCode,
        district,
        companyName,
        taxNumber,
        taxOffice,
        webSite,
        isInUse,
      } = this.form;
      const clearedProps = {
        addressTypeId: null,
        name: null,
        description: null,
        receiverName: null,
        receiverSurname: null,
        eMail: null,
        address: null,
        mobileNumber: null,
        cityId: null,
        countryId: null,
        postalCode: null,
        district: null,
        companyName: null,
        taxNumber: null,
        taxOffice: null,
        webSite: null,
        isInUse: null,
      };
      this.form = Object.assign({}, this.form, clearedProps);
      this.$v.$reset();
    },
  },
  watch: {
    "$store.state.dtAeo.cities":
        function () {
          this.cityList = this.getterCities();
          if (this.getterCities().length && this.getterCountries().length && this.getterAddressTypes().length) {
            this.getPersonAddresses(true);
          }
        }, "$store.state.dtAeo.countries":
        function () {
          this.countryList = this.getterCountries();
          if (this.getterCities().length && this.getterCountries().length && this.getterAddressTypes().length) {
            this.getPersonAddresses(true);
          }
        }, "$store.state.dtAeo.addressTypes":
        function () {
          this.addressTypeList = this.getterAddressTypes();
          if (this.getterCities().length && this.getterCountries().length && this.getterAddressTypes().length) {
            this.getPersonAddresses(true);
          }
        },


  },
}
</script>
