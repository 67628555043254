import DTAeoService from '@/services/dt.aeo.service';
import City from '@/components/settings/City';
import MembershipAeoService from "@/services/membership.aeo.service";

const initialState = {
    selectedAnnouncement: {},
    workplaces: [],
    positions: [],
    cities: [],
    district: [],
    addressTypes: [],
    publishPlaces: [],
    subjectTypes: [],
    subjectTypeGrouped: [],
    pageTypes: [],
    Overlay: false,
    countries: [],
    title: [],
    gender: [],
    universities: [],
    language: [],
    languageLevel: [],
    DTPaginatedCountry: {dtPagination: {}, item: []},
    DTPaginatedCity: {dtPagination: {}, item: []},
    DTPaginatedDistrict: {dtPagination: {}, item: []},
    DTPaginatedAddressType: {dtPagination: {}, item: []},
    DTPaginatedGender: {dtPagination: {}, item: []},
    DTPaginatedLanguage: {dtPagination: {}, item: []},
    DTPaginatedTitle: {dtPagination: {}, item: []},
    DTPaginatedUniversity: {dtPagination: {}, item: []},
    DTPaginatedWorkplaceType: {dtPagination: {}, item: []},
    DTPaginatedPublishPlace: {dtPagination: {}, item: []},
    DTPaginatedPageType: {dtPagination: {}, item: []},

    AnnouncementPaginatedSearchResult: {dtPagination: {}, item: []},

    currentStateComponent: City,
    currentStateComponentData: null,
    duplicateForm: {},

}
export const dtAeo = {
    namespaced: true, state: initialState, actions: {

        getUniversities({commit}) {
            return DTAeoService.getUniversities().then(res => {
                if (res.data) commit('setUniversities', res.data.data);
                return Promise.resolve(res.data);
            });
        },

        getPositions({commit}) {
            return DTAeoService.getPositions().then(res => {
                if (res.data) commit('setPositions', res.data.data);
                return Promise.resolve(res.data);
            });
        },

        addPosition({commit}) {
            return DTAeoService.addPosition().then(res => {
                if (res.data) commit('setPositions', res.data.data);
                return Promise.resolve(res.data);
            });
        },

        updatePosition({commit}) {
            return DTAeoService.updatePosition().then(res => {
                if (res.data) commit('setPositions', res.data.data);
                return Promise.resolve(res.data);
            });
        },


        getPageByIdAsync({commit, state}, payload) {
            return DTAeoService.getPageByIdAsync(payload).then(res => {
                if (res.data) {
                    commit('setSelectedAnnouncement', res.data);
                }
                return Promise.resolve(res.data);
            });
        },
        removeAnnouncement({commit, state}, payload) {
            return DTAeoService.removeAnnouncement(payload);
        },


        //Announcement Search
        getAnnouncementSearchPaginated({commit}, payload) {
            return DTAeoService.getAnnouncementSearchPaginated(payload).then(res => {
                if (res.data) commit('setAnnouncementSearchPaginatedItem', res.data);
                return Promise.resolve(res.data);
            });
        },
        addAnnouncement({commit}, payload) {
            return DTAeoService.addAnnouncement(payload).then(res => {
                if (res.data.data)
                    commit('setNewlyAddedAnnouncement', res.data.data);
                return Promise.resolve(res.data);
            });
        },

        updatePageDetail({commit}, payload) {
            return DTAeoService.updatePageDetail(payload);
        },
        updateImageGallery({commit}, payload) {
            return DTAeoService.updateImageGallery(payload);
        },
        deleteImageFromImageGallery({commit}, payload) {
            return DTAeoService.deleteImageFromImageGallery(payload);
        },
        updateImageGalleryItem({commit}, payload) {
            return DTAeoService.updateImageGalleryItem(payload);
        },
        updateMainIconPath({commit}, payload) {
            return DTAeoService.updateMainIconPath(payload);
        },
        //Country
        getCountryPaginated({commit}, payload) {
            return DTAeoService.getCountryPaginated(payload).then(res => {
                if (res.data) commit('setPaginatedCountryItem', res.data);
                return Promise.resolve(res.data);
            });
        }, getCountry({commit}) {
            return DTAeoService.getCountry().then(res => {
                if (res.data) commit('setCountry', res.data.data);
                return Promise.resolve(res.data);
            });
        }, addCountry({commit}, payload) {
            return DTAeoService.addCountry(payload).then(res => {
                return Promise.resolve(res.data);
            })
        }, updateCountry({commit}, payload) {
            return DTAeoService.updateCountry(payload).then(res => {
                return Promise.resolve(res.data);
            })
        },

        //City
        getCityPaginated({commit}, payload) {
            return DTAeoService.getCityPaginated(payload).then(res => {
                if (res.data) commit('setPaginatedCityItem', res.data);
                return Promise.resolve(res.data);
            });
        },
        getCity({commit}) {
            return DTAeoService.getCity().then(res => {
                if (res.data) commit('setCity', res.data.data);
                return Promise.resolve(res.data);
            });
        }, addCity({commit}, payload) {
            return DTAeoService.addCity(payload).then(res => {
                return Promise.resolve(res.data);
            })
        }, updateCity({commit}, payload) {
            return DTAeoService.updateCity(payload).then(res => {
                return Promise.resolve(res.data);
            })
        },


        //DT-Web
        getPublishPlace({commit, state}) {
            return DTAeoService.getPublishPlace().then(res => {
                if (res.data) {
                    commit('setPublishPlace', res.data.data);
                }
                return Promise.resolve(res.data);
            });
        },
        getSubjectTypeGrouped({commit}) {
            return DTAeoService.getSubjectTypeGrouped().then(res => {
                if (res.data) commit('setSubjectTypeGrouped', res.data.data);
                return Promise.resolve(res.data);
            });
        },
        getSubjectType({commit, state}) {
            return DTAeoService.getSubjectType().then(res => {
                if (res.data) {
                    commit('setSubjectType', res.data.data);
                }
                return Promise.resolve(res.data);
            });
        },
        getPageType({commit, state}) {
            return DTAeoService.getPageType().then(res => {
                if (res.data) {
                    commit('setPageType', res.data.data);
                }
                return Promise.resolve(res.data);
            });
        },

        //District
        getDistrictPaginated({commit}, payload) {
            return DTAeoService.getDistrictPaginated(payload).then(res => {
                if (res.data) commit('setPaginatedDistrictItem', res.data);
                return Promise.resolve(res.data);
            });
        },
        getDistrict({commit}) {
            return DTAeoService.getDistrict().then(res => {
                if (res.data) commit('setDistrict', res.data.data);
                return Promise.resolve(res.data);
            });
        }, addDistrict({commit}, payload) {
            return DTAeoService.addDistrict(payload).then(res => {
                return Promise.resolve(res.data);
            })
        }, updateDistrict({commit}, payload) {
            return DTAeoService.updateDistrict(payload).then(res => {
                return Promise.resolve(res.data);
            })
        },

        //AddressType
        getAddressTypePaginated({commit}, payload) {
            return DTAeoService.getAddressTypePaginated(payload).then(res => {
                if (res.data) commit('setPaginatedAddressTypeItem', res.data);
                return Promise.resolve(res.data);
            });
        },
        getAddressType({commit}) {
            return DTAeoService.getAddressType().then(res => {
                if (res.data) commit('setAddressType', res.data.data);
                return Promise.resolve(res.data);
            });
        }, addAddressType({commit}, payload) {
            return DTAeoService.addAddressType(payload).then(res => {
                return Promise.resolve(res.data);
            })
        }, updateAddressType({commit}, payload) {
            return DTAeoService.updateAddressType(payload).then(res => {
                return Promise.resolve(res.data);
            })
        },

        //Gender
        getGenderPaginated({commit}, payload) {
            return DTAeoService.getGenderPaginated(payload).then(res => {
                if (res.data) commit('setPaginatedGenderItem', res.data);
                return Promise.resolve(res.data);
            });
        }, getGender({commit}) {
            return DTAeoService.getGender().then(res => {
                if (res.data) commit('setGender', res.data.data);
                return Promise.resolve(res.data);
            });
        }, addGender({commit}, payload) {
            return DTAeoService.addGender(payload).then(res => {
                return Promise.resolve(res.data);
            })
        }, updateGender({commit}, payload) {
            return DTAeoService.updateGender(payload).then(res => {
                return Promise.resolve(res.data);
            })
        },

        //Language
        getLanguagePaginated({commit}, payload) {
            return DTAeoService.getLanguagePaginated(payload).then(res => {
                if (res.data) commit('setPaginatedLanguageItem', res.data);
                return Promise.resolve(res.data);
            });
        }, getLanguage({commit}) {
            return DTAeoService.getLanguage().then(res => {
                if (res.data) commit('setLanguage', res.data.data);
                return Promise.resolve(res.data);
            });

        }, getLanguageLevel({commit}) {
            return DTAeoService.getLanguageLevel().then(res => {
                if (res.data) commit('setLanguageLevel', res.data.data);
                return Promise.resolve(res.data);
            });

        }, addLanguage({commit}, payload) {
            return DTAeoService.addLanguage(payload).then(res => {
                return Promise.resolve(res.data);
            })
        }, updateLanguage({commit}, payload) {
            return DTAeoService.updateLanguage(payload).then(res => {
                return Promise.resolve(res.data);
            })
        },

        //Title
        getTitlePaginated({commit}, payload) {
            return DTAeoService.getTitlePaginated(payload).then(res => {
                if (res.data) commit('setPaginatedTitleItem', res.data);
                return Promise.resolve(res.data);
            });
        }, getTitle({commit}) {
            return DTAeoService.getTitle().then(res => {
                if (res.data) commit('setTitle', res.data.data);
                return Promise.resolve(res.data);
            });
        }, addTitle({commit}, payload) {
            return DTAeoService.addTitle(payload).then(res => {
                return Promise.resolve(res.data);
            })
        }, updateTitle({commit}, payload) {
            return DTAeoService.updateTitle(payload).then(res => {
                return Promise.resolve(res.data);
            })
        },

        //University
        getUniversityPaginated({commit}, payload) {
            return DTAeoService.getUniversityPaginated(payload).then(res => {
                if (res.data) commit('setPaginatedUniversityItem', res.data);
                return Promise.resolve(res.data);
            });
        }, getUniversity({commit}) {
            return DTAeoService.getUniversity().then(res => {
                if (res.data) commit('setUniversity', res.data.data);
                return Promise.resolve(res.data);
            });
        }, addUniversity({commit}, payload) {
            return DTAeoService.addUniversity(payload).then(res => {
                return Promise.resolve(res.data);
            })
        }, updateUniversity({commit}, payload) {
            return DTAeoService.updateUniversity(payload).then(res => {
                return Promise.resolve(res.data);
            })
        },

        //WorkplaceType
        getWorkplaceTypePaginated({commit}, payload) {
            return DTAeoService.getWorkplaceTypePaginated(payload).then(res => {
                if (res.data) commit('setPaginatedWorkplaceTypeItem', res.data);
                return Promise.resolve(res.data);
            });
        }, getWorkplaceType({commit}) {
            return DTAeoService.getWorkplaceType().then(res => {
                if (res.data) commit('setWorkplaceType', res.data.data);
                return Promise.resolve(res.data);
            });
        }, addWorkplaceType({commit}, payload) {
            return DTAeoService.addWorkplaceType(payload).then(res => {
                return Promise.resolve(res.data);
            })
        }, updateWorkplaceType({commit}, payload) {
            return DTAeoService.updateWorkplaceType(payload).then(res => {
                return Promise.resolve(res.data);
            })
        },

        //PublishPlace
        getPublishPlacePaginated({commit}, payload) {
            return DTAeoService.getPublishPlacePaginated(payload).then(res => {
                if (res.data) commit('setPaginatedPublishPlaceItem', res.data);
                return Promise.resolve(res.data);
            });

        }, addPublishPlace({commit}, payload) {
            return DTAeoService.addPublishPlace(payload).then(res => {
                return Promise.resolve(res.data);
            })
        }, updatePublishPlace({commit}, payload) {
            return DTAeoService.updatePublishPlace(payload).then(res => {
                return Promise.resolve(res.data);
            })
        },
        //PageType
        getPageTypePaginated({commit}, payload) {
            return DTAeoService.getPageTypePaginated(payload).then(res => {
                if (res.data) commit('setPaginatedPageTypeItem', res.data);
                return Promise.resolve(res.data);
            });
        },
        addPageType({commit}, payload) {
            return DTAeoService.addPageType(payload).then(res => {
                return Promise.resolve(res.data);
            })
        }, updatePageType({commit}, payload) {
            return DTAeoService.updatePageType(payload).then(res => {
                return Promise.resolve(res.data);
            })
        },
        approvePages({commit}, payload) {
            return DTAeoService.approvePages(payload).then(res => {
                return Promise.resolve(res.data);
            })
        },
    },
    getters: {
        getCountryCount(state) {
            return state.DTPaginatedCountry.item.length
        },
        getCityCount(state) {
            return state.DTPaginatedCity.item.length
        },
        getAnnouncementSearchCount(state) {
            return state.AnnouncementPaginatedSearchResult.item.length
        },
        getDistrictCount(state) {
            return state.DTPaginatedDistrict.item.length
        },
        getAddressTypeCount(state) {
            return state.DTPaginatedAddressType.item.length
        },
        getGenderCount(state) {
            return state.DTPaginatedGender.item.length
        },
        getLanguageCount(state) {
            return state.DTPaginatedLanguage.item.length
        },
        getTitleCount(state) {
            return state.DTPaginatedTitle.item.length
        },
        getUniversityCount(state) {
            return state.DTPaginatedUniversity.item.length
        },
        getWorkplaceTypeCount(state) {
            return state.DTPaginatedWorkplaceType.item.length
        },
        getPublishPlaceCount(state) {
            return state.DTPaginatedPublishPlace.item.length
        },
        getPageTypeCount(state) {
            return state.DTPaginatedPageType.item.length
        },


        getterGender(state) {
            return state.gender;
        },
        getterUniversities(state) {
            return state.universities;
        },
        getterLanguages(state) {
            return state.language;
        },
        getterLanguageLevels(state) {
            return state.languageLevel;
        },
        getterCities(state) {
            return state.cities;
        },
        getterDistricts(state) {
            return state.district;
        },
        getterCountries(state) {
            return state.countries;
        },
        getterAddressTypes(state) {
            return state.addressTypes;
        },
        getterPositions(state) {
            return state.positions;
        }
    },
    mutations: {
        setAnnouncementSearchPaginatedItem(state, payload) {
            state.AnnouncementPaginatedSearchResult.item = Object.assign([], payload.endpointResult.data);
            state.AnnouncementPaginatedSearchResult.dtPagination = Object.assign({}, payload.pagination);
            state.Overlay = false
        },

        setPaginatedCountryItem(state, payload) {
            state.DTPaginatedCountry.item = Object.assign([], payload.endpointResult.data);
            state.DTPaginatedCountry.dtPagination = Object.assign({}, payload.pagination);
            state.Overlay = false
        },

        setPaginatedCityItem(state, payload) {
            state.DTPaginatedCity.item = Object.assign([], payload.endpointResult.data);
            state.DTPaginatedCity.dtPagination = Object.assign({}, payload.pagination);
            state.Overlay = false
        },
        setPaginatedDistrictItem(state, payload) {
            state.DTPaginatedDistrict.item = Object.assign([], payload.endpointResult.data);
            state.DTPaginatedDistrict.dtPagination = Object.assign({}, payload.pagination);
            state.Overlay = false
        },
        setPaginatedAddressTypeItem(state, payload) {
            state.DTPaginatedAddressType.item = Object.assign([], payload.endpointResult.data);
            state.DTPaginatedAddressType.dtPagination = Object.assign({}, payload.pagination);
            state.Overlay = false
        },
        setPaginatedGenderItem(state, payload) {
            state.DTPaginatedGender.item = Object.assign([], payload.endpointResult.data);
            state.DTPaginatedGender.dtPagination = Object.assign({}, payload.pagination);
            state.Overlay = false
        },
        setPaginatedLanguageItem(state, payload) {
            state.DTPaginatedLanguage.item = Object.assign([], payload.endpointResult.data);
            state.DTPaginatedLanguage.dtPagination = Object.assign({}, payload.pagination);
            state.Overlay = false
        },
        setPaginatedTitleItem(state, payload) {
            state.DTPaginatedTitle.item = Object.assign([], payload.endpointResult.data);
            state.DTPaginatedTitle.dtPagination = Object.assign({}, payload.pagination);
            state.Overlay = false
        },
        setPaginatedUniversityItem(state, payload) {
            state.DTPaginatedUniversity.item = Object.assign([], payload.endpointResult.data);
            state.DTPaginatedUniversity.dtPagination = Object.assign({}, payload.pagination);
            state.Overlay = false
        },
        setPaginatedWorkplaceTypeItem(state, payload) {
            state.DTPaginatedWorkplaceType.item = Object.assign([], payload.endpointResult.data);
            state.DTPaginatedWorkplaceType.dtPagination = Object.assign({}, payload.pagination);
            state.Overlay = false
        },
        setPaginatedPublishPlaceItem(state, payload) {
            state.DTPaginatedPublishPlace.item = Object.assign([], payload.endpointResult.data);
            state.DTPaginatedPublishPlace.dtPagination = Object.assign({}, payload.pagination);
            state.Overlay = false
        },
        setPaginatedPageTypeItem(state, payload) {
            state.DTPaginatedPageType.item = Object.assign([], payload.endpointResult.data);
            state.DTPaginatedPageType.dtPagination = Object.assign({}, payload.pagination);
            state.Overlay = false
        },

        setCountry(state, payload) {
            state.countries = Object.assign([], payload);
        },

        setPublishPlace(state, payload) {
            state.publishPlaces = Object.assign([], payload);
        },
        setPageType(state, payload) {
            state.pageTypes = Object.assign([], payload);
        },
        setSubjectTypeGrouped(state, payload) {
            state.subjectTypeGrouped = Object.assign([], payload);
        },

        setSubjectType(state, payload) {
            state.subjectTypes = Object.assign([], payload);
        },

        setCity(state, payload) {
            state.cities = Object.assign([], payload);
        },

        setUniversities(state, payload) {
            state.universities = Object.assign([], payload);
        },
        setDistrict(state, payload) {
            state.district = Object.assign([], payload);
        },
        setAddressType(state, payload) {
            state.addressTypes = Object.assign([], payload);
        },
        setGender(state, payload) {
            state.gender = Object.assign([], payload);
        },
        setLanguage(state, payload) {
            state.language = Object.assign([], payload);
        },
        setLanguageLevel(state, payload) {
            state.languageLevel = Object.assign([], payload);
        },
        setTitle(state, payload) {
            state.title = Object.assign([], payload);
        },
        setUniversity(state, payload) {
            state.university = Object.assign([], payload);
        },
        setWorkplaceType(state, payload) {
        },
        setSelectedAnnouncement(state, payload) {
            state.selectedAnnouncement = Object.assign({}, payload);
        },
        setPositions(state, payload) {
            state.positions = Object.assign([], payload);
        }

    }
}