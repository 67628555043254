<template>
  <div class="main-menu" :class="showMenu ? 'menu-open' : 'menu-close'">
    <div class="back">
      <font-awesome-icon icon="bars" @click="openMenu" v-if="$store.state.general.showMenu" />
      <font-awesome-icon :icon="showMenu ? 'chevron-left' : 'chevron-right'" @click="toggleMenu" />
    </div>
    <div class="menu-logo" v-if="showMenu">
      <img src="../assets/img/logo.jpg" alt="Ankara Eczacı Odası" />
    </div>
    <div class="menu-title d-flex flex-column pt-5" v-if="showMenu">
      <h5 class="text-danger text-center ">Ankara Eczacı Odası</h5>


      <div class="input-group mt-4" >
          <input type="text" class="form-control" placeholder="Menüde Arayın" aria-label="Recipient's username with two button addons">
          <button class="btn btn-outline-secondary" type="button">Ara</button>
        </div>
    </div>
    
    <!-- <div class="mt-5 text-danger text-center fw-bold" v-if="showMenu">
    {{$store.state.general.menu[1].menuAdi}}
    </div> -->
    <div class="menu-bottom mt-3">
      <component :is="$store.state.general.currentMenu" />
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";
import { mapState, mapActions } from "vuex";

export default {
  mixins: [clickaway],
  methods: {
    ...mapActions("general", ["toggleMenu"]),
    away() {
      this.$store.state.general.showMenu = false;
    },
    openMenu() {
      this.$store.state.general.showMenu = true;
    },
  },
  created() {
  },
  computed: {
    ...mapState("general", ["showMenu"]),
  },
};
</script>
