<template>
  <div class="standart-page">
    <div class="card-component mb-4">
      <div class="body">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>TC Kimlik No</label>
              <input
                  maxlength="11"
                  type="number"
                  class="form-control"
                  :class="{ 'is-invalid': $v.form.personelIdNumber.$error }"
                  v-model="form.personelIdNumber"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  @input="searchForPersonelIdNumber"/>
              <template v-if="$v.form.personelIdNumber.$error">
                <small class="text-danger" v-if="!$v.form.personelIdNumber.required">
                  Bu alan boş bırakılamaz.
                </small>
              </template>
              <template v-if="!this.$v.form.personelIdNumber.minLength">
                <small class="text-danger" v-if="!$v.form.personelIdNumber.minLength">
                  TC Kimlik No en az 11 karakter içermelidir.</small
                >
              </template>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Cinsiyet</label>
              <!--:class="{ 'is-invalid': $v.form.documentTypeId.$error }"-->
              <select
                  :disabled="shouldDisable"
                  :class="{ 'is-invalid': $v.form.genderId.$error }"
                  v-select
                  class="form-control"
                  v-model="form.genderId"
              >
                <option :value="null">Seçiniz</option>
                <option
                    :value="item.id"
                    v-for="(item, index) in this.genderList"
                    :key="index"
                >
                  {{ item.text }}
                </option>
              </select>
              <template v-if="$v.form.genderId.$error">
                <small class="text-danger" v-if="!$v.form.genderId.required">
                  Bu alan boş bırakılamaz.
                </small>
              </template>


              <!--
              <template v-if="$v.form.documentTypeId.$error">
                <small
                    class="text-danger"
                    v-if="!$v.form.documentTypeId.required"
                >{{ this.getMessage(82) }}</small
                >
              </template>
              -->
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label>Ad</label>
              <input
                  :disabled="shouldDisable"
                  :class="{ 'is-invalid': $v.form.name.$error }"
                  type="text"
                  class="form-control"
                  v-model="form.name"
                  maxlength="100"
              />
              <template v-if="$v.form.name.$error">
                <small class="text-danger" v-if="!$v.form.name.required">
                  Bu alan boş bırakılamaz.
                </small>
              </template>
              <template v-if="!this.$v.form.name.minLength">
                <small class="text-danger" v-if="!$v.form.name.minLength">
                  İsim en az 2 karakter içermelidir.</small
                >
              </template>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Soyad</label>
              <input
                  :disabled="shouldDisable"
                  :class="{ 'is-invalid': $v.form.surName.$error }"

                  type="text"
                  class="form-control"
                  v-model="form.surName"
                  maxlength="100"
              />
              <template v-if="$v.form.surName.$error">
                <small class="text-danger" v-if="!$v.form.surName.required">
                  Bu alan boş bırakılamaz.
                </small>
              </template>
              <template v-if="!this.$v.form.surName.minLength">
                <small class="text-danger" v-if="!$v.form.surName.minLength">
                  Soy isim en az 2 karakter içermelidir.</small
                >
              </template>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Username</label>
              <input
                  :disabled="shouldDisable"
                  :class="{ 'is-invalid': $v.form.username.$error }"

                  type="text"
                  class="form-control"
                  v-model="form.username"
                  maxlength="100"
              />
              <template v-if="$v.form.username.$error">
                <small class="text-danger" v-if="!$v.form.username.required">
                  Bu alan boş bırakılamaz.
                </small>
              </template>
              <template v-if="!this.$v.form.username.minLength">
                <small class="text-danger" v-if="!$v.form.username.minLength">
                  Kullanıcı adı en az 2 karakter içermelidir.</small
                >
              </template>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Diğer İsim</label>
              <input
                  :disabled="shouldDisable"
                  type="text"
                  :class="{ 'is-invalid': $v.form.otherName.$error }"

                  class="form-control"
                  v-model="form.otherName"
                  maxlength="100"
              />
              <template v-if="!this.$v.form.otherName.minLength">
                <small class="text-danger" v-if="!$v.form.otherName.minLength">
                  Diğer isim adı en az 2 karakter içermelidir.</small
                >
              </template>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>E-posta</label>
              <input
                  :disabled="shouldDisable"
                  :class="{ 'is-invalid': $v.form.eMail.$error }"

                  type="text"
                  class="form-control"
                  v-model="form.eMail"
                  maxlength="100"
              />
              <template v-if="$v.form.eMail.$error">
                <small class="text-danger" v-if="!$v.form.eMail.required">
                  Bu alan boş bırakılamaz.
                </small>
              </template>
              <template v-if="!this.$v.form.eMail.minLength">
                <small class="text-danger" v-if="!$v.form.eMail.minLength">
                  E-Mail en az 10 karakter içermelidir.</small
                >
              </template>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Doğum Tarihi</label>
              <input
                  :disabled="shouldDisable"
                  :class="{ 'is-invalid': $v.form.birthDate.$error }"

                  type="date"
                  class="form-control"
                  v-model="form.birthDate"
                  maxlength="100"
              />
              <template v-if="$v.form.birthDate.$error">
                <small class="text-danger" v-if="!$v.form.birthDate.required">
                  Bu alan boş bırakılamaz.
                </small>
              </template>

            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Cep Telefonu</label>
              <!--v-mask="'(###) ### ## ##'"-->
              <input
                  :disabled="shouldDisable"
                  type="text"
                  class="form-control"
                  maxlength="15"
                  :class="{ 'is-invalid': $v.form.cellular.$error }"

                  v-model="form.cellular"
              />
              <template v-if="$v.form.cellular.$error">
                <small class="text-danger" v-if="!$v.form.cellular.required">
                  Bu alan boş bırakılamaz.
                </small>
              </template>
              <template v-if="!this.$v.form.cellular.minLength">
                <small class="text-danger" v-if="!$v.form.cellular.minLength">
                  Telefon numarası en az 10 karakter içermelidir.</small
                >
              </template>
            </div>
          </div>

        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Oda Kayıt No</label>
              <input
                  :disabled="shouldDisable"

                  type="text"
                  class="form-control"
                  v-model="form.aeoRegisterNumber"
                  maxlength="100"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Oda Kayıt Tarihi</label>
              <input
                  :disabled="shouldDisable"

                  type="date"
                  class="form-control"
                  v-model="form.aeoRegisterDate"
                  maxlength="100"
              />
            </div>
          </div>

        </div>
        <div class="row mt-3 justify-content-end">
          <div class="col-md-4 ms-auto">
            <div class="form-group w-100 ">
              <button
                  @click="save"
                  :disabled="shouldDisable"
                  class="btn btn-danger w-100 ">
                Kaydet ve ilerle
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {VueEditor} from "vue2-editor"
import {mapActions, mapGetters} from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/tr";
import moment from "moment";
import {minLength, required, requiredIf} from "vuelidate/lib/validators";

export default {
  name: "TahTesTut",
  components: {
    VueEditor,
    DatePicker,
    moment,
  },
  delimiters: ['${', '}'], // Avoid Twig conflicts
  tabIndex: 0,
  data() {
    return {
      genderList: [],
      shouldDisable: true,
      form: {
        memberRegistrationStateTypeId: 0,

        genderId: null,
        personelIdNumber: null,
        name: null,
        surName: null,
        username: null,
        otherName: null,
        birthDate: null,
        cellular: null,
        eMail: null,
        aeoRegisterDate: null,
        aeoRegisterNumber: null,
      },
    };
  },
  created() {
    if (this.getterGender().length === 0) {
      this.getGender();
    }
    else{
      this.genderList = this.getterGender()
    }
  },
  validations: {
    form: {
      personelIdNumber: {
        required,
        minLength: minLength(11)
      }, birthDate: {
        required,
      }, cellular: {
        required,
        minLength: minLength(10)
      }, eMail: {
        required,
        minLength: minLength(10)
      },
      name: {
        required,
        minLength: minLength(2)
      },
      surName: {
        required,
        minLength: minLength(2)
      },
      username: {
        required,
        minLength: minLength(2)
      },
      otherName: {
        minLength: minLength(2)
      },
      genderId: {
        required,
      },
    },

  },

  methods: {
    ...mapGetters("dtAeo", ["getterGender"]),
    ...mapActions("dtAeo", ["getGender"]),
    ...mapActions("membershipAeo", ["savePerson",
      "searchPersonByPersonalIdNumber",
      "getMemberRegistrationStates"]),
    ...mapActions("general", ["showModal"]),
    searchForPersonelIdNumber: function () {
      if (this.form.personelIdNumber.length === 11) {
        this.$store.state.general.showOverlay = true;
        this.searchPersonByPersonalIdNumber(this.form.personelIdNumber).then(res => {
          if (res.data.person != null) {
            this.showModal({
              title: "Uyarı - Dikkat",
              message: "Bu TC ile bir kullanıcı zaten var!",
              type: "danger",
            })
            this.$store.state.general.showOverlay = false;
          } else {
            this.shouldDisable = false;
            this.$store.state.general.showOverlay = false;
          }
        }).catch((reason) => {
          this.showModal({
            title: "Hata!",
            message: reason.toString(),
            type: "danger",
          });
          this.$store.state.general.showOverlay = false;
        });
      } else {
        this.shouldDisable = true;
      }

    },


    save: function () {

      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.form.memberRegistrationStateTypeId === -1) {
          this.showModal({
            title: "Hata!",
            message: "Bir sonraki aşamaya geçilemiyor!",
            type: "danger",
          });
          return;
        }
        if (this.form.aeoRegisterNumber === "") {
          this.form.aeoRegisterNumber = null;
          this.form.aeoRegisterDate = null;
        }


        let tabIndex = this.$store.state.membershipAeo.tabIndex;
        let index =
            this.$store.state.membershipAeo.memberRegistrationStates.findIndex(x => x.index === tabIndex + 1)
        this.form.memberRegistrationStateTypeId = this.$store.state.membershipAeo.memberRegistrationStates[index].id;
        console.log(index);
        this.savePerson(this.form).then(res => {
          if (res.hasError) {
            this.showModal({
              title: "Hata!",
              message: res.errorMessage,
              type: "danger",
            });
          } else {
            this.showModal({
              title: "Success!",
              message: "User saved successfully",
              type: "success",
            });
            this.$emit("update-tab-index", this.$store.state.membershipAeo.memberRegistrationStates[index].index);
          }
        }).catch((reason) => {
          this.showModal({
            title: "Hata!",
            message: reason.toString(),
            type: "danger",
          });
        });
      }
    }

  },
  watch: {
    "$store.state.dtAeo.gender":
        function () {
          this.genderList = this.getterGender();
        },
    "$store.state.membershipAeo.selectedPerson": function () {
      let obj = this.$store.state.membershipAeo.selectedPerson.person;
      if (obj != null) {
        this.form = {
          memberRegistrationStateTypeId: obj.memberRegistrationStateTypeId,
          genderId: obj.genderId,
          personelIdNumber: obj.memberRegistrationStateTypeId,
          name: obj.memberRegistrationStateTypeId.name,
          surName: obj.memberRegistrationStateTypeId.surName,
          username: null,
          otherName: obj.otherName,
          birthDate: obj.birthDate,
          cellular: obj.cellular,
          eMail: obj.eMail,
          aeoRegisterDate: obj.aeoRegisterDate,
          aeoRegisterNumber: obj.aeoRegisterNumber,
        };
        let index =
            this.$store.state.membershipAeo.memberRegistrationStates.find(x => x.id === this.form.memberRegistrationStateTypeId).index;
        this.$emit("update-tab-index", index);
      }


    },
  }
}
</script>
