<template>
  <div>
    <b-modal
        id="modal-personUniversity-list-edit"
        size="lg"
        centered
        title="Kişi Üniversite Bilgisini Düzenle"
        ok-title="Kaydet"
        ok-variant="success"
        cancel-title="İptal"
        cancel-variant="outline-secondary"
        @ok.prevent="update"
        @hidden="clearForm">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Universite Bilgisi</label>
            <!--:class="{ 'is-invalid': $v.form.documentTypeId.$error }"-->
            <select
                :class="{ 'is-invalid': $v.form.universityId.$error }"
                v-select
                class="form-control"
                v-model="form.universityId"
            >
              <option :value="null">Seçiniz</option>
              <option
                  :value="item.id"
                  v-for="(item, index) in this.universityList"
                  :key="index"
              >
                {{ item.text }}
              </option>
            </select>
            <template v-if="$v.form.universityId.$error">
              <small class="text-danger" v-if="!$v.form.universityId.required">
                Bu alan boş bırakılamaz.
              </small>
            </template>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Bölüm / Fakülte</label>
            <input
                type="text"
                :class="{ 'is-invalid': $v.form.specialization.$error }"

                class="form-control"
                v-model="form.specialization"
                maxlength="100"
            />
            <template v-if="!this.$v.form.specialization.minLength">
              <small class="text-danger" v-if="!$v.form.specialization.minLength">
                Yetkinlik alanı adı en az 10 karakter içermelidir.</small
              >
            </template>

            <template v-if="$v.form.specialization.$error">
              <small class="text-danger" v-if="!$v.form.specialization.required">
                Bu alan boş bırakılamaz.
              </small>
            </template>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Başlangıç Tarihi</label>

            <input
                :class="{ 'is-invalid': $v.form.beginDate.$error }"
                type="date"
                class="form-control"
                v-model="form.beginDate"
                maxlength="100"
            />
            <template v-if="$v.form.beginDate.$error">
              <small class="text-danger" v-if="!$v.form.beginDate.required">
                Bu alan boş bırakılamaz.
              </small>
            </template>

          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Mezuniyet Tarihi</label>
            <input
                :class="{ 'is-invalid': $v.form.graduationDate.$error }"

                type="date"
                class="form-control"
                v-model="form.graduationDate"
                maxlength="100"
            />
            <template v-if="$v.form.graduationDate.$error">
              <small class="text-danger" v-if="!$v.form.graduationDate.required">
                Bu alan boş bırakılamaz.
              </small>
            </template>

          </div>
        </div>


      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Diploma Number</label>
            <input
                :class="{ 'is-invalid': $v.form.diplomaNumber.$error }"

                type="number"
                class="form-control"
                v-model="form.diplomaNumber"
                maxlength="100"
            />
            <template v-if="$v.form.diplomaNumber.$error">
              <small class="text-danger" v-if="!$v.form.diplomaNumber.required">
                Bu alan boş bırakılamaz.
              </small>
            </template>

          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label>Diploma Soyadı</label>
            <input
                :class="{ 'is-invalid': $v.form.diplomaSurname.$error }"

                type="text"
                class="form-control"
                v-model="form.diplomaSurname"
                maxlength="100"
            />
            <template v-if="$v.form.diplomaSurname.$error">
              <small class="text-danger" v-if="!$v.form.diplomaSurname.required">
                Bu alan boş bırakılamaz.
              </small>
            </template>
            <template v-if="!this.$v.form.diplomaSurname.minLength">
              <small class="text-danger" v-if="!$v.form.diplomaSurname.minLength">
                Diploma soy adı en az 2 karakter içermelidir.</small
              >
            </template>


          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Diploma Notu</label>
            <input
                :class="{ 'is-invalid': $v.form.diplomaGrade.$error }"

                type="text"
                class="form-control"
                v-model="form.diplomaGrade"
                maxlength="100"
            />
            <template v-if="$v.form.diplomaGrade.$error">
              <small class="text-danger" v-if="!$v.form.diplomaGrade.required">
                Bu alan boş bırakılamaz.
              </small>
            </template>


          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="d-block">Aktif</label>
            <label class="label_switch">
              <input type="checkbox" v-model="form.isInUse"/>
              <span class="mr-2"></span>
            </label>
          </div>
        </div>
      </div>
    </b-modal>
    <table
        v-if="this.personUniversityList.length > 0"
        class="table table-hover">
      <thead>
      <tr>
        <th class="fit">Üniversite Adı</th>
        <th class="fit">Başlangıç Tarihi</th>
        <th class="fit">Mezuniyet Tarihi</th>
        <th class="fit">Diploma Numarası</th>
        <th class="fit">Notu</th>
        <th class="fit text-right">Aktif</th>
        <th class="fit text-right">
          <PlusCircleIcon v-b-modal.modal-personUniversity-list-edit/>
        </th>
        <th class="fit text-right">
          <ShowHistoryIcon @iconClicked="showHistoryClicked" :disabled="disableHistoryButton"/>
        </th>

      </tr>
      </thead>
      <tbody>
      <tr
          @click="showEditModal(item)"
          v-for="(item, index) in this.personUniversityList"
          :key="index"
          class="cursor-pointer"
      >
        <td class="fit">{{
          $store.state.dtAeo.universities.find(
          (d) => d.id === item.universityId
          ).text
          }}
        </td>
        <td class="fit">{{ format_date(item.beginDate) }}</td>
        <td class="fit">{{ format_date(item.graduationDate) }}</td>
        <td class="fit">{{ item.diplomaNumber }}</td>
        <td class="fit">{{ item.diplomaGrade }}</td>
        <td class="fit text-right">
          <font-awesome-icon icon="check" size="lg" class="text-danger" v-if="item.isInUse"/>
          <font-awesome-icon icon="ban" size="lg" class="text-danger" v-else/>
        </td>
        <td class="fit text-right"></td>
        <td class="fit text-right"></td>
      </tr>
      </tbody>
    </table>

  </div>


</template>

<script>
import {VueEditor} from "vue2-editor"
import {mapActions, mapGetters} from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/tr";
import moment from "moment";
import {minLength, required, requiredIf} from "vuelidate/lib/validators";
import PlusCircleIcon from "@/components/PlusCircleIcon.vue";
import ShowHistoryIcon from "@/components/ShowHistoryIcon.vue";

export default {
  name: "TahTesTut",
  components: {
    ShowHistoryIcon,
    PlusCircleIcon,
    VueEditor,
    DatePicker,
    moment,
  },
  delimiters: ['${', '}'], // Avoid Twig conflicts
  tabIndex: 0,
  data() {
    return {
      disableHistoryButton: false,
      prevIsInUse: true,
      personUniversityList: [],
      universityList: [],
      shouldDisable: true,
      isEditing: false,
      form: {
        specialization: null,
        id: null,
        isInUse: null,
        memberRegistrationStateTypeId: 1,
        personId: null,
        universityId: null,
        diplomaSurname: null,
        graduationDate: moment().format('YYYY-MM-DD'),
        beginDate: moment().format('YYYY-MM-DD'),
        diplomaNumber: null,
        diplomaGrade: null,
      },

    };
  },
  created() {


    this.universityList = this.getterUniversities().length === 0
        ? this.getUniversities()
        : this.getterUniversities();

    if (this.universityList.length > 0)
      this.getPersonEducation(true);
  },

  validations: {
    form: {
      universityId: {
        required,
      }, beginDate: {
        required,
      }, graduationDate: {
        required,
      }, diplomaSurname: {
        required,
        minLength: minLength(2)

      }, diplomaNumber: {
        required,
      }, diplomaGrade: {
        required,
      },
      specialization: {
        required,
        minLength: minLength(11)
      },

    }
  },

  methods: {
    showHistoryClicked() {
      this.prevIsInUse = !this.prevIsInUse;
      this.getPersonEducation(this.prevIsInUse)
    },
    getPersonEducation(isInUse) {
      this.disableHistoryButton = !this.disableHistoryButton
      this.$store.state.general.showOverlay = true;
      let payload = {
        isInUse: isInUse,
        personId: this.$route.params.personId
      };
      this.getPersonUniversities(payload).then(res => {
        if (res.hasError) {
          this.showModal({
            title: "Hata!",
            message: res.errorMessage,
            type: "danger",
          });
        } else {
          if (res.data.length > 0) {
            this.personUniversityList = res.data;
            if (this.form.personId == null) {
              this.form.personId = this.personUniversityList[0].personId;
            }
          } else {
            this.prevIsInUse = !this.prevIsInUse;
            this.showModal({
              title: "Uyarı!",
              message: "Kayıt bulunamadı.",
              type: "danger",
            });
          }
        }
      }).catch((reason) => {
        this.showModal({
          title: "Hata!",
          message: reason.toString(),
          type: "danger",
        });
      }).finally(() => {
        this.$store.state.general.showOverlay = false;
        this.disableHistoryButton = !this.disableHistoryButton

      });
    },
    format_date(value) {
      return moment(value).format("DD.MM.YYYY");
    },
    ...mapActions("membershipAeo", ["savePersonUniversity", "updatePersonState",
      "getPersonUniversities", "updatePersonUniversity"]),
    ...mapActions("dtAeo", ["getUniversities"]),
    ...mapGetters("dtAeo", ["getterUniversities"]),
    ...mapActions("general", ["showModal"]),

    showEditModal(item) {
      this.isEditing = true;
      const {
        id,
        universityId,
        beginDate,
        graduationDate,
        diplomaSurname,
        diplomaNumber,
        diplomaGrade,
        specialization,
        isInUse
      } = item;
      Object.assign(this.form, {
        id: id,
        universityId: universityId,
        specialization: specialization,
        graduationDate: moment(graduationDate).format('YYYY-MM-DD'),
        beginDate: moment(beginDate).format('YYYY-MM-DD'),
        diplomaSurname: diplomaSurname,
        diplomaNumber: diplomaNumber,
        diplomaGrade: diplomaGrade,
        isInUse: isInUse
      });
      this.$bvModal.show("modal-personUniversity-list-edit");
    },
    update: function () {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store.state.general.showOverlay = true;
        if (this.isEditing) {
          this.updatePersonUniversity(this.form).then(res => {
            if (res.hasError) {
              this.showModal({
                title: "Hata!",
                message: res.errorMessage,
                type: "danger",
              });
            } else {
              this.showModal({
                title: "Başarıylı!",
                message: "Okul Bilgisi kayıt edildi.",
                type: "success",
              });
              this.personUniversityList = res.data;
              if (this.form.personId == null) {
                this.form.personId = this.personUniversityList[0].personId;
              }
              console.log(this.personUniversityList)
              this.clearForm();
            }

          }).catch((reason) => {
            this.showModal({
              title: "Hata!",
              message: reason.toString(),
              type: "danger",
            });
          }).finally(() => {
            this.$store.state.general.showOverlay = false;
            //this.$nextTick(()=>{}); if necessary
            this.$bvModal.hide("modal-personUniversity-list-edit");
          })
        } else {
          this.savePersonUniversity(this.form).then(res => {
            if (res.hasError) {
              this.showModal({
                title: "Hata!",
                message: res.errorMessage,
                type: "danger",
              });
            } else {
              this.showModal({
                title: "Başarıylı!",
                message: "Okul Bilgisi kayıt edildi.",
                type: "success",
              });
              this.personUniversityList = res.data;
              if (this.form.personId == null) {
                this.form.personId = this.personUniversityList[0].personId;
              }
              console.log(this.personUniversityList)
              this.clearForm();
            }
          }).catch((reason) => {
            this.showModal({
              title: "Hata!",
              message: reason.toString(),
              type: "danger",
            });
          }).finally(() => {
            this.$store.state.general.showOverlay = false;
            this.$bvModal.hide("modal-personUniversity-list-edit");
          });
        }
        this.isEditing = false;
      }
    },
    clearForm() {
      this.isEditing = false;
      const {
        universityId,
        beginDate,
        graduationDate,
        diplomaNumber,
        diplomaGrade,
        diplomaSurname,
        specialization,
        isInUse
      } = this.form;
      const clearedProps = {
        universityId: null,
        beginDate: null,
        graduationDate: null,
        diplomaNumber: null,
        diplomaGrade: null,
        diplomaSurname: null,
        specialization: null,
        isInUse: null,
      };
      this.form = Object.assign({}, this.form, clearedProps);
      this.$v.$reset();
    },

  },
  watch: {
    "$store.state.dtAeo.universities":
        function () {
          this.universityList = this.getterUniversities();
          this.getPersonEducation(true);
        },
  },
}
</script>
