<template>
  <div id="app">
    <Popup v-if="$store.state.general.showPopup"/>
    <b-overlay :show="$store.state.general.showOverlay" rounded="sm" class="d-flex flex-fill w-100">
      <component :is="layout">
        <navbarMenu v-if="$store.state.general.showMenu"/>
        <router-view/>
      </component>
    </b-overlay>
  </div>
</template>

<script>
import layout from "./layouts/Default";
import {mapActions} from "vuex";
import Popup from "@/components/Popup";
import navbarMenu from "../src/components/menu/navbarMenu.vue"

export default {
  data() {
    return {
      connection: null,
      connectionId: null
    }
  },
  async created() {
    await this.getUserAuthorizedMenu();
  },
  components: {
    Popup,
    layout,
    navbarMenu
  },
  methods: {
    ...mapActions('general', ['getSystemMessages', 'updateMenu', 'showModal']),
    ...mapActions("auth", [
      "getUserAuthorizedMenu",
    ]),

  },

  mounted() {
    this.$root.$on('bv::modal::shown', () => {
      window.$(".modal").draggable({handle: 'header'});
    });
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || 'default-layout');
    }
  },
  watch: {

    $route(to) {
      document.title = to.meta.title || "Ankara Eczacı Odası - Portal";
    }
  }
};
</script>
