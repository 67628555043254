<template>

  <div class="standart-page">
    <div class="row">
      <div class="col"></div>
      <div class="col"><h3 class="title">Akademi - Onay Bekleyen Öğrenci Listesi</h3></div>
      <div class="col d-flex justify-content-end">
      </div>
    </div>
    <div class="card-component mb-4">
      <div class="body">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label>Öğrenci Adı</label>
              <input type="text" class="form-control" v-model="form.name"/>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label>Öğrenci Soyadı</label>
              <input type="text" class="form-control" v-model="form.surName"/>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label>TC Kimlik Numarası</label>
              <input type="text" class="form-control" v-model="form.personelIdNumber"/>
            </div>
          </div>

        </div>
        <div class="row justify-content-end">
          <div class="col-auto">
            <div class="form-group">
              <button class="btn btn-outline-secondary">Temizle</button>
            </div>
          </div>
          <div class="col-auto">
            <div class="form-group">
              <button class="btn btn-secondary" @click="getAcademyUserWaitingApprovalFunc()">Listele</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-hover">
        <thead>
        <tr>
          <th class="fit">Başvuru Tarihi</th>
          <th class="fit">Öğrenci Adı - Soyadı</th>
          <th class="fit">Okulu</th>
          <th class="fit">TC Kimlik No</th>
          <th class="fit">İşlemler</th>

        </tr>
        </thead>
        <tbody>
        <tr class="cursor-pointer"
            v-for="(item, index) in $store.state.academyAeo.DTAcademyUser.item" :key="index">
          <td class="fit">{{ format_date(item.createdAt) }}</td>
          <td class="fit">{{ item.name }} {{item.surName}}</td>
          <td v-if="isUniversitiesReady" class="fit">{{
            $store.state.dtAeo.universities.find(
            (d) => d.id === item.universityId
            ).text
            }}
          </td>
          <td class="fit">{{ item.personelIdNumber }}</td>

          <td class="fit">
            <button class="btn btn-warning button-spacing" @click="onBtnShowDetail(item)">Ayrıntılar / CV</button>
            <button class="btn btn-success button-spacing" @click="onBtnApprove(item)">Onayla</button>
            <button class="btn btn-danger button-spacing" @click="onBtnReject(item)">Reddet</button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="alert alert-danger text-center" v-if="this.$store.getters['academyAeo/getStudentCount']<=0">
      {{ "Aradığınız kriterlere uygun kayıt bulunamadı." }}
    </div>
    <div class="row" v-if="$store.state.academyAeo.DTAcademyUser.item.length > 0">
      <div class="col">
        <i class="text-secondary"
        >{{
          $store.state.academyAeo.DTAcademyUser.dtPagination.totalItems
          }}
          kayıttan {{ (form.currentPage - 1) * form.pageSize + 1 }} -
          {{ form.currentPage * form.pageSize }} arası gösterilmektedir.</i
        >
      </div>
      <div class="col">
        <div class="row">
          <div class="col d-flex justify-content-end">
            <nav>
              <paginate
                  v-if="$store.state.academyAeo.DTAcademyUser.item.length"
                  :page-count="
                  $store.state.academyAeo.DTAcademyUser.dtPagination.totalPages
                "
                  :click-handler="changePage"
                  :prev-text="'Önceki'"
                  :next-text="'Sonraki'"
                  :active-class="'active'"
                  :container-class="'pagination'"
                  v-model="form.currentPage"
              >
              </paginate>
            </nav>
          </div>
          <div class="col-2">
            <select
                v-select
                class="form-control"
                v-model="form.pageSize"
                @change="changeSize"
            >
              >
              <option value="25" selected>25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>
<script>
import {VueEditor} from "vue2-editor"
import {mapActions, mapGetters} from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/tr";
import moment from "moment";

export default {
  name: "UserList",
  components: {
    VueEditor,
    DatePicker,
    moment,
  },
  delimiters: ['${', '}'], // Avoid Twig conflicts
  data() {
    return {
      isUniversitiesReady: false,
      form: {
        name: null,
        surName: null,
        personelIdNumber: null,
        currentPage: 1,
        pageSize: 25
      },
    };
  },
  created() {
    this.$store.state.general.showMenu = true;

    this.getAcademyUserWaitingApprovalFunc();
    if (this.getterUniversities().length === 0) {
      this.getUniversities();
    } else {
      this.isUniversitiesReady = true;
    }

  },
  methods: {
    onBtnShowDetail(payload) {
      //TODO (AÇÜ): Ask Deniz Abi about the detail screen
    },
    onBtnApprove(payload) {
      console.log(payload)
      this.approveAcademyUser(payload.personelIdNumber).then(res => {
        if (res.hasError) {
          this.showModal({title: 'Hata!', message: res.errorMessage, type: 'danger'});
          return;
        }

        this.updateRoleItemGroupDTO = {
          "name": payload.name,
          "personelIdNumber": payload.personelIdNumber.toString(),
          "surname": payload.surName,
          //TODO: USERNAME FIX
          "userName": "akademikOgrenci",
          "eMail": payload.email,
          //TODO: BURASı AkADEMIOGRENCI ROLUNE AIT GUID: BURAYI FIXLE SONRA
          "selectedRoles": ["a52cac23-47bc-446e-876a-0fb9a01158d6"],
          "genderId": payload.genderId,
          //"titleId": payload.titleId,
          "otherName": payload.otherNames,
          "birthDate": payload.dateOfBirth,
          "cellular": payload.cellular,
        }
        this.addUser(this.updateRoleItemGroupDTO).then((res) => {
          if (res.hasError) {

            this.showModal({
              title: "Hata!",
              message: res.errorMessage,
              type: "danger",
            });
          } else {
            this.getAcademyUserWaitingApprovalFunc();

            this.showModal({
              title: "İşlem Başarılı",
              message: "Akademi Kullanıcısı Başarıyla Kayıt Edildi.",
              type: "success",
            });
          }
        });


      })
    },
    onBtnReject(payload) {
      //TODO (AÇÜ): Ask Deniz Abi the flow of rejection
    },
    format_date(value) {
      return moment(value).format("DD.MM.YYYY");
    },
    ...mapActions("general", ["showModal", "addUser"]),
    ...mapActions("academyAeo", ["getAcademyUserWaitingApproval", "approveAcademyUser"]),
    ...mapActions("dtAeo", ["getUniversities"]),
    ...mapGetters("dtAeo", ["getterUniversities"]),

    getAcademyUserWaitingApprovalFunc() {
      this.getAcademyUserWaitingApproval(this.form).then(res => {
        this.$store.state.general.showOverlay = false;
      });
    },

    changeSize() {
      this.$store.state.general.showOverlay = true;
      this.changePage(1)
      this.$store.state.dtAeo.duplicateForm = Object.assign({}, this.form);
      window.scrollTo(0, 0);
    },
    changePage(pageNumber) {
      this.$store.state.general.showOverlay = true;
      this.form.currentPage = pageNumber;
      this.getAcademyUserWaitingApprovalFunc();
      this.$store.state.dtAeo.duplicateForm = Object.assign({}, this.form);
      window.scrollTo(0, 0);
    },
  },
  watch: {
    "$store.state.dtAeo.universities":
        function () {
          this.isUniversitiesReady = true
        }
  }
}
</script>

<style>
.button-spacing {
  margin-right: 10px; /* Adjust the margin value as needed */
}
</style>