import axios from 'axios';
import authHeader from './auth-header';


class GeneralService {
    //IMPORTANT CHANGES 

    getUsersInRole(payload) {
        const url = `${process.env.VUE_APP_PORTAL_API_URL}Authorization/get-users-in-role`;
        const params = {
            roleName: payload
        };

        return axios.get(url, {
            headers: authHeader(),
            params: params
        });
    }

    filterRoleItems(payload) {
        return axios.get(`${process.env.VUE_APP_PORTAL_API_URL}Authorization/search-role-item?itemName=` + payload,
            {
                headers: authHeader()
            });
    }

    filterRoles(payload) {
        return axios.get(`${process.env.VUE_APP_PORTAL_API_URL}Authorization/search-role?rolName=` + payload,
            {
                headers: authHeader()
            });
    }

    filterPageGroups(payload) {
        return axios.get(`${process.env.VUE_APP_PORTAL_API_URL}Authorization/search-role-item-group?itemName=` + payload,
            {
                headers: authHeader()
            });
    }

    filterUsers(payload) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Authorization/search-user`,
            {
                Name: payload.Name,
                SurName: payload.SurName,
                PageNumber: payload.currentPage,
                PageLength: payload.pageSize
            }, {
                headers: authHeader()
            });
    }
    getRoleItemDetail(payload) {
        return axios.get(`${process.env.VUE_APP_PORTAL_API_URL}Authorization/get-role-item-detail?authorizationItemKey=` + payload,
            {
                headers: authHeader()
            });
    }

    getCompanies() {
        return axios.get(`${process.env.VUE_APP_DOCK_SCHEDULING_API_URL}Company`,
            {
                headers: authHeader()
            });
    }

    getUserDetail(payload) {
        return axios.get(`${process.env.VUE_APP_PORTAL_API_URL}Authorization/get-user-detail?userId=` + payload,
            {
                headers: authHeader()
            });
    }

    getRoleDetail(payload) {
        return axios.get(`${process.env.VUE_APP_PORTAL_API_URL}Authorization/get-role-detail?rolId=` + payload,
            {
                headers: authHeader()
            });
    }

    getRoleItemGroupDetail(payload) {
        return axios.get(`${process.env.VUE_APP_PORTAL_API_URL}Authorization/get-role-item-group-detail?authorizationItemKey=` + payload,
            {
                headers: authHeader()
            });
    }

    updateRoleItemGroupDetail(payload) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Authorization/update-role-item-group-detail`, payload,
            {
                headers: authHeader()
            });
    }

    updateUser(payload) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Authorization/update-user`, payload,
            {
                headers: authHeader()
            });
    }

    updateRole(payload) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Authorization/update-role-detail`, payload,
            {
                headers: authHeader()
            });
    }

    updateRoleItemDetail(payload) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Authorization/update-role-item-detail`, payload,
            {
                headers: authHeader()
            });
    }

    addRoleItemDetail(payload) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Authorization/add-role-item`, payload,
            {
                headers: authHeader()
            });
    }

    addRoleItemGroupDetail(payload) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Authorization/add-role-item-group`, payload,
            {
                headers: authHeader()
            });
    }

    addUser(payload) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Authorization/add-user`, payload,
            {
                headers: authHeader()
            });
    }

    addRole(payload) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Authorization/add-role`, payload,
            {
                headers: authHeader()
            });
    }


    whatIsMyDuty() {
        return axios.get(`${process.env.VUE_APP_PORTAL_API_URL}Authorization/what-is-my-duty`,
            {
                headers: authHeader()
            });
    }

    syncAnnouncementFromOldPortal() {
        return axios.get(`${process.env.VUE_APP_PORTAL_API_URL}Transfer/transfer-data-old-to-new?isPartial=true`,
            {
                headers: authHeader()
            });
    }
}

export default new GeneralService();
