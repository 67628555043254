import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'


import AuthorizationView from "@/views/auth/AuthorizationView";
import Login from "@/views/identity/Login";
import SettingsView from "@/views/settings/SettingsView";
import AnnouncementView from "@/views/announcement/AnnouncementView";
import Duyurularim from "@/views/announcement/Duyurularim";
import HaberDuyIst from "@/views/announcement/HaberDuyIst";
import HaberDuySer from "@/views/announcement/HaberDuySer";
import IlanGonder from "@/views/announcement/IlanGonder";
import Rapor from "@/views/announcement/Rapor";


import PhaSearch from "@/views/information-process/PhaSearch";
import EczKayList from "@/views/information-process/EczKayList";
import ExcelRapAlma from "@/views/information-process/ExcelRapAlma";
import FotosizEcz from "@/views/information-process/FotosizEcz";
import KavEcz from "@/views/information-process/KavEcz";
import KulSifGon from "@/views/information-process/KulSifGon";
import KulIslYon from "@/views/information-process/KulIslYon";
import OzgHav from "@/views/information-process/OzgHav";
import ListGun from "@/views/information-process/ListGun";
import TEBRecTev from "@/views/information-process/TEBRecTev";


import UyeAra from "@/views/information-process/UyeAra";

import UyeKaydet from "@/views/information-process/UyeKaydet";
import ZiyList from "@/views/information-process/ZiyList";
import BolgeIslem from "@/views/information-process/BolgeIslem";
import Parametreler from "@/views/information-process/Parametreler";
import Reklam from "@/views/information-process/Reklam";
import BorcTakip from "@/views/receiving/BorcTakip";
import MesajAra from "@/views/receiving/MesajAra";
import MesajUye from "@/views/receiving/MesajUye";
import SiraliOdeme from "@/views/receiving/SiraliOdeme";
import TahTesTut from "@/views/receiving/TahTesTut";
import UyeBorclandir from "@/views/receiving/UyeBorclandir";
import UyeTahOde from "@/views/receiving/UyeTahOde";
import EczAraMember from "@/views/membership/EczAraMember";
import EczKayit from "@/views/membership/EczKayit";
import ExcelRapAlmaMember from "@/views/membership/ExcelRapAlmaMember";
import OzgHavMember from "@/views/membership/OzgHavMember";
import MemberDetail from "@/views/membership/MemberDetail.vue";
import MemberSearch from "@/views/membership/MemberSearch";
import MemberRegistration from "@/views/membership/MemberRegistration.vue";
import BorcTakipReceivingAdmin from "@/views/receiving-admin/BorcTakipReceivingAdmin";
import MesajAraReceivingAdmin from "@/views/receiving-admin/MesajAraReceivingAdmin";
import MesajUyeReceivingAdmin from "@/views/receiving-admin/MesajUyeReceivingAdmin";
import MuhOdKont from "@/views/receiving-admin/MuhOdKont";
import SiraliOdemeReceivingAdmin from "@/views/receiving-admin/SiraliOdemeReceivingAdmin";
import UyeBorcReceivingAdmin from "@/views/receiving-admin/UyeBorcReceivingAdmin";
import UyeTahOdReceivingAdmin from "@/views/receiving-admin/UyeTahOdReceivingAdmin";
import CarLisBas from "@/views/crisis/CarLisBas";
import CarLisIlce from "@/views/crisis/CarLisIlce";
import EczAraNobet from "@/views/crisis/EczAraNobet";
import EczKayitNobet from "@/views/crisis/EczKayitNobet";
import EczNobetDegisimi from "@/views/crisis/EczNobetDegisimi";
import ExcelRapAlmaNobet from "@/views/crisis/ExcelRapAlmaNobet";
import HaritaBolArama from "@/views/crisis/HaritaBolArama";
import HaritaBolGorNobAra from "@/views/crisis/HaritaBolGorNobAra";
import HaritaIlceArama from "@/views/crisis/HaritaIlceArama";
import HaritaIlceGorNobAra from "@/views/crisis/HaritaIlceGorNobAra";
import NobetAra from "@/views/crisis/NobetAra";
import NobetAnasayfa from "@/views/crisis/NobetAnasayfa";
import NobetKartBas from "@/views/crisis/NobetKartBas";
import PerNobDuz from "@/views/crisis/PerNobDuz";
import DuyuruOnay from "@/views/announcement/DuyuruOnay.vue";
import AkademiUser from "@/views/academy/UserList.vue";


Vue.use(VueRouter)


const routes = [

    {
        name: 'login',
        path: '/login',
        meta: {
            title: 'Giriş Yap',
            layout: 'empty-layout'
        },
        component: Login
    },
    {
        name: 'dashboard',
        path: '/',
        meta: {
            title: 'AEO',
        },
        component: Dashboard,
    },
    {
        name: 'Authorization',
        path: '/authorization',
        meta: {
            title: 'Authorization-AEO',
        },
        component: AuthorizationView,
    },
    {
        name: 'Settings',
        path: '/settings',
        meta: {
            title: 'Settings-AEO',
        },
        component: SettingsView,
    },
    {
        name: 'New Announcement',
        path: '/Page/add-new-announcement',
        meta: {
            title: ' -AEO | DUYURU',
        },
        component: AnnouncementView,
    },
    {
        name: 'PhaSearch',
        path: '/pha-search',
        meta: {
            title: ' -AEO | Eczane Ara',
        },
        component: PhaSearch,
    },
    {
        path: '/announcement/search-announcement',
        meta: {
            title: 'AEO | Haber Yönetimi'
        },
        component: () => import('../views/announcement/SearchAnnouncementView.vue'),
    },

    {
        path: '/workplace',
        meta: {
            title: 'AEO | Bölge Ekle / Düzenle'
        },
        component: () => import('../views/workplace/AddWorkplace.vue'),
    },


    {
        path: '/announcement/update/:id',
        meta: {
            title: 'AEO | Duyuru Güncelleme'
        },
        component: () => import('../views/announcement/AnnouncementView.vue'),
    },
    {
        name: 'DuyuruOnay',
        path: '/Page/approve-pages',
        meta: {
            title: ' -AEO | Duyuru Onay',
        },
        component: DuyuruOnay,
    },
    {
        name: 'Duyurularim',
        path: '/duyuru/duyurularim',
        meta: {
            title: ' -AEO | Duyurularım',
        },
        component: Duyurularim,
    },
    {
        name: 'Akademi',
        path: '/Academy/user-list',
        meta: {
            title: ' -AEO | Akademi',
        },
        component: AkademiUser,
    },
    {
        name: 'HaberDuyIst',
        path: '/duyuru/haber-duy-ist',
        meta: {
            title: ' -AEO | Haber Duyuru İstatistik',
        },
        component: HaberDuyIst,
    },
    {
        name: 'HaberDuySer',
        path: '/duyuru/haber-duy-ser',
        meta: {
            title: ' -AEO | Haber Duyuru Servisi',
        },
        component: HaberDuySer,
    },
    {
        name: 'IlanGonder',
        path: '/duyuru/ilan-gonder',
        meta: {
            title: ' -AEO | İlan Gönder',
        },
        component: IlanGonder,
    },
    {
        name: 'Rapor',
        path: '/duyuru/rapor',
        meta: {
            title: ' -AEO | Rapor',
        },
        component: Rapor,
    },
    {
        name: 'EczKayList',
        path: '/bilgi-islem/ecz-kay-list',
        meta: {
            title: ' -AEO | Eczane Kaydı Liste',
        },
        component: EczKayList,
    },
    {
        name: 'ExcelRapAlma',
        path: '/bilgi-islem/excel-rap-alma',
        meta: {
            title: ' -AEO | Excel Rapor Alma',
        },
        component: ExcelRapAlma,
    },
    {
        name: 'FotosizEcz',
        path: '/bilgi-islem/fotosiz-ecz',
        meta: {
            title: ' -AEO | Fotoğrafsız Eczacılar',
        },
        component: FotosizEcz,
    },
    {
        name: 'KavEcz',
        path: '/bilgi-islem/kav-ecz',
        meta: {
            title: ' -AEO | Kavşak Eczacılar',
        },
        component: KavEcz,
    },
    {
        name: 'KulSifGon',
        path: '/bilgi-islem/kul-sif-gon',
        meta: {
            title: ' -AEO | Kullanıcı Şifre Gönderme',
        },
        component: KulSifGon,
    },
    {
        name: 'KulIslYon',
        path: '/bilgi-islem/kul-isl-yon',
        meta: {
            title: ' -AEO | Kullanıcı İşlem Yönetimi',
        },
        component: KulIslYon,
    },
    {
        name: 'ListGun',
        path: '/bilgi-islem/list-gun',
        meta: {
            title: ' -AEO | Liste Güncelleme',
        },
        component: ListGun,
    },
    {
        name: 'OzgHav',
        path: '/bilgi-islem/ozg-hav',
        meta: {
            title: ' -AEO | Özgeçmiş Havuzu',
        },
        component: OzgHav,
    },
    {
        name: 'TEBRecTev',
        path: '/bilgi-islem/teb-rec-tev',
        meta: {
            title: ' -AEO | TEB Reçete Tevzi',
        },
        component: TEBRecTev,
    },
    {
        name: 'MemberRegistration',
        path: '/member/registration',
        meta: {
            title: ' AEO | Üye Kaydet',
        },
        component: MemberRegistration,
    },
    {
        name: 'UyeAra',
        path: '/bilgi-islem/uye-ara',
        meta: {
            title: ' -AEO | Üye Ara',
        },
        component: UyeAra,
    },
    {
        name: 'UyeDetay',
        path: '/member/member-detail/:personId',
        meta: {
            title: ' AEO | Üye Detay',
        },
        component: MemberDetail,
    },
    {
        name: 'UyeKaydet',
        path: '/bilgi-islem/uye-kaydet',
        meta: {
            title: ' -AEO | Üye Kaydet',
        },
        component: UyeKaydet,
    },
    {
        name: 'ZiyList',
        path: '/bilgi-islem/ziy-list',
        meta: {
            title: ' -AEO | Ziyaret Listesi',
        },
        component: ZiyList,
    },
    {
        name: 'BolgeIslem',
        path: '/bilgi-islem/bolge-islem',
        meta: {
            title: ' -AEO | Bölge İşlemleri',
        },
        component: BolgeIslem,
    },
    {
        name: 'Parametreler',
        path: '/bilgi-islem/parametreler',
        meta: {
            title: ' -AEO | Parametreler',
        },
        component: Parametreler,
    },
    {
        name: 'Reklam',
        path: '/bilgi-islem/reklam',
        meta: {
            title: ' -AEO | Reklam',
        },
        component: Reklam,
    },


//******************** 


    {
        name: 'BorcTakip',
        path: '/tahsilat/borc-takip',
        meta: {
            title: ' -AEO | Borçlandırma Takip',
        },
        component: BorcTakip,
    },
    {
        name: 'MesajAra',
        path: '/tahsilat/mesaj-ara',
        meta: {
            title: ' -AEO | Mesaj Ara',
        },
        component: MesajAra,
    },
    {
        name: 'MesajUye',
        path: '/tahsilat/mesaj-uye',
        meta: {
            title: ' -AEO | Mesaj Üye',
        },
        component: MesajUye,
    },
    {
        name: 'SiraliOdeme',
        path: '/tahsilat/sirali-odeme',
        meta: {
            title: ' -AEO | Sıralı Ödeme',
        },
        component: SiraliOdeme,
    },
    {
        name: 'TahTesTut',
        path: '/tahsilat/tah-tes-tut',
        meta: {
            title: ' -AEO | Tahsilat Teslim Tutanak Ödeme',
        },
        component: TahTesTut,
    },
    {
        name: 'UyeBorclandir',
        path: '/tahsilat/uye-borclandir',
        meta: {
            title: ' -AEO | Üye Borçlandır',
        },
        component: UyeBorclandir,
    },
    {
        name: 'UyeTahOde',
        path: '/tahsilat/uye-tah-ode',
        meta: {
            title: ' -AEO | Üye Tahsilat-Ödeme',
        },
        component: UyeTahOde,
    },

    //****** */
    {
        name: 'EczAraMember',
        path: '/uyelik/ecz-ara',
        meta: {
            title: ' -AEO | Eczane Ara',
        },
        component: EczAraMember,
    },
    {
        name: 'EczKayit',
        path: '/uyelik/ecz-kayit',
        meta: {
            title: ' -AEO | Eczane Kayıt',
        },
        component: EczKayit,
    },
    {
        name: 'ExcelRapAlmaMember',
        path: '/uyelik/excel-rap-alma',
        meta: {
            title: ' -AEO | Excel Rapor Alma Member',
        },
        component: ExcelRapAlmaMember,
    },
    {
        name: 'OzgHavMember',
        path: '/uyelik/ozg-hav',
        meta: {
            title: ' -AEO | Özgeçmiş Havuzu Member',
        },
        component: OzgHavMember,
    },
    {
        name: 'MemberSearch',
        path: '/member/member-search',
        meta: {
            title: ' -AEO | Üye Ara Member',
        },
        component: MemberSearch,
    },
    {
        name: 'BorcTakipReceivingAdmin',
        path: '/tahsilat-admin/borc-takip',
        meta: {
            title: ' -AEO | Borçlandırma Takip Receiving Admin',
        },
        component: BorcTakipReceivingAdmin,
    },
    {
        name: 'MesajAraReceivingAdmin',
        path: '/tahsilat-admin/mesaj-ara',
        meta: {
            title: ' -AEO | Mesaj Ara Receiving Admin',
        },
        component: MesajAraReceivingAdmin,
    },
    {
        name: 'MesajUyeReceivingAdmin',
        path: '/tahsilat-admin/mesaj-uye',
        meta: {
            title: ' -AEO | Mesaj Üye Receiving Admin',
        },
        component: MesajUyeReceivingAdmin,
    },
    {
        name: 'MuhOdKont',
        path: '/tahsilat-admin/muh-od-kont',
        meta: {
            title: ' -AEO | Muhasebe Ödeme Kontrol',
        },
        component: MuhOdKont,
    },
    {
        name: 'SiraliOdemeReceivingAdmin',
        path: '/tahsilat-admin/sirali-odeme',
        meta: {
            title: ' -AEO | Sıralı Dağıtım Ödeme Takip-Receiving Admin',
        },
        component: SiraliOdemeReceivingAdmin,
    },
    {
        name: 'UyeBorcReceivingAdmin',
        path: '/tahsilat-admin/uye-borc',
        meta: {
            title: ' -AEO | Üye Borçlandır-Receiving Admin',
        },
        component: UyeBorcReceivingAdmin,
    },
    {
        name: 'UyeTahOdReceivingAdmin',
        path: '/tahsilat-admin/uye-tah-od',
        meta: {
            title: ' -AEO | Üye Borçlandır-Receiving Admin',
        },
        component: UyeTahOdReceivingAdmin,
    },

    //****** */
    {
        name: 'CarLisBas',
        path: '/nobet/car-lis-bas',
        meta: {
            title: ' -AEO | Çarşaf Liste Bastır',
        },
        component: CarLisBas,
    },
    {
        name: 'CarLisIlce',
        path: '/nobet/car-lis-ilce',
        meta: {
            title: ' -AEO | Çarşaf Liste İlçe',
        },
        component: CarLisIlce,
    },
    {
        name: 'EczAraNobet',
        path: '/nobet/ecz-ara',
        meta: {
            title: ' -AEO | Çarşaf Liste İlçe',
        },
        component: EczAraNobet,
    },
    {
        name: 'EczKayitNobet',
        path: '/nobet/ecz-kayit',
        meta: {
            title: ' -AEO | Eczane Kayıt Nöbet',
        },
        component: EczKayitNobet,
    },
    {
        name: 'EczNobetDegisimi',
        path: '/nobet/ecz-nobet-degisimi',
        meta: {
            title: ' -AEO | Eczane Nöbet Değişimi',
        },
        component: EczNobetDegisimi,
    },
    {
        name: 'ExcelRapAlmaNobet',
        path: '/nobet/excel-rap-alma',
        meta: {
            title: ' -AEO | Excel Rapor Alma Nöbet',
        },
        component: ExcelRapAlmaNobet,
    },
    {
        name: 'HaritaBolArama',
        path: '/nobet/haritada-bol-arama',
        meta: {
            title: ' -AEO | Haritada Bölge Arama',
        },
        component: HaritaBolArama,
    },
    {
        name: 'HaritaBolGorNobAra',
        path: '/nobet/haritada-bol-gor-nob-ara',
        meta: {
            title: ' -AEO | Haritada Bölgeye Göre Nöbet Ara',
        },
        component: HaritaBolGorNobAra,
    },
    {
        name: 'HaritaIlceArama',
        path: '/nobet/harita-ilce-arama',
        meta: {
            title: ' -AEO | Haritada İlçe Arama',
        },
        component: HaritaIlceArama,
    },
    {
        name: 'HaritaIlceGorNobAra',
        path: '/nobet/harita-ilce-gor-ara',
        meta: {
            title: ' -AEO | Haritada İlçeye Göre Nöbet Ara',
        },
        component: HaritaIlceGorNobAra,
    },
    {
        name: 'NobetAnasayfa',
        path: '/nobet/nobet-anasayfa',
        meta: {
            title: ' -AEO | Nöbet Anasayfa',
        },
        component: NobetAnasayfa,
    },
    {
        name: 'NobetAra',
        path: '/nobet/nobet-ara',
        meta: {
            title: ' -AEO | Nöbet Ara',
        },
        component: NobetAra,
    },
    {
        name: 'NobetKartBas',
        path: '/nobet/nobet-kart-bas',
        meta: {
            title: ' -AEO | Nöbet Kartı Bastır',
        },
        component: NobetKartBas,
    },
    {
        name: 'PerNobDuz',
        path: '/nobet/per-nob-duz',
        meta: {
            title: ' -AEO | Perifer Nöbet Dönem Düzenle',
        },
        component: PerNobDuz,
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('token');


    //Burada yetki kontrolü yaparak ilerleyelim..


    //Check token's validity for every route changes !
    if (authRequired && (loggedIn === 'null' || !loggedIn || loggedIn === 'undefined')) {
        return next(`/login?returnUrl=${encodeURIComponent(to.fullPath)}`);
    }

    next(vm => {
        vm.prevRoute = from
    });
})

export default router
