import Vue from 'vue'
import App from './App.vue'

// import './polyfills'

import router from './router'
import store from './store'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import CircularCountDownTimer from "vue-circular-count-down-timer"
import LiquorTree from 'liquor-tree'
import ability from './config/ability'
import {abilitiesPlugin, Can} from '@casl/vue'
import ToggleButton from 'vue-js-toggle-button'
import Vuelidate from 'vuelidate'
import VueTheMask from 'vue-the-mask'
import VueMoment from 'vue-moment'
import Multiselect from 'vue-multiselect'
import Paginate from 'vuejs-paginate'
import vueMask from 'vue-jquery-mask'
import OtpInput from "@bachdgvn/vue-otp-input"
import $ from "jquery";
import 'jquery-ui-dist/jquery-ui';
import NumericDirective from "@/helpers/vue-numeric-directive";
import "@/helpers/formats";
import vSelect from 'vue-select'
import {i18n} from './i18n'

window.$ = window.jQuery = $;
import 'select2';
import 'select2/dist/css/select2.css';
import '@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.min.css'
import './plugins/fontawesome'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import './assets/scss/main.scss'
import Default from './layouts/Default'
import Empty from './layouts/Empty'
import lineClamp from 'vue-line-clamp'
import {Photoshop, Slider} from 'vue-color'
import * as VueGoogleMaps from "vue2-google-maps" // Import package
Vue.config.productionTip = false
Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyA4D99f0HyuHhUuory2jrS22XVuXivEQuM",
        libraries: "places"
    }
});
Vue.component("v-otp-input", OtpInput)
Vue.component('photoshop-picker', Photoshop)
Vue.component('slider-picker', Slider)
Vue.component('v-select', vSelect)
Vue.use(vueMask);
Vue.use(VueTheMask)
Vue.use(Vuelidate)
Vue.use(ToggleButton)
Vue.use(abilitiesPlugin, ability)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(LiquorTree)
Vue.use(lineClamp)
Vue.use(VueMoment)
Vue.use(CircularCountDownTimer)

Vue.component('default-layout', Default)
Vue.component('empty-layout', Empty)
Vue.component('Can', Can)
Vue.component('multiselect', Multiselect)
Vue.component('paginate', Paginate)
Vue.directive('uppercase', {
    update(el) {
        const start = el.selectionStart;
        const end = el.selectionEnd;

        const sourceValue = el.value;
        const newValue = sourceValue.toLocaleUpperCase('TR');

        if (sourceValue !== newValue) {
            el.value = newValue;
            el.setSelectionRange(start, end);
            el.dispatchEvent(new Event('input', {bubbles: true}));
        }
    },
});

function updateFunction(el, binding) {
    Vue.nextTick(function () {
        let options = binding.value || {};

        $(el).select2(Object.assign({}, {
            templateResult: function (data, container) {
                if (data.element) {
                    window.$(container).addClass(window.$(data.element).attr("class"));
                }
                return data.text;
            },
            theme: 'bootstrap4',
            language: {
                "noResults": function () {
                    return "Sonuç bulunamadı";
                }
            },
            dropdownParent: $(el).parent(),
            dropdownAutoWidth: true,
            width: 'auto'
        }, options))
            // .val(el.value ?? null)
            .trigger('change')
            .on("select2:select select2:unselect", (e) => {
                el.dispatchEvent(new Event('change', {target: e.target}));
            }).on("select2:opening",
            function () {
                window.$(".modal-content").removeAttr("tabindex", "-1");
            }).on("select2:close",
            function () {
                window.$(".modal-content").attr("tabindex", "-1");
            });
    });
}

Vue.directive('select', {
    inserted: updateFunction,
    componentUpdated: updateFunction,
});
Vue.use(NumericDirective);
window.ability = ability
new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
