<template>
  <div>
    <b-overlay :show="showOverlay" rounded="sm" class="d-flex flex-fill w-100"></b-overlay>
    <b-modal
        id="modal-role-edit"
        size="lg"
        centered
        :title="modalTitle"
        ok-title="Kaydet"
        ok-variant="success"
        cancel-title="İptal"
        cancel-variant="outline-secondary"
        :busy="showOverlay"
        @ok.prevent="save"
        @hidden="clear"
    >
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label>Rol Adı</label>
            <input type="text" class="form-control" v-model="form.rolName"/>
          </div>
        </div>

      </div>

    </b-modal>
    <table class="table table-hover">
      <thead>
      <tr>
        <th class="fit">Rol Adı</th>


        <th class="fit text-right">
          <button class="btn btn-sm" @click.stop="addPage">
            <PlusCircleIcon/>
          </button>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
          v-for="(item, index) in $store.state.general.roles"
          :key="index"
          class="cursor-pointer"
          @click="showEditModal(item)"
      >
        <td class="fit">{{ item.name }}</td>


        <td class="fit text-right">

        </td>
      </tr>
      </tbody>
    </table>

  </div>

</template>

<script>
import PlusCircleIcon from "@/components/PlusCircleIcon";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/tr";
import {mapActions, mapGetters} from "vuex";
import {required, requiredIf} from "vuelidate/lib/validators";
import {forEach} from "lodash";

export default {
  data() {
    return {
      selectedEmployee: null,
      openingType: "",
      showList: true,
      showError: false,
      selectedId: null,
      modalTitle: "Rol Bilgisi Güncelleme",
      isEditing: false,
      showOverlay: false,
      pageGroups: [],
      temp: {},
      roles: [],

      form: {
        rolId: "",
        rolName: ""
      },

      updateItemGroupDTO: {}
    };
  },
  components: {
    PlusCircleIcon,
  },

  computed: {
    ...mapGetters("general", ["getMessage"]),
  },

  methods: {
    async addPage() {
      this.isEditing = false;
      await this.showEditModal(null);
    },
    async save() {
      this.showOverlay = true;
      if (this.isEditing === true) {
        this.updateRoleItemGroupDTO = {
          "rolId": this.form.rolId.toString(),
          "RolAdi": this.form.rolName
        }
        this.$nextTick(() => {
          this.$bvModal.hide("modal-role-edit");

        })
        this.$store.state.general.showOverlay = true;

        await this.updateRole(this.updateRoleItemGroupDTO).then((res) => {
          if (res.hasError) {
            this.showModal({
              title: "Hata!",
              message: res.errorMessage,
              type: "danger",
            });
          } else {
            this.$store.state.general.showOverlay = false;
            this.showOverlay = false;
            this.showModal({
              title: "İşlem Başarılı",
              message: "Kayıt Başarıyla Güncellendi",
              type: "success",
            });
          }
        });
      } else {

        this.updateRoleItemGroupDTO = {
          "RolAdi": this.form.rolName
        }

        this.$nextTick(() => {
          this.$bvModal.hide("modal-role-edit");
        })
        this.$store.state.general.showOverlay = true;
        // Send to api
        await this.addRole(this.updateRoleItemGroupDTO).then((res) => {
          if (res.hasError) {

            this.showModal({
              title: "Hata!",
              message: res.errorMessage,
              type: "danger",
            });
          } else {

            this.showModal({
              title: "İşlem Başarılı",
              message: "Kayıt Başarıyla Güncellendi",
              type: "success",
            });
          }
        });
      }
      this.$store.state.general.showOverlay = false;
      this.showOverlay = false;

      this.clear();


    },
    async showEditModal(item) {
      this.clear();
      this.selectedRoles = [];
      if (item !== null) {
        this.isEditing = true;

        this.modalTitle = "User Yetki/Bilgi Güncelleme";

        this.$store.state.general.showOverlay = true;
        this.showOverlay = true;
        await this.getRoleDetail(item.id).then((res) => {
          if (res.hasError) {

            this.showModal({
              title: "Hata!",
              message: res.errorMessage,
              type: "danger",
            });
          } else {
            this.$bvModal.show("modal-role-edit");
          }
        });

        this.temp = this.$store.state.general.CurrentRole;
        this.form.rolName = this.temp.name;
        this.form.rolId = this.temp.id;

      } else this.$bvModal.show("modal-role-edit");
      this.$store.state.general.showOverlay = false;
      this.showOverlay = false;

    },

    ...mapActions("general", ["showModal", "filterRoles", "updateRole", "addRole", "getRoleDetail"]),

    clear() {
      this.isEditing = false;

      this.form = Object.assign(
          {},
          {
            rolName: "",
            rolId: ""
          }
      );


    },
  },
  created() {
    this.filterRoles('');
  }


}
</script>


<style scoped>

</style>