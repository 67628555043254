<template>
<div class="standart-page">
    <div class="row">
      <div class="col-md-12 mb-5"><h3 class="title">Fotoğrafsız Eczacılar</h3></div>
      <div class="col-md-12">
        <nav>
  <div class="nav nav-tabs row justify-content-around" id="nav-tab" role="tablist">

    <button class="nav-link active p-3 col-md-2" id="nav-eczecz-tab" data-bs-toggle="tab" data-bs-target="#nav-eczecz" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Eczane Eczacıları</button>

    <button class="nav-link p-3 col-md-2" id="nav-kamuecz-tab" data-bs-toggle="tab" data-bs-target="#nav-kamuecz" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Kamu Eczacıları</button>

    <button class="nav-link p-3 col-md-2" id="nav-ozelecz-tab" data-bs-toggle="tab" data-bs-target="#nav-ozelecz" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Özel Sektör Eczacıları</button>

    <button class="nav-link p-3 col-md-2" id="nav-mesicraa-tab" data-bs-toggle="tab" data-bs-target="#nav-mesicraa" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Mesleğini İcraa Etmeyen Üyeler</button>
    
  </div>
</nav>
<div class="tab-content pt-5" id="nav-tabContent">
  <div class="tab-pane fade show active" id="nav-eczecz" role="tabpanel" aria-labelledby="nav-eczecz-tab" tabindex="0">
    <div class="d-flex row justify-content-around">
      <button class="btn btn-primary mb-3 col-md-2">Bölgelere Göre Eczacılar</button>
      <button class="btn btn-primary mb-3 col-md-2">İlçelere Göre Eczacılar</button>
    </div>
  </div>
  <div class="tab-pane fade" id="nav-kamuecz" role="tabpanel" aria-labelledby="nav-kamuecz-tab" tabindex="0">
    <div class="d-flex row justify-content-around">
      <button class="btn btn-primary mb-3 col-md-2">Hastaneler</button>
      <button class="btn btn-primary mb-3 col-md-2">Sosyal Güvenlik Kurumu</button>
      <button class="btn btn-primary mb-3 col-md-2">Sağlık Bakanlığı</button>
      <button class="btn btn-primary mb-3 col-md-2">Üniversiteler</button>
    </div>
  </div>
  <div class="tab-pane fade" id="nav-ozelecz" role="tabpanel" aria-labelledby="nav-ozelecz-tab" tabindex="0">
    <div class=" row ">
    <div class="col-md-6">
      <div class="form-group">
              <label>Kurum Seçiniz</label>
              <multiselect
                  v-model="form.subjectGroups"
                  :options="this.$store.state.dtAeo.subjectTypeGrouped"
                  :multiple="true"
                  group-values="childSubjectTypes"
                  selectLabel="Sadece bu itemi seçmek için tıkla"
                  selectGroupLabel="Bu grubun tamamını seçmek için tıkla"
                  selectedLabel="Seçili"
                  @open="openSubjectType()"
                  deselectLabel="Bu itemi silmek için tıkla"
                  deselectGroupLabel="Bu grubun tamamını silmek için tıkla"
                  group-label="parentSubjectType" :group-select="true" placeholder="Kurum Seçiniz"
                  track-by="text"
                  label="text"><span
                  slot="noResult">Aradığın kriterlere uygun kayıt bulunamadı.</span>
              </multiselect>
                <button class="btn btn-warning col-md-4 mt-3">Rapor Al</button>
            </div> 
    </div>   
  </div>
  </div>
  <div class="tab-pane fade" id="nav-mesicraa" role="tabpanel" aria-labelledby="nav-mesicraa-tab" tabindex="0">
    <div class="table-responsive row">
      <table class="table col-md-6">
        <thead>
        <tr>
          <th >Adı Soyadı</th>
        </tr>
        </thead>
        <tbody>
        <tr><td>Ecz. Furkan Erbagci</td></tr>
        <tr><td>Ecz. Furkan Erbagci</td></tr>
        <tr><td>Ecz. Furkan Erbagci</td></tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
      
      </div>
    </div>
  </div>
</template>
<script>
import {VueEditor} from "vue2-editor"
import {mapActions} from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/tr";
import moment from "moment";

export default {
  name: "PhaSearch",
  components: {
    VueEditor,
    DatePicker,
    moment,
  },
  delimiters: ['${', '}'], // Avoid Twig conflicts
  data() {
    return {


      images: [],
      index: null,
      open: false,
      day_difference: null,


      announcement_head_pic_file_local: null,
      form: {
        announcement_title: null,
        announcement_html: "<h4>Aşağıdaki önizleme butonuna basarsan, burada yazılanların duyurun onaylandığında nasıl gözükeceklerini görebilirsin.</h4>",
        announcement_head_pic: null,
        announcement_head_pic_file: null,
        announcement_start_date: null,
        announcement_type: null,
        subjectGroups: [],
        publishPlaces: [],
        announcement_charged_person: null
      },

    };
  },
  created() {
    this.getPageType();
    this.getPublishPlace();
    this.$store.dispatch("general/changeMenu", "InfProMenu");

  },
  methods: {

    customLabel({options_grouping}) {
      return `${options_grouping.lang}`
    },

    openPublishPlaces() {
      if (this.$store.state.dtAeo.publishPlaces.length === 0) {
        this.getPublishPlace();
      }
    },

    openSubjectType() {
      if (this.$store.state.dtAeo.subjectTypeGrouped.length === 0) {
        this.getSubjectTypeGrouped();
      }
    },


    Up(item) {
      //TODO: Böyle bir workaround yok. :)
      let test = Object.assign([], this.images)
      let item0 = test.findIndex(s => s.fileName === item.fileName);
      if (item0 != null) {
        let temp = test[item0 - 1];//1 üsttekinin item'ını aldık.
        test[item0 - 1] = item; //1 üste 1 aşağısındakini koyduk
        test[item0] = temp;
        this.images = Object.assign([], test);
      }
    },
    Down(item) {
      //TODO: Böyle bir workaround yok. :)
      let test = Object.assign([], this.images)
      let item0 = test.findIndex(s => s.fileName === item.fileName);
      if (item0 != null) {
        let temp = test[item0 + 1];//1 üsttekinin item'ını aldık.
        test[item0 + 1] = item; //1 üste 1 aşağısındakini koyduk
        test[item0] = temp;
        this.images = Object.assign([], test);
      }
    },
    ...mapActions("general", ["showModal"]),
    ...mapActions("dtAeo", ["getPublishPlace", "getSubjectTypeGrouped", "getPageType"]),

    onChangeFileUpload(e, isMultiple) {
      // console.log(isMultiple)
      if (isMultiple) {
        const tmpFiles = e.target.files
        //console.log(tmpFiles)
        for (let f of tmpFiles) {
          // console.log(f);
          if (f.name.match(/.(jpg|jpeg|png)$/i)) {
            let obj = {
              file: f,
              fileName: f.name,
              path: URL.createObjectURL(f)
            }
            this.images.push(obj);
          }
        }
      } else {
        if (
            !this.$refs.sfile.files[0].name.match(
                /.(jpg|jpeg|png)$/i
            )
        ) {
          this.showModal({
            title: "Hata!",
            message: "Sadece *.jpeg |  *.jpg | *.png uzantılı dosyalar yükleyebilirsin.",
            type: "danger",
          });
          this.announcement_head_pic_file_local = null;
        } else {
          this.form.announcement_head_pic_file = this.$refs.sfile.files[0];
          this.form.announcement_head_pic = this.$refs.sfile.files[0].name;
        }
      }

    },
    handleChange(value) {
      if (value[0] == null) {
        this.day_difference = null
        return;
      }
      let finish_day = moment(String(value[1]), "DD.MM.YYYY")
      let start_day = moment(String(value[0]), "DD.MM.YYYY")
      this.day_difference = Math.ceil((finish_day - start_day) / (1000 * 60 * 60 * 24));
    },


    onChange(tmpFiles) {
      for (let f of tmpFiles) {
        // console.log(f);
        if (f.name.match(/.(jpg|jpeg|png)$/i)) {
          let obj = {
            file: f,
            fileName: f.name,
            path: URL.createObjectURL(f)
          }
          this.images.push(obj);
        }
      }
    },

    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('bg-green-300')) {
        event.currentTarget.classList.remove('bg-gray-100');
        event.currentTarget.classList.add('bg-green-300');
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    },
    drop(event) {
      event.preventDefault();
      this.$refs.mfile.files = event.dataTransfer.files;
      this.onChange(this.$refs.mfile.files); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    }
  }
}
</script>

<style scoped>
