var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"standart-page"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-md-3"}),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"table-responsive row"},[_c('table',{staticClass:"table col-md-12"},[_vm._m(1),_c('tbody',[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15),_c('tr',[_c('td',[_c('router-link',{staticClass:"text-primary",attrs:{"tag":"a","to":"/duyuru/ilan-gonder","href":""}},[_vm._v("İlan göndermek için tıklayınız ")])],1)])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12 mb-5"},[_c('h3',{staticClass:"title"},[_vm._v("Kavşak Eczacılar")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Bağlantılar")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_c('a',{staticClass:"text-primary",attrs:{"href":""}},[_vm._v("Oda Kayıt Belgesi Almak İçin Tıklayınız")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_c('a',{staticClass:"text-primary",attrs:{"href":""}},[_vm._v("ANKARA ECZACI ODASI Kişisel Verilerin İşlenmesine İlişkin Aydınlatma Metni")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_c('a',{staticClass:"text-primary",attrs:{"href":""}},[_vm._v("2022 yılı Olağan Seçimsiz Genel Kurul Çalışma Raporu")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_c('a',{staticClass:"text-primary",attrs:{"href":""}},[_vm._v("2021 yılı Olağan Seçimli Genel Kurul Çalışma Raporu")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_c('a',{staticClass:"text-primary",attrs:{"href":""}},[_vm._v("2020 yılı Olağan Seçimsiz Genel Kurul Çalışma Raporu")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_c('a',{staticClass:"text-primary",attrs:{"href":""}},[_vm._v("2019 yılı Olağan Seçimli Genel Kurul Çalışma Raporu")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_c('a',{staticClass:"text-primary",attrs:{"href":""}},[_vm._v("2018 yılı Olağan Seçimsiz Genel Kurul Çalışma Raporu")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_c('a',{staticClass:"text-primary",attrs:{"href":""}},[_vm._v("2017 yılı Olağan Seçimli Genel Kurul Çalışma Raporu")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_c('a',{staticClass:"text-primary",attrs:{"href":""}},[_vm._v("2016 yılı Olağan Seçimsiz Genel Kurul Çalışma Raporu")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_c('a',{staticClass:"text-primary",attrs:{"href":""}},[_vm._v("2015 yılı Olağan Seçimli Genel Kurul Çalışma Raporu")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_c('a',{staticClass:"text-primary",attrs:{"href":""}},[_vm._v("Eczane Mesai Çizelgesi")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_c('a',{staticClass:"text-primary",attrs:{"href":""}},[_vm._v("Eczane Mesai Saatleri")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_c('a',{staticClass:"text-primary",attrs:{"href":""}},[_vm._v("Örnek Teknisyen Sözleşmesi")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_c('a',{staticClass:"text-primary",attrs:{"href":""}},[_vm._v("ÇALIŞMA SÜRELERİ VE DİNLENME SÜRELERİ 29.03.2016")])])])
}]

export { render, staticRenderFns }