import { render, staticRenderFns } from "./ZiyList.vue?vue&type=template&id=5d5003ac&scoped=true&"
import script from "./ZiyList.vue?vue&type=script&lang=js&"
export * from "./ZiyList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d5003ac",
  null
  
)

export default component.exports