<template>
  <div class="standart-page">
    <div class="row">
      <div class="col"></div>
      <div class="col-md-12">
        <h3 v-if="!isEditing" class="title">YENİ DUYURU GİRİŞİ</h3>
        <h3 v-else class="title">DUYURU DÜZENLEME</h3>
      </div>
      <div class="col d-flex justify-content-end"></div>
    </div>
    <div class="card-component mb-4">
      <div class="body">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label>{{ $t("announcement_title") }}</label>
              <input
                  type="text"
                  class="form-control"
                  v-model="form.announcement_title"
                  maxlength="100"
                  :class="{ 'is-invalid': $v.form.announcement_title.$error }"
              />
              <template v-if="$v.form.announcement_title.$error">
                <small class="text-danger" v-if="!$v.form.announcement_title.required">
                  Duyuru başlığı zorunlu bir alandır.<br
                /></small>
              </template>
              <template v-if="!$v.form.announcement_title.minLength">
                <small class="text-danger" v-if="!$v.form.announcement_title.minLength">
                  Duyuru başlığı en az 5 harf olmalıdır.</small
                >
              </template>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label>{{ $t("announcement_detail") }}</label>
              <vue-editor v-model="form.announcement_html"
                          :class="{ 'is-invalid': $v.form.announcement_html.$error }"
                          aria-placeholder="Aşağıdaki önizleme butonuna basarsan, burada yazılanların duyurun onaylandığında nasıl gözükeceklerini görebilirsin."
              ></vue-editor>
              <template v-if="$v.form.announcement_html.$error">
                <small class="text-danger" v-if="!$v.form.announcement_html.required">
                  Açıklama zorunlu bir alandır.<br
                /></small>
              </template>
              <template v-if="!$v.form.announcement_html.minLength">
                <small class="text-danger" v-if="!$v.form.announcement_html.minLength">
                  Duyuru başlığı en az 50 karakter olmalıdır.</small
                >
              </template>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-3">
            <input
                type="file"
                ref="sfile"
                class="d-none"
                @change="onChangeFileUpload($event, false)"
            />
            <button
                class="btn btn-secondary w-100"
                @click="$refs.sfile.click()"
            >
              {{ $t("choose_image") }}
            </button>
          </div>
          <div class="col-md-9">
            <div class="form-group">
              <input
                  type="text"
                  class="form-control"
                  disabled
                  v-model="form.announcement_head_pic"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Yayın Yerleri</label>
              <multiselect
                  v-model="form.publishPlaces"
                  :options="this.$store.state.dtAeo.publishPlaces"
                  :multiple="true"
                  @input="updatePublishPlaceInput"
                  selectLabel="Sadece bu itemi seçmek için tıkla"
                  selectedLabel="Seçili"
                  deselectLabel="Bu itemi silmek için tıkla"
                  @open="openPublishPlaces()"
                  :group-select="false"
                  placeholder="Sayfa Ara"
                  track-by="text"
                  label="text"
              >
                <span slot="noResult"
                >Aradığın kriterlere uygun kayıt bulunamadı.</span
                >
                <span slot="noOptions">Hiç kayıt yok ki.</span>
              </multiselect>
              <template v-if="$v.form.publishPlaces.$error">
                <small class="text-danger" v-if="!$v.form.publishPlaces.required">
                  Yayın yerini seçmek zorunlu bir alandır.<br
                /></small>
              </template>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Konu Grupları</label>
              <multiselect
                  v-model="form.subjectTypes"
                  :options="this.$store.state.dtAeo.subjectTypes"
                  :multiple="true"
                  @input="updateCheckedSubjectTypes"
                  selectLabel="Sadece bu itemi seçmek için tıkla"
                  selectGroupLabel="Bu grubun tamamını seçmek için tıkla"
                  selectedLabel="Seçili"
                  @open="openSubjectType()"
                  deselectLabel="Bu itemi silmek için tıkla"
                  deselectGroupLabel="Bu grubun tamamını silmek için tıkla"
                  placeholder="Konu Ara"
                  track-by="text"
                  label="text"
              ><span slot="noResult"
              >Aradığın kriterlere uygun kayıt bulunamadı.</span
              >
              </multiselect>
              <template v-if="$v.form.subjectTypes.$error">
                <small class="text-danger" v-if="!$v.form.subjectTypes.required">
                  Konu grubunu seçmek zorunlu bir alandır.<br
                /></small>
              </template>
            </div>
          </div>
        </div>
        <div class="row mb-3 mt-3">
          <div class="col-md-6">
            <div class="form-group">
              <label>Başlangıç ve Bitiş Tarihi</label>
              <date-picker
                  :popup-style="{ top: '100%', left: 0 }"
                  :append-to-body="false"
                  v-model="form.announcement_start_date"
                  format="DD.MM.YYYY"
                  range
                  type="date"
                  valueType="format"
                  :open.sync="open"
                  @change="handleChange"
              ></date-picker>
              <template v-if="$v.form.announcement_start_date.$error">
                <small class="text-danger" v-if="!$v.form.announcement_start_date.required">
                  Tarih aralığı seçmek zorunlu bir alandır.<br
                /></small>
              </template>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Yayında kalacak toplam gün sayısı</label>
              <input
                  type="text"
                  disabled
                  class="form-control"
                  v-model="day_difference"
                  maxlength="100"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>{{ $t("announcement_type") }}</label>

              <select
                  v-select
                  class="form-control"
                  v-model="form.announcement_type"
              >
                <option :value="null">{{ $t("choose") }}</option>
                <option
                    :value="item.id"
                    v-for="(item, index) in $store.state.dtAeo.pageTypes"
                    :key="index"
                >
                  {{ item.text }}
                </option>
              </select>
              <template v-if="$v.form.announcement_type.$error">
                <small class="text-danger" v-if="!$v.form.announcement_type.required">
                  Duyuru Tipini seçmek zorunlu bir alandır.<br
                /></small>
              </template>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Onaylayacak Kişi</label>

              <select
                  v-select
                  class="form-control"
                  v-model="form.announcement_charged_person"
              >
                <option :value="null">{{ $t("choose") }}</option>
                <option
                    :value="item.id"
                    v-for="(item, index) in announcementAdminUsers"
                    :key="index"
                >
                  {{ item.name }} {{ item.surName }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div
              class="flex w-full h-screen items-center justify-center text-center"
          >
            <div
                class="p-12 bg-light-100 border border-light-300"
                @dragover="dragover"
                @dragleave="dragleave"
                @drop="drop"
            >
              <input
                  type="file"
                  multiple
                  name="fields[assetsFieldHandle][]"
                  id="assetsFieldHandle"
                  class="w-px h-px opacity-0 overflow-hidden absolute"
                  @change="onChangeFileUpload($event, true)"
                  ref="mfile"
                  style="display: none"
                  accept=".jpg,.jpeg,.png"
              />

              <label for="assetsFieldHandle" class="block cursor-pointer">
                <div class="p-5">
                  Galeri fotoğraflarını buraya sürekleyip bırakabilirsin ya da
                  <span class="underline fw-bold">buraya</span>
                  tıklayarak bilgisayarından seçebilirsin.
                </div>
              </label>

              <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                  <tr>
                    <th>Fotoğraf</th>
                    <th class="text-right">Sıralama Değiştir</th>
                    <th class="fit">{{ $t("common.delete") }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                      class="cursor-pointer"
                      v-for="(item, index) in this.images"
                      :key="index"
                  >
                    <td>
                      <img
                          width="100px"
                          height="100px"
                          :src="item.imagePath"
                          alt="a"
                      />
                    </td>
                    <td class="text-right">
                      <button
                          v-if="index !== 0"
                          type="button"
                          class="btn btn-info"
                          @click="Up(item)"
                      >
                        Yukarı
                      </button>
                      <button
                          v-if="index !== images.length - 1"
                          type="button"
                          @click="Down(item)"
                          class="btn btn-info"
                      >
                        Aşağı
                      </button>
                    </td>
                    <td class="fit">
                      <button type="button" @click="remove(index)" class="btn btn-info">Sil</button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <button class="btn btn-danger w-100" @click="saveAnnouncement">
              Kaydet
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {VueEditor} from "vue2-editor";
import {mapActions} from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/tr";
import moment from "moment";
import {required, minLength} from "vuelidate/lib/validators";

export default {
  name: "AnnouncementView",
  components: {
    VueEditor,
    DatePicker,
    moment,
  },
  delimiters: ["${", "}"], // Avoid Twig conflicts
  data() {
    return {
      isEditing: false,
      fileList: [], // Store our uploaded files

      images: [],
      index: null,
      open: false,
      day_difference: null,
      announcementAdminUsers: [],
      announcement_head_pic_file_local: null,
      form: {
        announcement_title: null,
        announcement_html: null,
        announcement_head_pic: null,
        announcement_head_pic_file: null,
        announcement_start_date: [],
        announcement_type: null,
        subjectTypes: [],
        publishPlaces: [],
        announcement_charged_person: null,
      },
      selectedAnnouncement: null,
    };
  },
  validations: {
    form: {
      announcement_title: {
        required,
        minLength: minLength(5),
      },
      announcement_html: {
        required,
        minLength: minLength(50)
      },
      publishPlaces: {
        required
      },
      subjectTypes: {
        required
      },
      announcement_start_date: {
        required
      },
      announcement_type: {
        required
      }

    },
  },
  async created() {
    this.$store.state.general.showMenu = true
    if (Object.keys(this.$route.params).length !== 0) {
      this.pageId = this.$route.params.id;
      this.isEditing = true;
    }
    await this.openPublishPlaces().catch(error => {
      this.$store.state.general.showOverlay = true;
      this.showModal({
        title: "Hata!",
        message: error,
        type: "danger",
      });
    });
    await this.openSubjectType().catch(error => {
      this.$store.state.general.showOverlay = true;
      this.showModal({
        title: "Hata!",
        message: error,
        type: "danger",
      });
    });
    if (this.isEditing) {
      await this.getPageByIdAsync(this.$route.params.id).catch(error => {
        this.$store.state.general.showOverlay = true;
        this.showModal({
          title: "Hata!",
          message: error,
          type: "danger",
        });
      });
    }
    await this.openPageTypes().catch(error => {
      this.$store.state.general.showOverlay = true;
      this.showModal({
        title: "Hata!",
        message: error,
        type: "danger",
      });
    });

    this.getUsersInRole("DUYURU ADMIN").then((res) => {
      if (res.hasError) {
        this.showModal({
          title: "Hata!",
          message: res.errorMessage,
          type: "danger",
        });
      } else {
        this.announcementAdminUsers = res.data;
      }
    })
  },
  /*
  	In a shocking turn of events, the long-standing border conflict between Veridia and Eldoria has reached a critical juncture today, as both nations exchanged heavy artillery fire along their disputed border. The conflict, which has been simmering for years, escalated dramatically overnight, with reports of casualties on both sides.

	Military officials from Veridia have accused Eldoria of initiating the hostilities by crossing into Veridian territory, while Eldorian authorities claim that Veridian forces fired the first shots. The exact cause of the recent escalation remains uncertain, as both sides continue to trade blame and propaganda.

As tensions soar, neighboring countries and the international community have expressed deep concern over the deteriorating situation. Diplomatic efforts to mediate a ceasefire and initiate peace talks have intensified, with several nations offering their assistance in finding a peaceful resolution to the conflict.

The humanitarian impact of the renewed violence is already being felt, with reports emerging of thousands of civilians displaced from their homes. Aid organizations are struggling to provide assistance to those affected by the hostilities, and there are growing fears of a worsening humanitarian crisis if the conflict persists.

	The global community is closely monitoring the situation, urging both Veridia and Eldoria to exercise restraint and engage in dialogue to prevent further bloodshed. The United 						Nations Security Council has convened an emergency session to discuss the crisis and explore possible avenues for de-escalation.

As the border conflict intensifies, the world watches with bated breath, hoping for a swift and peaceful resolution to avoid further loss of life and suffering.








   */
  methods: {
    saveMainPageIconPath(payload) {
      this.$store.state.general.showOverlay = true;
      const formData = new FormData();
      formData.append("mainImage", payload);
      formData.append("id", this.$route.params.id);

      this.updateMainIconPath(formData).then((res) => {
        if (res.hasError) {
          this.$store.state.general.showOverlay = false;

          this.showModal({

            title: "Hata!",
            message: res.errorMessage,
            type: "danger",
          });
        } else {
          this.$store.state.general.showOverlay = false;
          this.showModal({
            title: "Başarılı!",
            message: "Fotoğraf kayıt edildi.",
            type: "success",
          });
        }
      })
    },
    saveAnnouncement() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store.state.general.showOverlay = true;
        if (this.isEditing) {

          let obj = {
            id: this.$route.params.id,
            header: this.form.announcement_title,
            content: this.form.announcement_html,
            startDate: this.form.announcement_start_date[0],
            endDate: this.form.announcement_start_date[1],
            pageTypeId: this.form.announcement_type,
            publishPlacesList: this.form.checkedPublishPlaces,
            subjectTypesList: this.form.checkedSubjectTypes,
            approvedBy: this.form.announcement_charged_person

          }
          //console.log(obj);
          this.updatePageDetail(obj).then((res) => {
            if (res.hasError) {
              this.showModal({
                title: "Hata!",
                message: res.errorMessage,
                type: "danger",
              });
            } else {
              this.getPageByIdAsync(this.$route.params.id).then((res) => {
                this.$store.state.general.showOverlay = false;
              });
              this.showModal({
                title: "Başarılı!",
                message: "Sayfa Başarı ile güncellendi",
                type: "success",
              });
            }
          }).catch(() => {
            this.showModal({
              title: "Hata!",
              message: "catch 500 status",
              type: "danger",
            });
          }).finally(() => {
            this.$store.state.general.showOverlay = false;

          })


        } else {
          const formData = new FormData();
          formData.append("header", this.form.announcement_title);
          formData.append("content", this.form.announcement_html);
          formData.append("startDate", this.form.announcement_start_date[0]);
          formData.append("endDate", this.form.announcement_start_date[1]);
          formData.append("pageTypeId", this.form.announcement_type);
          formData.append("publishPlacesList", this.form.checkedPublishPlaces);
          formData.append("subjectTypesList", this.form.checkedSubjectTypes);
          formData.append("mainImage", this.form.announcement_head_pic_file);
          formData.append("approvedBy", this.form.announcement_charged_person);

          this.images.forEach((it) => {
            formData.append("imageGallery", it.file);
          });

          this.addAnnouncement(formData)
              .then((res) => {
                this.$store.state.general.showOverlay = false;
                if (res.hasError) {
                  this.showModal({
                    title: "Hata!",
                    message: res.errorMessage,
                    type: "danger",
                  });
                } else {
                  this.showModal({
                    title: "Başarılı!",
                    message: "Sayfa başarıyla kayıt edildi.",
                    type: "success",
                  });
                  this.clearAll();
                }
              })
              .catch(() => {
                this.$store.state.general.showOverlay = false;
                this.showModal({
                  title: "Hata!",
                  message: "catch 500 status",
                  type: "danger",
                });
              });
        }
      }
    },

    customLabel({options_grouping}) {
      return `${options_grouping.lang}`;
    },

    async openPublishPlaces() {
      if (this.$store.state.dtAeo.publishPlaces.length === 0) {
        await this.getPublishPlace();
      }
    },
    clearAll() {
      this.form = {
        announcement_title: null,
        announcement_html: null,
        announcement_head_pic: null,
        announcement_head_pic_file: null,
        announcement_start_date: [],
        announcement_type: null,
        subjectTypes: [],
        publishPlaces: [],
        announcement_charged_person: null,
      };
      this.images = [];
      this.$v.$reset();
    },
    updatePublishPlaceInput() {
      let publishPlaces = [];

      this.form.publishPlaces.forEach((publish_place) => {
        publishPlaces.push(publish_place.id);
      });

      this.form.checkedPublishPlaces = publishPlaces;


    },
    updateCheckedSubjectTypes() {
      let subjectTypes = [];

      this.form.subjectTypes.forEach((subject_type) => {
        subjectTypes.push(subject_type.id);
      });

      this.form.checkedSubjectTypes = subjectTypes;
    },

    async openPageTypes() {
      if (this.$store.state.dtAeo.pageTypes.length === 0) {
        await this.getPageType();
      }
    },

    async openSubjectType() {
      if (this.$store.state.dtAeo.subjectTypes.length === 0) {
        await this.getSubjectType();
      }
    },

    Up(item) {
      if (this.isEditing)
        this.$store.state.general.showOverlay = true;

      let test = Object.assign([], this.images);
      //console.log(test);
      let item0;
      if (this.isEditing) {
        item0 = test.findIndex((s) => s.id === item.id);

      } else {
        item0 = test.findIndex((s) => s.imagePath === item.imagePath);

      }
      //console.log(test);
      if (item0 != null) {
        let temp = test[item0 - 1]; //1 üsttekinin item'ını aldık.
        //console.log(temp);
        let prevOrder = item.order;
        item.order = temp.order;
        test[item0 - 1] = item; //1 üste 1 aşağısındakini koyduk
        test[item0] = temp;
        test[item0].order = prevOrder;
        this.images = Object.assign([], test);
        if (this.isEditing)
          this.updateImageGalleryItem(this.images).then((res) => {
            if (!res.hasError) {
              this.getPageByIdAsync(this.$route.params.id).then((res) => {
                this.$store.state.general.showOverlay = false;
              });

            } else {
              this.$store.state.general.showOverlay = false;

              this.showModal({
                title: "Hata!",
                message: res.errorMessage,
                type: "danger",
              });
            }
          });
      }
    },
    Down(item) {
      if (this.isEditing)
        this.$store.state.general.showOverlay = true;

      let test = Object.assign([], this.images);
      let item0;
      if (this.isEditing) {
        item0 = test.findIndex((s) => s.id === item.id);

      } else {
        item0 = test.findIndex((s) => s.imagePath === item.imagePath);

      }
      if (item0 != null) {
        let temp = test[item0 + 1]; //1 üsttekinin item'ını aldık.
        let prevOrder = item.order;
        item.order = temp.order;
        test[item0 + 1] = item; //1 üste 1 aşağısındakini koyduk
        test[item0] = temp;
        test[item0].order = prevOrder;
        this.images = Object.assign([], test);


        if (this.isEditing)
          this.updateImageGalleryItem(this.images).then((res) => {
            if (!res.hasError) {
              this.getPageByIdAsync(this.$route.params.id).then((res) => {
                this.$store.state.general.showOverlay = false;
              });

            } else {
              this.$store.state.general.showOverlay = false;

              this.showModal({
                title: "Hata!",
                message: res.errorMessage,
                type: "danger",
              });
            }
          });


      }
    },
    ...mapActions("general", ["showModal", "getUsersInRole"]),
    ...mapActions("dtAeo", [
      "getPublishPlace",
      "getSubjectTypeGrouped",
      "getPageType",
      "addAnnouncement",
      "getPageByIdAsync",
      "getSubjectType",
      "updateImageGallery",
      "updateImageGalleryItem",
      "updateMainIconPath",
      "updatePageDetail",
      "deleteImageFromImageGallery"
    ]),

    onChangeFileUpload(e, isMultiple) {
      if (this.isEditing)
        this.saveAnnouncement();
      //console.log(isMultiple)
      if (isMultiple) {
        if (this.isEditing) {
          let pushToApi = [];
          const tmpFiles = e.target.files;
          const formData = new FormData();
          let index = 0;
          //console.log(tmpFiles)
          for (let f of tmpFiles) {
            this.$store.state.general.showOverlay = true;
            // console.log(f);
            if (f.name.match(/.(jpg|jpeg|png)$/i)) {
              let tempOrder;
              if (this.selectedAnnouncement.imageGallery.length === 0) {
                tempOrder = 0;
              } else
                tempOrder = this.selectedAnnouncement.imageGallery[this.selectedAnnouncement.imageGallery.length - 1].order;
              let obj = {
                pageId: this.$route.params.id,
                image: f,
                order: ++tempOrder,
                imagePath: URL.createObjectURL(f),
              };
              this.images.push(obj);
              if (this.isEditing) {
                formData.append(`imageGalleryDto[${index}].pageId`, obj.pageId);
                formData.append(`imageGalleryDto[${index}].order`, obj.order);
                formData.append(`imageGalleryDto[${index}].image`, obj.image);
                index++;
              }
            }
          }
          this.updateImageGallery(formData).then((res) => {
            this.$store.state.general.showOverlay = false;
            if (res.hasError) {
              this.showModal({
                title: "Hata!",
                message: res.errorMessage,
                type: "danger",
              });
            } else {
              this.showModal({
                title: "Başarılı!",
                message: "Success işleri",
                type: "success",
              });
              this.getPageByIdAsync(this.$route.params.id);

            }
          })
              .catch(() => {
                this.$store.state.general.showOverlay = false;
                this.showModal({
                  title: "Hata!",
                  message: "catch 500 status code",
                  type: "danger",
                });
              });


        } else {
          const tmpFiles = e.target.files;
          //console.log(tmpFiles)
          for (let f of tmpFiles) {
            // console.log(f);
            if (f.name.match(/.(jpg|jpeg|png)$/i)) {
              let obj = {
                id: null,
                file: f,
                fileName: f.name,
                imagePath: URL.createObjectURL(f),
              };
              this.images.push(obj);
            }
          }
        }

      } else {

        if (!this.$refs.sfile.files[0].name.match(/.(jpg|jpeg|png)$/i)) {
          this.showModal({
            title: "Hata!",
            message:
                "Sadece *.jpeg |  *.jpg | *.png uzantılı dosyalar yükleyebilirsin.",
            type: "danger",
          });
          this.announcement_head_pic_file_local = null;
        } else {
          this.form.announcement_head_pic_file = this.$refs.sfile.files[0];
          this.form.announcement_head_pic = this.$refs.sfile.files[0].name;
          if (this.isEditing)
            this.saveMainPageIconPath(this.form.announcement_head_pic_file);
        }
      }
    },
    handleChange(value) {
      if (value[0] == null) {
        this.day_difference = null;
        this.form.announcement_start_date = []
        return;
      }
      let finish_day = moment(String(value[1]), "DD.MM.YYYY");
      let start_day = moment(String(value[0]), "DD.MM.YYYY");
      this.day_difference = Math.ceil(
          (finish_day - start_day) / (1000 * 60 * 60 * 24)
      );
    },

    onChange(tmpFiles) {
      for (let f of tmpFiles) {
        // console.log(f);
        if (f.name.match(/.(jpg|jpeg|png)$/i)) {
          let obj = {
            id: null,

            file: f,
            fileName: f.name,
            imagePath: URL.createObjectURL(f),
          };
          this.images.push(obj);
        }
      }
    },
    remove(i) {
      if (this.isEditing)
        this.$store.state.general.showOverlay = true;
      let k;
      for (k = i + 1; k < this.images.length; k++) {
        //console.log(test);
        let test = Object.assign([], this.images);
        let item0;
        item0 = test.findIndex((s) => s.id === this.images[k].id);
        if (item0 != null) {
          let temp = test[item0 - 1]; //1 üsttekinin item'ını aldık.
          //console.log(temp);
          let prevOrder = this.images[k].order;
          this.images[k].order = temp.order;
          test[item0 - 1] = this.images[k]; //1 üste 1 aşağısındakini koyduk
          test[item0] = temp;
          test[item0].order = prevOrder;
          this.images = Object.assign([], test);
        }
      }


      if (this.isEditing)
        this.deleteImageFromImageGallery(this.images[this.images.length - 1]).then((res) => {
          if (res.hasError) {
            this.showModal({
              title: "Hata!",
              message: res.errorMessage,
              type: "danger",
            });
          } else {
            this.images.splice(this.images.length - 1, 1);
            this.updateImageGalleryItem(this.images).then((res) => {
              this.$store.state.general.showOverlay = false;
              if (!res.hasError) {
                this.getPageByIdAsync(this.$route.params.id).then((res) => {
                  this.$store.state.general.showOverlay = false;
                });
              }
            });
            this.showModal({
              title: "Başarılı!",
              message: "Success",
              type: "success",
            });
          }
        }).catch(() => {
          this.showModal({
            title: "Hata!",
            message: "catch 500 status code",
            type: "danger",
          });
        }).finally(() => {
          this.$store.state.general.showOverlay = false;
        });
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.mfile.files = event.dataTransfer.files;
      this.onChange(this.$refs.mfile.files); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    format_date(value) {
      return moment(value).format("DD.MM.YYYY");
    },
    map() {
      console.log(this.selectedAnnouncement);
      this.form.subjectTypes = [];
      this.form.publishPlaces = [];
      this.form.announcement_start_date = [];

      this.form.announcement_title = this.selectedAnnouncement.header
      this.form.announcement_html = this.selectedAnnouncement.content
      this.form.announcement_head_pic = this.selectedAnnouncement.pageIconPath
      this.form.announcement_charged_person = this.selectedAnnouncement.approvedBy
      this.form.announcement_start_date.push(this.format_date(this.selectedAnnouncement.startDate));
      this.form.announcement_start_date.push(this.format_date(this.selectedAnnouncement.endDate));
      this.handleChange(this.form.announcement_start_date)
      this.form.announcement_type = this.selectedAnnouncement.pageTypeId;
      this.images = this.selectedAnnouncement.imageGallery;

      this.selectedAnnouncement.subjectTypeIds.forEach((value) => {
        this.form.subjectTypes.push(this.$store.state.dtAeo.subjectTypes.find(s => s.id === value))
        this.updateCheckedSubjectTypes();
      });

      this.selectedAnnouncement.publishPlaceIds.forEach((value) => {
        this.form.publishPlaces.push(this.$store.state.dtAeo.publishPlaces.find(s => s.id === value))
        this.updatePublishPlaceInput();
      });

      // console.log(this.images)
    }
  }
  ,
  watch: {
    "$store.state.dtAeo.selectedAnnouncement":
        function () {
          this.selectedAnnouncement = this.$store.state.dtAeo.selectedAnnouncement.data;
          this.map();
          this.$store.state.general.showOverlay = false;
        }
  }
}
</script>

<style scoped>
[v-cloak] {
  display: none;
}
</style>
