import { render, staticRenderFns } from "./BorcTakip.vue?vue&type=template&id=aff97894&scoped=true&"
import script from "./BorcTakip.vue?vue&type=script&lang=js&"
export * from "./BorcTakip.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aff97894",
  null
  
)

export default component.exports