<template>
  <div id="login-container">
    <div class="container">
      <div class="row">
        <div class="col-lg-5 mx-auto">
          <img src="@/assets/img/logo200.png" alt="Ankara Eczacı Odası" class="mx-auto d-block" style="z-index: 10; position: relative;">
          <b-overlay :show="$store.state.auth.showOverlay" rounded="sm" class="d-flex flex-fill" style="margin-top: -115px;">
            <div class="card card-signin my-3 d-flex flex-fill" style="padding-top: 100px; border-radius:3rem; background: #f4eded;">
              <div class="card-body d-flex flex-fill">
               <LoginComponent></LoginComponent>
              </div>
            </div>
          </b-overlay>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginComponent from "@/components/login/LoginComponent.vue";
export default {
  name: "Login.vue",
  components:{
    LoginComponent
  }
}
</script>

<style scoped>

</style>