<template>
  <div class="standart-page">
    <div class="row">
      <div class="col-md-12"><h3 class="title">Üye Detay - Güncelle</h3></div>
    </div>
    <div class="card-component mb-4">
      <div class="body">
        <div class="row flex-column align-center justify-content-center">
          <div class="col-md-2 justify-content-center mx-auto">
            <div class="image-contain">
              <img
                class="mb-3 image-detail"
                style="height: 200px"
                src="../../../src/assets/img/furkan foto.jpg"
                alt=""
              />
              <div class="overlay">
                <div class="text">Resmi Düzenle</div>
              </div>
            </div>
          </div>
          <div
            class="col-md-2 justify-content-center mx-auto text-center mt-3 mb-3"
          >
            <h3 v-if="personDetail !== null" class="border-bottom">
              {{ personDetail.name }}
            </h3>
          </div>
        </div>

        <!-- Kişisel Bilgiler -->
        <b-modal
          id="modal-personDetail-edit"
          size="lg"
          centered
          title="Kişisel Bilgileri Düzenle"
          ok-title="Kaydet"
          ok-variant="success"
          cancel-title="İptal"
          cancel-variant="outline-secondary"
          @ok.prevent="saveFormPersonDetail"
          @hidden="clearFormPersonDetail"
        >
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label>Adı</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="formPersonDetail.name"
                />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label>Soyadı</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="formPersonDetail.surName"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label>Diğer İsim</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="formPersonDetail.otherName"
                />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label>TC Kimlik Numarası</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="formPersonDetail.personelIdNumber"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label>Doğum Tarihi</label>
                <date-picker
                  v-model="formPersonDetail.birthDate"
                  input-class="form-control"
                  format="DD.MM.YYYY"
                  valueType="format"
                  :popup-style="{ top: '100%', left: 0 }"
                  :append-to-body="false"
                ></date-picker>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label>Cinsiyet</label>
                <select
                  v-select
                  class="form-control"
                  v-model="formPersonDetail.genderId"
                >
                  <option :value="null">Seçiniz</option>
                  <option
                    :value="item.id"
                    v-for="(item, index) in this.$store.state.dtAeo.gender"
                    :key="index"
                  >
                    {{ item.text }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </b-modal>
        <div class="row mb-5">
          <div class="col-lg mb-5">
            <div class="card">
              <div class="card-header d-flex justify-content-between">
                <h4 class="card-title">Kişisel Bilgiler</h4>
                <div>
                  <button
                    class="btn btn-outline-secondary"
                    @click="openFormPersonDetailModal"
                  >
                    <i class="fa-regular fa-pen-to-square"></i>
                  </button>
                </div>
              </div>
              <div v-if="personDetail !== null" class="card-body">
                <div class="scroll">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                      <span class="fw-bold">Ad : </span
                      ><a href="">{{ personDetail.name }}</a>
                    </li>
                    <li class="list-group-item">
                      <span class="fw-bold">Soyad : </span
                      ><a href="">{{ personDetail.surName }}</a>
                    </li>
                    <li
                      v-if="personDetail.otherName !== null"
                      class="list-group-item"
                    >
                      <span class="fw-bold">Diğer İsim : </span
                      ><a href="">{{ personDetail.otherName }}</a>
                    </li>
                    <li class="list-group-item">
                      <span class="fw-bold">TC Kimlik No : </span
                      ><a href="">{{ personDetail.personelIdNumber }}</a>
                    </li>

                    <li class="list-group-item">
                      <span class="fw-bold">Doğum Tarihi : </span
                      ><a href="">{{ format_date(personDetail.birthDate) }}</a>
                    </li>
                    <li
                      v-if="this.getterGender().length !== 0"
                      class="list-group-item"
                    >
                      <span class="fw-bold">Cinsiyet : </span
                      ><a href="">{{
                        $store.state.dtAeo.gender.find(
                          (d) => d.id === personDetail.genderId
                        ).text
                      }}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div v-else>
                <b-overlay
                  show
                  rounded="sm"
                  class="d-flex flex-fill w-100"
                ></b-overlay>
              </div>
            </div>
          </div>

          <!--Üyelik Bilgileri-->
          <div class="col-lg mb-5">
            <div class="card">
              <div class="card-header d-flex justify-content-between">
                <h4 class="card-title">Üyelik Bilgileri</h4>
              </div>
              <div v-if="memberDetail !== null" class="card-body">
                <div class="scroll">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                      <span class="fw-bold">Üye No : </span
                      ><a href="">{{ memberDetail.membershipNumber }}</a>
                    </li>
                    <li class="list-group-item">
                      <span class="fw-bold">Başlangıç Tarihi : </span
                      ><a href="">{{
                        format_date(memberDetail.membershipStartDate)
                      }}</a>
                    </li>
                    <li
                      v-if="memberDetail.membershipEndDate !== null"
                      class="list-group-item"
                    >
                      <span class="fw-bold">Bitiş Tarihi : </span
                      ><a href="">
                        {{ format_date(memberDetail.membershipEndDate) }}
                      </a>
                    </li>
                    <li
                      v-if="memberDetail.membershipEndReason !== null"
                      class="list-group-item"
                    >
                      <span class="fw-bold">Bitiş Sebebi : </span
                      ><a href="">{{ memberDetail.membershipEndReason }}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div v-else>
                <b-overlay
                  show
                  rounded="sm"
                  class="d-flex flex-fill w-100"
                ></b-overlay>
              </div>
            </div>
          </div>
        </div>

        <!-- Üniversite Bilgisi -->
        <div class="col-lg mb-5">
          <div class="card">
            <div class="card-header d-flex justify-content-between">
              <h4 class="card-title">Üniversite Bilgileri</h4>
            </div>
            <MemberDetail_University />
          </div>
        </div>

        <!-- Dil Bilgisi -->
        <div class="col-lg mb-5">
          <div class="card">
            <div class="card-header d-flex justify-content-between">
              <h4 class="card-title">Yabancı Dil</h4>

            </div>
            <MemberDetail_Language />
          </div>
        </div>

        <!-- Adres Bilgisi -->
        <div class="col-lg mb-5">
          <div class="card">
            <div class="card-header d-flex justify-content-between">
              <h4 class="card-title">Adres Bilgileri</h4>

            </div>
            <MemberDetail_Address />
          </div>
        </div>

        <div class="col-lg mb-5">
          <div class="card"><div class="card-header d-flex justify-content-between">
            <h4 class="card-title">Notlar</h4>

          </div>
            <MemberDetail_Note />
          </div>
        </div>
        <div v-if="this.memberDetail !== null" class="col-lg mb-5">
          <div class="card">
            <div class="card-header d-flex justify-content-between">
              <h4 class="card-title">İş Yeri Bilgileri</h4>

            </div>
            <MemberDetail_Workplace :memberId="memberDetail.id" />
          </div>
        </div>

        <div v-if="this.memberDetail !== null" class="col-lg mb-5">
          <div class="card">
            <div class="card-header d-flex justify-content-between">
              <h4 class="card-title">Dosya Bilgileri</h4>

            </div>
            <MemberDetail_File :memberId="memberDetail.id" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { VueEditor } from "vue2-editor";
import { mapActions, mapGetters } from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/tr";
import moment from "moment";
import MemberDetail_University from "@/views/membership/MemberDetail_University.vue";
import MemberDetail_Language from "@/views/membership/MemberDetail_Language.vue";
import MemberDetail_Address from "@/views/membership/MemberDetail_Address.vue";
import MemberDetail_Note from "@/views/membership/MemberDetail_Note.vue";
import MemberDetail_Workplace from "@/views/membership/MemberDetail_Workplace.vue";
import MemberDetail_File from "@/views/membership/MemberDetail_File.vue";

export default {
  props: ["memberId"],
  name: "TahTesTut",
  components: {
    VueEditor,
    DatePicker,
    moment,
    MemberDetail_University,
    MemberDetail_Language,
    MemberDetail_Note,
    MemberDetail_Address,
    MemberDetail_Workplace,
    MemberDetail_File,
  },
  delimiters: ["${", "}"], // Avoid Twig conflicts
  data() {
    return {
      personDetail: null,
      memberDetail: null,
      formPersonDetail: {
        personId: null,
        name: null,
        surName: null,
        otherName: null,
        birthDate: null,
        personelIdNumber: null,
        genderId: null,
      },
    };
  },
  created() {
    this.$store.state.general.showMenu = true;

    if (Object.keys(this.$route.params).length !== 0) {
      const personId = this.$route.params.personId;
      this.getPersonDetail(personId).then((res) => {
        if (res.hasError) {
          this.showModal({
            title: "Hata!",
            message: res.errorMessage,
            type: "danger",
          });
        } else {
          this.personDetail = res.data;
        }
      });

      if (this.getterGender().length === 0) {
        this.getGender();
      }

      this.getMemberDetail(personId).then((res) => {
        if (res.hasError) {
          this.showModal({
            title: "Hata!",
            message: res.errorMessage,
            type: "danger",
          });
        } else {
          this.memberDetail = res.data;
        }
      });
    }
  },
  methods: {
    ...mapActions("general", ["showModal"]),

    ...mapActions("associationAeo", [
      "getPersonDetail",
      "updatePerson",
      "getMemberDetail",
    ]),
    ...mapActions("dtAeo", ["getGender"]),
    ...mapGetters("dtAeo", ["getterGender"]),
    format_date(value) {
      return moment(value).format("DD.MM.YYYY");
    },
    clearFormPersonDetail() {
      const {
        name,
        surName,
        genderId,
        personelIdNumber,
        birthDate,
        otherName,
      } = this.formPersonDetail;
      const clearedProps = {
        name: null,
        surName: null,
        otherName: null,
        birthDate: null,
        personelIdNumber: null,
        genderId: null,
      };
      this.formPersonDetail = Object.assign(
        {},
        this.formPersonDetail,
        clearedProps
      );
    },
    saveFormPersonDetail() {
      console.log(this.formPersonDetail);
      const myDate = moment(this.formPersonDetail.birthDate, "DD.MM.YYYY HH:mm")
        .utcOffset("GMT+03:00")
        .format();
      this.formPersonDetail.birthDate = myDate;
      this.updatePerson(this.formPersonDetail).then((res) => {
        if (res.hasError) {
          this.showModal({
            title: "Hata!",
            message: res.errorMessage,
            type: "danger",
          });
        } else {
          this.showModal({
            title: "Başarılı!",
            message: "Kayıt Başarıyla Güncellendi",
            type: "success",
          });
          this.personDetail = res.data;
        }
        this.$bvModal.hide("modal-personDetail-edit");
      });
    },
    openFormPersonDetailModal() {
      this.formPersonDetail = Object.assign({}, this.personDetail);
      this.formPersonDetail.birthDate = this.format_date(
        this.formPersonDetail.birthDate
      );
      this.$bvModal.show("modal-personDetail-edit");
    },
  },
};
</script>

<style scoped>
/* Track */
.scroll {
  height: 300px;
  overflow: scroll;
}

.image-contain {
  position: relative;
}

.image-detail {
  display: block;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #acacac;
}

.image-contain:hover .overlay {
  opacity: 0.8;
}

.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  cursor: pointer;
}
</style>

