var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ul',{staticClass:"list"},[_c('div',[(_vm.showMenu)?_c('h6',{staticClass:"text-danger mt-4 text-center fw-bold"},[_vm._v(_vm._s(_vm.$store.state.general.menu[4].menuAdi))]):_vm._e()]),_vm._l((_vm.$store.state.general.subMenu),function(item,index){return _c('li',{key:index,on:{"click":function($event){_vm.$store.state.general.activeMenuId = item.id}}},[(item.menuUrl == '/dashboard')?_c('router-link',{attrs:{"to":item.menuUrl == '/dashboard'
            ? '/application-management'
            : item.menuUrl,"exact":!(item.menuUrl.split('/').length > 2)}},[_c('img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.right",value:(
            !_vm.$store.state.general.showMenu ? item.menuAdi : ''
          ),expression:"\n            !$store.state.general.showMenu ? item.menuAdi : ''\n          ",modifiers:{"right":true}}],attrs:{"src":_vm.getIcon(item)}}),_vm._v(" "+_vm._s(_vm.$store.state.general.showMenu ? item.menuAdi : "")+" ")]):_vm._e(),(item.menuUrl != '/dashboard')?_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":"#"},on:{"click":function($event){return _vm.goToRoot(item)}}},[_c('img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.right",value:(
            !_vm.$store.state.general.showMenu ? item.menuAdi : ''
          ),expression:"\n            !$store.state.general.showMenu ? item.menuAdi : ''\n          ",modifiers:{"right":true}}],attrs:{"src":_vm.getIcon(item)}}),_vm._v(" "+_vm._s(_vm.$store.state.general.showMenu ? item.menuAdi : "")+" ")]):_vm._e()],1)})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }