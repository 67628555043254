<template>
  <div class="standart-page">

    <b-card no-body style="background: unset">
      <div class="bottomtabs">
        <b-tabs
            card
            v-model="tabIndex"
            nav-class="custom col-12 mx-auto"
            align="left"
            justified
        >
          <b-tab title="Sayfalar" active>
            <PageComponent></PageComponent>
          </b-tab>
          <b-tab title="Sayfa Grupları">
            <PageGroups></PageGroups>
          </b-tab>
          <b-tab title="Kullanıcılar">
            <Users></Users>
          </b-tab>
          <b-tab title="Roller">
            <Roles></Roles>
          </b-tab>

        </b-tabs>
      </div>

    </b-card>
  </div>

</template>

<script>
import PageComponent from "@/components/auth/PageComponent";
import Users from "@/components/auth/Users";
import Roles from "@/components/auth/Roles";
import PageGroups from "@/components/auth/PageGroups";
import {VueEditor} from "vue2-editor"
import {mapActions} from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/tr";
import moment from "moment";

export default {
  components: {PageGroups, Roles, Users, PageComponent},
  data() {
    return {
      tabIndex: 0,
    }
  },
  created() {
    this.$store.state.general.showMenu = true
  },

  name: "AuthorizationView.vue",

}

</script>

<style>
.bottomtabs {
  background: #efefef;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #1e1e1e !important;
  background-color: #6c757d;
  border-color: #6c757d;
}

.tab-content {
  background: #f8f8f8;
}

.custom li {
  background-color: unset;
  margin-right: 0.5rem;
  max-width: 150px !important;
  margin-bottom: 10px !important;
}

.custom li a {
  border: solid 1px #88898b !important;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition-property: color, background-color, border-color, box-shadow;
  transition-duration: 0.15s, 0.15s, 0.15s, 0.15s;
  transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out;
  transition-delay: 0s, 0s, 0s, 0s;
  line-height: 40px;
  text-align: center;
}

.top li a {
  line-height: 20px;
}

.custom li a:hover {
  background-color: #6c757d;
  color: #fff !important;
}

#document {
  background-color: #f8f8f8;
  padding-top: 0;
  margin-top: -10px;
}

.card {
  border: 0;
}

.card-header {
  background: #f8f8f8;
  border: 0;
}
</style>