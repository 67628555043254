<template>
  <ul class="list">
  <div>
      <h6 class="text-danger mt-4 text-center fw-bold" v-if="showMenu">> {{$store.state.general.menu[6].menuAdi}}</h6>
    </div>
    <li v-for="(item,index) in $store.state.general.subMenu" :key="index"
        @click="$store.state.general.activeMenuId=item.id">
      <router-link :to="item.menuUrl=='/dashboard'?'/application-management':item.menuUrl" :exact="!(item.menuUrl.split('/').length > 2)"
                   v-if="item.menuUrl=='/dashboard'">
        <img
            :src="getIcon(item)"
            v-b-tooltip.right="!$store.state.general.showMenu?item.menuAdi:''"/>
        {{ $store.state.general.showMenu ? item.menuAdi : '' }}
      </router-link>

      <a href="#" @click="goToRoot(item)" v-if="item.menuUrl!='/dashboard'" style="text-decoration: none">
        <img
            :src="getIcon(item)"
            v-b-tooltip.right="!$store.state.general.showMenu?item.menuAdi:''"/>
        {{ $store.state.general.showMenu ? item.menuAdi : '' }}
      </a>
    </li>
  </ul>
</template>

<script>

import { mixin as clickaway } from "vue-clickaway";
import { mapState, mapActions } from "vuex";

export default {
  methods: {

    ...mapActions("general", ["toggleMenu"]),
    away() {
      this.$store.state.general.showMenu = false;
    },
    openMenu() {
      this.$store.state.general.showMenu = true;
    },

    goToRoot(item) {
      window.location.href = item.menuUrl;

      
    },
    getIcon(item) {
      try {
        return require(`@/assets/img/icons/settings.png`);
        return require(`@/assets/img/icons/${item.menuIcon}${
          item.id === this.$store.state.general.activeMenuId ? "-active" : ""
        }.png`);
      } catch (ex) {}
    },
  },
  computed: {
    ...mapState("general", ["showMenu"]),
  },
};
</script>

