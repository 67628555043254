<template>
<div class="standart-page">
    <div class="row">
      <div class="col"><h3 class="title">Eczane Ara</h3></div>
    </div>
    <div class="card-component mb-4">
      <div class="body">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Eczane Adı</label>
              <input
                  type="text"
                  class="form-control"
                  v-model="form.announcement_title"
                  maxlength="100"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Durumu</label>
              <multiselect
                  v-model="form.subjectGroups"
                  :options="this.$store.state.dtAeo.subjectTypeGrouped"
                  :multiple="true"
                  group-values="childSubjectTypes"
                  selectLabel="Sadece bu itemi seçmek için tıkla"
                  selectGroupLabel="Bu grubun tamamını seçmek için tıkla"
                  selectedLabel="Seçili"
                  @open="openSubjectType()"
                  deselectLabel="Bu itemi silmek için tıkla"
                  deselectGroupLabel="Bu grubun tamamını silmek için tıkla"
                  group-label="parentSubjectType" :group-select="true" placeholder="Nöbet Tutan | Tutmayan"
                  track-by="text"
                  label="text"><span
                  slot="noResult">Aradığın kriterlere uygun kayıt bulunamadı.</span>
              </multiselect>
            </div> 
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Eczacı Ad Soyad</label>
              <input
                  type="text"
                  class="form-control"
                  v-model="form.announcement_title"
                  maxlength="100"
              />
            </div>
          </div>
          <div class="col-md-6 mb-5 mt-3">
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault">
              <label class="form-check-label" for="flexSwitchCheckDefault">Yeri İşaretlenmemiş</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Bölge</label>
              <multiselect
                  v-model="form.subjectGroups"
                  :options="this.$store.state.dtAeo.subjectTypeGrouped"
                  :multiple="true"
                  group-values="childSubjectTypes"
                  selectLabel="Sadece bu itemi seçmek için tıkla"
                  selectGroupLabel="Bu grubun tamamını seçmek için tıkla"
                  selectedLabel="Seçili"
                  @open="openSubjectType()"
                  deselectLabel="Bu itemi silmek için tıkla"
                  deselectGroupLabel="Bu grubun tamamını silmek için tıkla"
                  group-label="parentSubjectType" :group-select="true" placeholder="Hepsini Getir"
                  track-by="text"
                  label="text"><span
                  slot="noResult">Aradığın kriterlere uygun kayıt bulunamadı.</span>
              </multiselect>
            </div> 
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>İlçe</label>
              <multiselect
                  v-model="form.subjectGroups"
                  :options="this.$store.state.dtAeo.subjectTypeGrouped"
                  :multiple="true"
                  group-values="childSubjectTypes"
                  selectLabel="Sadece bu itemi seçmek için tıkla"
                  selectGroupLabel="Bu grubun tamamını seçmek için tıkla"
                  selectedLabel="Seçili"
                  @open="openSubjectType()"
                  deselectLabel="Bu itemi silmek için tıkla"
                  deselectGroupLabel="Bu grubun tamamını silmek için tıkla"
                  group-label="parentSubjectType" :group-select="true" placeholder="Hepsini Getir"
                  track-by="text"
                  label="text"><span
                  slot="noResult">Aradığın kriterlere uygun kayıt bulunamadı.</span>
              </multiselect>
            </div> 
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 mb-3"><button class="btn btn-secondary w-100">Ara</button></div>
          <div class="col-md-6"><button class="btn btn-outline-secondary w-100">Excel'e Aktar</button></div>
        </div>
        
        
        
      </div>
    </div>
  </div>
</template>
<script>
import {VueEditor} from "vue2-editor"
import {mapActions} from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/tr";
import moment from "moment";

export default {
  name: "PhaSearch",
  components: {
    VueEditor,
    DatePicker,
    moment,
  },
  delimiters: ['${', '}'], // Avoid Twig conflicts
  data() {
    return {


      images: [],
      index: null,
      open: false,
      day_difference: null,


      announcement_head_pic_file_local: null,
      form: {
        announcement_title: null,
        announcement_html: "<h4>Aşağıdaki önizleme butonuna basarsan, burada yazılanların duyurun onaylandığında nasıl gözükeceklerini görebilirsin.</h4>",
        announcement_head_pic: null,
        announcement_head_pic_file: null,
        announcement_start_date: null,
        announcement_type: null,
        subjectGroups: [],
        publishPlaces: [],
        announcement_charged_person: null
      },

    };
  },
  created() {
    this.getPageType();
    this.getPublishPlace();
    this.$store.dispatch("general/changeMenu", "InfProMenu");

  },
  methods: {

    customLabel({options_grouping}) {
      return `${options_grouping.lang}`
    },

    openPublishPlaces() {
      if (this.$store.state.dtAeo.publishPlaces.length === 0) {
        this.getPublishPlace();
      }
    },

    openSubjectType() {
      if (this.$store.state.dtAeo.subjectTypeGrouped.length === 0) {
        this.getSubjectTypeGrouped();
      }
    },


    Up(item) {
      //TODO: Böyle bir workaround yok. :)
      let test = Object.assign([], this.images)
      let item0 = test.findIndex(s => s.fileName === item.fileName);
      if (item0 != null) {
        let temp = test[item0 - 1];//1 üsttekinin item'ını aldık.
        test[item0 - 1] = item; //1 üste 1 aşağısındakini koyduk
        test[item0] = temp;
        this.images = Object.assign([], test);
      }
    },
    Down(item) {
      //TODO: Böyle bir workaround yok. :)
      let test = Object.assign([], this.images)
      let item0 = test.findIndex(s => s.fileName === item.fileName);
      if (item0 != null) {
        let temp = test[item0 + 1];//1 üsttekinin item'ını aldık.
        test[item0 + 1] = item; //1 üste 1 aşağısındakini koyduk
        test[item0] = temp;
        this.images = Object.assign([], test);
      }
    },
    ...mapActions("general", ["showModal"]),
    ...mapActions("dtAeo", ["getPublishPlace", "getSubjectTypeGrouped", "getPageType"]),

    onChangeFileUpload(e, isMultiple) {
      // console.log(isMultiple)
      if (isMultiple) {
        const tmpFiles = e.target.files
        //console.log(tmpFiles)
        for (let f of tmpFiles) {
          // console.log(f);
          if (f.name.match(/.(jpg|jpeg|png)$/i)) {
            let obj = {
              file: f,
              fileName: f.name,
              path: URL.createObjectURL(f)
            }
            this.images.push(obj);
          }
        }
      } else {
        if (
            !this.$refs.sfile.files[0].name.match(
                /.(jpg|jpeg|png)$/i
            )
        ) {
          this.showModal({
            title: "Hata!",
            message: "Sadece *.jpeg |  *.jpg | *.png uzantılı dosyalar yükleyebilirsin.",
            type: "danger",
          });
          this.announcement_head_pic_file_local = null;
        } else {
          this.form.announcement_head_pic_file = this.$refs.sfile.files[0];
          this.form.announcement_head_pic = this.$refs.sfile.files[0].name;
        }
      }

    },
    handleChange(value) {
      if (value[0] == null) {
        this.day_difference = null
        return;
      }
      let finish_day = moment(String(value[1]), "DD.MM.YYYY")
      let start_day = moment(String(value[0]), "DD.MM.YYYY")
      this.day_difference = Math.ceil((finish_day - start_day) / (1000 * 60 * 60 * 24));
    },


    onChange(tmpFiles) {
      for (let f of tmpFiles) {
        // console.log(f);
        if (f.name.match(/.(jpg|jpeg|png)$/i)) {
          let obj = {
            file: f,
            fileName: f.name,
            path: URL.createObjectURL(f)
          }
          this.images.push(obj);
        }
      }
    },

    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('bg-green-300')) {
        event.currentTarget.classList.remove('bg-gray-100');
        event.currentTarget.classList.add('bg-green-300');
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    },
    drop(event) {
      event.preventDefault();
      this.$refs.mfile.files = event.dataTransfer.files;
      this.onChange(this.$refs.mfile.files); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    }
  }
}
</script>

<style scoped>
