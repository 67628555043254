<template>

<div>
  <div>
      <h6 class="text-danger mt-4 text-center fw-bold h-25"></h6>
    </div>
<!-- <div class="accordion" id="accordionExample">
  <div class="accordion-item" v-for="(item,index) in $store.state.general.menu" :key="index">
    <h2 class="" id="headingOne">
      <button class="btn text-danger fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
       {{item.menuAdi}}
      </button>   
    </h2>
    <div id="collapseOne" class="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div class="accordion-body"
      v-for="items in $store.state.general.menu" :key="items"
      >
        <ul class="list-group list-group-flush"
        v-for="item in items.altMenuler" :key="item"
         >
          <li class="list-group-item">{{item.menuAdi}}</li>
          
        </ul>     
      </div>
    </div>
  </div>
</div> -->

<ul class="list">
    <li v-for="(item,index) in $store.state.general.menu" :key="index"
        @click="$store.state.general.activeMenuId=item.id">
      <a class="cursor-pointer" @click="changeMenu(item.menuBilesen,item.menuUrl,item.menuTipId)"
         :class="$route.path===item.menuUrl?'router-link-exact-active':''">
        <img
            :src="getIcon(item)"
            v-b-tooltip.right="!$store.state.general.showMenu?item.menuAdi:''"/>
        {{ $store.state.general.showMenu ? item.menuAdi : '' }}
      </a>
      
    </li>
  </ul></div>
  
</template>

<script>
export default {
  methods: {
    getIcon(item) {
      try {
        return require(`@/assets/img/icons/settings.png`);
        return require(`@/assets/img/icons/${item.menuIcon}${item.id === this.$store.state.general.activeMenuId ? '-active' : ''}.png`);
      } catch (ex) {
      }
    },
    changeMenu(menu, url, menuTip) {
      if (this.$route.path !== ('/' + url)) {
        if (menuTip === 3) window.location.href = url; //menu tip 3 olduğu zaman sub module redirect oluyor
        else if (menu) {
          if (this.$route.path != '/') {
            this.$router.push('/');
          }
         // console.log(menu)
          this.$router.push(url);
        } else {
          this.$router.push(url);
        }
      }

    }

  }
}
</script>
