import AcademyAeoService from "@/services/academy.aeo.service";

const initialState = {
    students: [],
    filteredStudents: [],
    DTAcademyUser: {dtPagination: {}, item: []},

}
export const academyAeo = {
    namespaced: true,
    state: initialState,
    actions: {


        getAcademyUserWaitingApproval({commit}, payload) {
            return AcademyAeoService.getAcademyUserWaitingApproval(payload).then(res => {
                if (res.data) commit('setStudents', res.data);
                return Promise.resolve(res.data);
            });
        },
        getAcademyUserApproved({commit}, payload) {
            return AcademyAeoService.getAcademyUserApproved(payload).then(res => {
                if (res.data) commit('setStudents', res.data);
                return Promise.resolve(res.data);
            });
        },
        approveAcademyUser({commit}, payload) {
            return AcademyAeoService.approveAcademyUser(payload).then(res => {
                return Promise.resolve(res.data);
            });
        }


    },
    getters:{
        getStudentCount(state){
            return state.DTAcademyUser.item.length;
        }
    },
    mutations: {

        setStudents(state, payload) {
            state.DTAcademyUser.item = Object.assign([], payload.endpointResult.data);
            state.DTAcademyUser.dtPagination = Object.assign({}, payload.pagination);
        },

    },
};