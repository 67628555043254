<template>
  <div>
    <div class="standart-page">
      <b-card no-body style="background: unset">
        <b-tabs card
                v-model="tabIndex"
                nav-class="custom top col-12 mx-auto"
                align="center"
                justified>

          <b-tab :disabled="$store.state.membershipAeo.
          listOfStateTypes[0]" title="Özet Bilgiler">
            <!--<MemberPerson @update-tab-index="handleUpdateTabIndex"></MemberPerson>-->
            <MemberPerson @update-tab-index="handleUpdateTabIndex"></MemberPerson>
          </b-tab>
          <b-tab :disabled="$store.state.membershipAeo.
          listOfStateTypes[1]" title="Eğitim Bilgisi">
            <MemberPersonUniversity @update-tab-index="handleUpdateTabIndex"></MemberPersonUniversity>
          </b-tab>
          <b-tab :disabled="$store.state.membershipAeo.
          listOfStateTypes[2]" title="Dil Bilgisi">
            <MemberPersonLanguage @update-tab-index="handleUpdateTabIndex"></MemberPersonLanguage>
          </b-tab>
          <b-tab :disabled="$store.state.membershipAeo.
          listOfStateTypes[3]" title="Adres Bilgisi">
            <MemberPersonAddress @update-tab-index="handleUpdateTabIndex"></MemberPersonAddress>
          </b-tab>
          <b-tab :disabled="$store.state.membershipAeo.
          listOfStateTypes[4]" title="Not">
            <MemberPersonNote @update-tab-index="handleUpdateTabIndex"></MemberPersonNote>
          </b-tab>

          <b-tab v-show="$store.state.membershipAeo.listOfStateTypes.length > 5" :disabled="$store.state.membershipAeo.
          listOfStateTypes[5]" title="Üye İşyerleri">
            <MemberWorkplace @update-tab-index="handleUpdateTabIndex"></MemberWorkplace>
          </b-tab>
          <b-tab v-show="$store.state.membershipAeo.listOfStateTypes.length > 5" :disabled="$store.state.membershipAeo.
          listOfStateTypes[6]" title="Üye Dosyaları">
            <MemberFile @update-tab-index="handleUpdateTabIndex"></MemberFile>
          </b-tab>

        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import MemberPerson from "@/views/membership/MemberPerson.vue"
import Region from "@/views/association/dt/Region.vue"
import MemberPersonLanguage from "@/views/membership/MemberPersonLanguage.vue"
import MemberPersonUniversity from "@/views/membership/MemberPersonUniversity.vue"
import MemberPersonAddress from "@/views/membership/MemberPersonAddress.vue"
import MemberPersonNote from "@/views/membership/MemberPersonNote.vue"
import MemberWorkplace from "@/views/membership/MemberWorkplace.vue"
import MemberFile from "@/views/membership/MemberFile.vue"
import {mapActions} from "vuex";

export default {
  name: "MemberRegistration.vue",
  components: {
    MemberPerson,
    MemberPersonUniversity,
    MemberPersonLanguage,
    MemberPersonAddress,
    MemberPersonNote,
    MemberFile,
    MemberWorkplace,
    Region
  },
  data() {
    return {
      tabIndex: 0,
    }
  },
  methods: {

    ...mapActions("membershipAeo", ["getMemberRegistrationStates", "resetListOfStateTypes"]),
    ...mapActions("general", ["showModal",]),

    handleUpdateTabIndex(newIndex) {
      this.$store.commit("membershipAeo/SET_TAB_INDEX", newIndex);
      // console.log(this.$store.state.membershipAeo.tabIndex)
      // update the "tabIndex" value in the store with the new value passed as the payload of the event
    },
  },
  async created() {
    this.tabIndex = 0;
    this.$store.state.general.showOverlay = true;
    this.$store.state.general.showMenu = true;
    await this.getMemberRegistrationStates().then(res => {
      if (res.hasError) {
        this.showModal({
          title: "Uyarı - Dikkat",
          message: "Bir hata oluştu! Lütfen sayfayı yenileyiniz.",
          type: "danger",
        })
      }
      this.$store.state.general.showOverlay = false;

    });
  },

  watch: {
    "$store.state.membershipAeo.tabIndex": function () {
      this.tabIndex = this.$store.state.membershipAeo.tabIndex;
      this.resetListOfStateTypes(this.tabIndex);
    },


  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$store.commit('membershipAeo/SET_TAB_INDEX', 0)// Reset the tabIndex value to 0
    });
  },
}
</script>

<style>
.bottomtabs {
  background: #efefef;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #1c7430 !important;
  background-color: #6c757d;
  border-color: #6c757d;
}

.tab-content {
  background: #f8f8f8;
}

.custom li {
  background-color: unset;
  margin-right: 0.5rem;
  max-width: 150px !important;
  margin-bottom: 10px !important;
}

.custom li a {
  border: solid 1px #88898b !important;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition-property: color, background-color, border-color, box-shadow;
  transition-duration: 0.15s, 0.15s, 0.15s, 0.15s;
  transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out;
  transition-delay: 0s, 0s, 0s, 0s;
  line-height: 40px;
  text-align: center;
}

.top li a {
  line-height: 20px;
}

.custom li a:hover {
  background-color: #6c757d;
  color: #fff !important;
}


</style>
