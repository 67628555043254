<template>
  <div class="d-flex flex-column w-100">
    <!-- <navbar/>
    <main-menu/> -->
    <div class="content" :class="showMenu?'menu-open':'menu-close'">
      <slot/>
    </div>
  </div>
</template>
<script>
import mainMenu from "../components/MainMenu";
import navbar from "../layouts/Navbar";
import {mapState} from "vuex";

export default {
  components: {
    mainMenu,
    navbar
  },
  computed: {
    ...mapState('general', ['showMenu'])
  }
};
</script>