<template>
  <div class="standart-page">
    <b-modal
        id="modal-personNote-list-edit"
        size="lg"
        centered
        title="Kişi Dil Bilgisini Düzenle"
        ok-title="Kaydet"
        ok-variant="success"
        cancel-title="İptal"
        cancel-variant="outline-secondary"
        @ok.prevent="update"
        @hidden="clearForm">
      <div class="row">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Not</label>
              <input
                  type="text"
                  :class="{ 'is-invalid': $v.form.text.$error }"
                  class="form-control"
                  v-model="form.text"
                  maxlength="100"
              />
              <template v-if="!this.$v.form.text.minLength">
                <small class="text-danger" v-if="!$v.form.text.minLength">
                  Adres adı adı en az 5 karakter içermelidir.</small
                >
              </template>

              <template v-if="$v.form.text.$error">
                <small class="text-danger" v-if="!$v.form.text.required">
                  Bu alan boş bırakılamaz.
                </small>
              </template>
            </div>
          </div>

        </div>
      </div>
    </b-modal>

    <div class="card-component mb-4">
      <div class="body">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Not</label>
              <input
                  type="text"
                  :class="{ 'is-invalid': $v.form.text.$error }"
                  class="form-control"
                  v-model="form.text"
                  maxlength="100"
              />
              <template v-if="!this.$v.form.text.minLength">
                <small class="text-danger" v-if="!$v.form.text.minLength">
                  Adres adı adı en az 5 karakter içermelidir.</small
                >
              </template>

              <template v-if="$v.form.text.$error">
                <small class="text-danger" v-if="!$v.form.text.required">
                  Bu alan boş bırakılamaz.
                </small>
              </template>
            </div>
          </div>

        </div>

        <div class="row mt-3 justify-content-end">
          <div class="col-md-4">
            <div class="form-group w-100">
              <button
                  @click="save"
                  class="btn btn-danger w-100 align-content-center">
                Not Kaydet
              </button>
            </div>
          </div>
        </div>
        <table
            v-if="this.personNoteList.length > 0"
            class="table table-hover">
          <thead>
          <tr>
            <th class="fit">Eklenme Tarihi</th>
            <th class="fit">Not</th>
          </tr>
          </thead>
          <tbody>
          <tr
              @click="showEditModal(item)"
              v-for="(item, index) in this.personNoteList"
              :key="index"
              class="cursor-pointer"
          >
            <td>{{format_date(item.created_at)}}</td>
            <td>{{item.text}}</td>

          </tr>
          </tbody>
        </table>
        <div class="row mt-3 justify-content-end">
          <div class="col-md-4">
            <div class="form-group w-100">
              <button
                  @click="updatePersonRegistrationState"
                  class="btn btn-danger w-100 align-content-center">
                Bir sonraki adım'a geç
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {VueEditor} from "vue2-editor"
import {mapActions, mapGetters} from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/tr";
import moment from "moment";
import {minLength, required, requiredIf} from "vuelidate/lib/validators";

export default {
  name: "TahTesTut",
  components: {
    VueEditor,
    DatePicker,
    moment,
  },
  delimiters: ['${', '}'], // Avoid Twig conflicts
  tabIndex: 0,
  data() {
    return {
      personNoteList: [],

      form: {
        id: null,
        personId: null,
        memberRegistrationStateTypeId: 0,
        text: null,
      },

    };
  },
  created() {
  },

  validations: {
    form: {
      text: {
        required,
        minLength: minLength(5),
      }
    }
  },

  methods: {
    format_date(value) {
      return moment(value).format("DD.MM.YYYY");
    },
    ...mapActions("membershipAeo", ["updatePersonState", "getPersonNote",
      "updatePersonNote", "savePersonNote"]),
    ...mapActions("general", ["showModal"]),

    showEditModal(item) {
      const {
        id,
        text,
      } = item;
      Object.assign(this.form, {
        id: id,
        text: text,
      });
      this.$bvModal.show("modal-personNote-list-edit");
    },
    save: function () {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.form.memberRegistrationStateTypeId === -1) {
          this.showModal({
            title: "Hata!",
            message: "Bir sonraki aşamaya geçilemiyor!",
            type: "danger",
          });
          return;
        }
        this.$store.state.general.showOverlay = true;
        this.savePersonNote(this.form).then(res => {
          if (res.hasError) {
            this.showModal({
              title: "Hata!",
              message: res.errorMessage,
              type: "danger",
            });
          } else {
            this.showModal({
              title: "Başarıylı!",
              message: "Dil Bilgisi kayıt edildi.",
              type: "success",
            });
            this.personNoteList = res.data;
            if (this.form.personId == null) {
              this.form.personId = this.personNoteList[0].personId;
            }
            this.clearForm();
          }
        }).catch((reason) => {
          this.showModal({
            title: "Hata!",
            message: reason.toString(),
            type: "danger",
          });
        }).finally(() => {
          this.$store.state.general.showOverlay = false;

        });
      }
    },
    update: function () {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store.state.general.showOverlay = true;
        this.updatePersonNote(this.form).then(res => {
          if (res.hasError) {
            this.showModal({
              title: "Hata!",
              message: res.errorMessage,
              type: "danger",
            });
          } else {
            this.showModal({
              title: "Başarıylı!",
              message: "Not Bilgisi güncellendi.",
              type: "success",
            });
            this.personNoteList = res.data;
            if (this.form.personId == null) {
              this.form.personId = this.personNoteList[0].personId;
            }
            this.clearForm();
          }

        }).catch((reason) => {
          this.showModal({
            title: "Hata!",
            message: reason.toString(),
            type: "danger",
          });
        }).finally(() => {
          this.$store.state.general.showOverlay = false;
          //this.$nextTick(()=>{}); if necessary
          this.$bvModal.hide("modal-personNote-list-edit");
        })
      }
    },
    clearForm() {
      const {text} = this.form;
      const clearedProps = {
        text: null,
      };
      this.form = Object.assign({}, this.form, clearedProps);
      this.$v.$reset();
    },
    updatePersonRegistrationState() {
      let tabIndex = this.$store.state.membershipAeo.tabIndex;
      console.log(this.$store.state.membershipAeo.memberRegistrationStates);
      console.log(this.$store.state.membershipAeo.tabIndex)
      let index =
          this.$store.state.membershipAeo.memberRegistrationStates.findIndex(x => x.index === tabIndex + 1)
      this.form.memberRegistrationStateTypeId = this.$store.state.membershipAeo.memberRegistrationStates[index].id;

      this.updatePersonState(this.form).then(res => {
        if (!res.hasError)
          this.$emit("update-tab-index",
              this.$store.state.membershipAeo.memberRegistrationStates[index].index);
        else {
          this.showModal({
            title: "FAIL!",
            message: res.errorMessage,
            type: "danger",
          });
        }
      }).catch((reason) => {
        this.showModal({
          title: "Hata!",
          message: reason.toString(),
          type: "danger",
        });
      }).finally(() => {
        this.$store.state.general.showOverlay = false;
      });
    }

  },
  watch: {
    "$store.state.membershipAeo.selectedPerson":
        function () {
          if (this.$store.state.membershipAeo.selectedPerson.person != null) {
            this.form.personId = this.$store.state.membershipAeo.selectedPerson.person.id;
            this.getPersonNote(this.form.personId).then((res) => {
              if (res.hasError) {
                this.showModal({
                  title: "Hata!",
                  message: "Kişinin not bilgileri çekilemedi. Lütfen sayfayı yenileyin",
                  type: "danger",
                });
              } else {
                this.personNoteList = Object.assign([], res.data)
              }
            });
          }
        }
  },
}
</script>
