import { render, staticRenderFns } from "./Duyurularim.vue?vue&type=template&id=305d2742&scoped=true&"
import script from "./Duyurularim.vue?vue&type=script&lang=js&"
export * from "./Duyurularim.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "305d2742",
  null
  
)

export default component.exports