<template>
  <div class="d-flex flex-fill">
    <form class="d-flex flex-fill justify-content-center align-items-center flex-column form-signin"
          @submit.prevent="lgn">

      <div class="form-label-group mt-1">
        <input type="text" id="user" class="form-control" required autofocus placeholder="E-Posta:"
               autocomplete="off"
               v-model="$store.state.auth.loginForm.eMail"
               oninvalid="this.setCustomValidity('Lütfen e-posta adresinizi yazınız.')"
               oninput="this.setCustomValidity('')"
        >
        <label for="user">E-Posta:</label>

      </div>
      <div class="form-label-group mt-1">
        <input type="password" id="password" class="form-control" required autofocus placeholder="Parola:"
               autocomplete="off"
               v-model="$store.state.auth.loginForm.password"
               oninvalid="this.setCustomValidity('Lütfen parolanızı yazınız.')"
               oninput="this.setCustomValidity('')"
        >
        <label for="password">Parola:</label>

      </div>
      <button class="btn btn-lg btn-danger text-uppercase" type="submit" style="font-size: small; width: 100%;">GİRİŞ
      </button>
      <button class="btn btn-lg" type="submit">Kayıt Ol</button>
    </form>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import VueQrcode from 'vue-qrcode'

export default {
  name: "LoginComponent.vue",
  components: {
    VueQrcode
  },
  data() {
    return {}
  },
  methods: {
    ...mapActions('general', ['showModal']),
    ...mapActions('auth', ['login', 'getUserAuthorizedMenu']),
    lgn() {
      this.$store.state.auth.showOverlay = true;
      this.login({
        eMail: this.$store.state.auth.loginForm.eMail,
        password: this.$store.state.auth.loginForm.password
      }).then((res) => {
        if (res.hasError) {
          this.showModal({title: 'Hata!', message: res.errorMessage, type: 'danger'});

        } else
          this.getUserAuthorizedMenu().then(res => {
            this.$store.state.auth.showOverlay = false;

            if (res.hasError) {
              this.showModal({title: 'Hata!', message: res.errorMessage, type: 'danger'});
            } else {
              this.$router.push("/")

            }
          });
      }).catch(() => {
        this.showModal({title: 'Hata!', message: 'Sistem yöneticisine başvurunuz (557)', type: 'danger'});
      });
    }
  },
  computed: {}
}
</script>