import { render, staticRenderFns } from "./Gender.vue?vue&type=template&id=18086e0e&"
import script from "./Gender.vue?vue&type=script&lang=js&"
export * from "./Gender.vue?vue&type=script&lang=js&"
import style0 from "./Gender.vue?vue&type=style&index=0&id=18086e0e&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports