<template>
  <div class="standart-page">
    <div class="form-group">
      <label class="d-block">Aktif</label>
      <label class="label_switch">
        <input type="checkbox"/>
        <span class="mr-2"></span>
      </label>
    </div>
    <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
         aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title text-danger" id="staticBackdropLabel"> Uyarı!</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            Seçilen Duyuruları Silmek İstediğinizden Emin Misiniz?
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Evet</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col"></div>
      <div class="col"><h3 class="title">Duyuru Onay</h3></div>
      <div class="col d-flex justify-content-end"></div>
    </div>
    <div class="card-component mb-4">
      <div class="body">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <button class="btn btn-outline-secondary" @click="approvePagesWithPayload(false)">
                Seçilen Duyuruları Onayla (Mesaj Gönderme)
              </button>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <button class="btn btn-secondary" @click="approvePagesWithPayload(true)">
                Seçilen Duyuruları Onayla (Mesaj Gönder)
              </button>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <button class="btn btn-danger"
                      data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                >
                Seçilen Duyuruları Sil
              </button>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <button class="btn btn-warning"
                      data-bs-toggle="modal" @click="mtd_syncAnnouncementFromOldPortal()">
                Sync Duyurular
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <div
          class="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Okuyanlar</h5>
              <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <ul class="list-group">
                <li class="list-group-item">Furkan Erbağcı</li>
              </ul>
            </div>
            <div class="modal-footer">
              <button
                  type="button"
                  class="btn btn-danger"
                  data-bs-dismiss="modal"
              >
                Kapat
              </button>
            </div>
          </div>
        </div>
      </div>
      <table class="table table-hover">
        <thead>
        <tr>
          <th>
            <label class="form-check-label">
              <input class="form-check-input" type="checkbox" v-model="allSelected" @change="selectAll">
              <span>Hepsini Seç</span>
            </label>
          </th>
          <th>Yayın Tarihi</th>
          <th>Duyuru Başlığı</th>
          <th class="fit">Yayın Yeri</th>
          <th class="fit">Konu Grupları</th>
          <th class="fit text-right">İşlemler</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in $store.state.dtAeo.AnnouncementPaginatedSearchResult.item" :key="index">
          <td class="fit text"><input type="checkbox" v-model="selected" :value="item.id"
                                      class="form-check-input"/></td>
          <td>{{ format_date(item.startDate) }} - {{ format_date(item.endDate) }}</td>
          <td>{{item.header}}</td>
          <td class="fit text">{{item.publishPlaceText}}</td>
          <td class="fit text">{{item.subjectTypeText}}</td>
          <td class="fit text-right">

            <router-link tag="button" class="btn btn-secondary me-1"
                         :to="{ path: '/announcement/update/'+item.id}">
              Düzenle
            </router-link>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="alert alert-danger text-center"
         v-if="this.showError">
      {{ errorMessage}}
    </div>
    <div
        class="row"
        v-if="
        $store.state.dtAeo.AnnouncementPaginatedSearchResult.item.length > 0
      "
    >
      <div class="col">
        <i class="text-secondary"
        >{{
          $store.state.dtAeo.AnnouncementPaginatedSearchResult.dtPagination
          .totalItems
          }}
          kayıttan {{ (form.currentPage - 1) * form.pageSize + 1 }} -
          {{ form.currentPage * form.pageSize }} arası gösterilmektedir.</i
        >
      </div>
      <div class="col">
        <div class="row">
          <div class="col d-flex justify-content-end">
            <nav>
              <paginate
                  v-if="
                  $store.state.dtAeo.AnnouncementPaginatedSearchResult.item
                    .length
                "
                  :page-count="
                  $store.state.dtAeo.AnnouncementPaginatedSearchResult
                    .dtPagination.totalPages
                "
                  :click-handler="changePage"
                  :prev-text="'Önceki'"
                  :next-text="'Sonraki'"
                  :active-class="'active'"
                  :container-class="'pagination'"
                  v-model="form.currentPage"
              >
              </paginate>
            </nav>
          </div>
          <div class="col-2">
            <select
                v-select
                class="form-control"
                v-model="form.pageSize"
                @change="changeSize"
            >
              >
              <option value="25" selected>25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>
<script>
import {VueEditor} from "vue2-editor";
import {mapActions} from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/tr";
import moment from "moment";
import RouterView from "../RouterView.vue";

export default {

  name: "UyeTahOdReceivingAdmin",
  components: {
    VueEditor,
    DatePicker,
    moment,
    RouterView,
  },
  delimiters: ["${", "}"], // Avoid Twig conflicts
  data() {
    return {
      showError: false,
      form: {
        dateInterval: [],
        publishPlaceIds: null,
        keyword: null,
        pageTypes: null,
        searchForApproval: true,
        checkedPublishPlaces: [],
        checkedSubjectTypes: [],
        isApproved: 0,
        approvedBy: null,
        pageSize: 25,
        currentPage: 1,
      },
      selected: [],
      allSelected: false,
      selectedItems: {
        pageIds: [],
        willBeMessageSend: null,
      }
    };
  },
  created() {
    this.$store.state.general.showMenu = true;
    if (this.$store.state.general.userDetail.directionUrl == null) {
      this.whatIsMyDuty();
    } else {
      this.form.approvedBy = this.$store.state.general.userDetail.personId;
      this.listAllNotApproved();
    }
  },
  computed: {},
  methods: {
    mtd_syncAnnouncementFromOldPortal() {
      this.syncAnnouncementFromOldPortal().then(res => {
        if (res.hasError) {
          this.showModal({
            title: "Hata!",
            message: res.errorMessage,
            type: "danger",
          });
        } else {
          this.form.approvedBy = this.$store.state.general.userDetail.personId;
          this.listAllNotApproved();
        }
      })
    },
    selectAll() {
      if (this.allSelected) {
        this.selected = this.$store.state.dtAeo.AnnouncementPaginatedSearchResult.item.map(a => a.id);
      } else {
        this.selected = [];
      }
    },
    removeSelectedAnnouncements() {
      this.selected.forEach(id => {
        this.removeAnnouncementFromList(id);
      });
      this.selected = [];
      this.allSelected = false;
    },
    showStatistics(id) {
      // your logic to show statistics for an announcement
    },
    removeAnnouncementFromList(id) {
      // your logic to remove an announcement from the list
    },

    approvePagesWithPayload(payload) {
      this.selectedItems.pageIds = this.selected;
      // console.log(this.selectedItems)
      this.selectedItems.willBeMessageSend = payload;
      this.approvePages(this.selectedItems).then((res) => {
        if (res.hasError) {
          this.showModal({
            title: "Hata!",
            message: res.errorMessage,
            type: "danger",
          });
        } else {
          this.showModal({
            title: "Başarılı!",
            message: "Onaylandı.",
            type: "success",
          });
          this.$forceUpdate();
        }
      })
      // your logic to remove an announcement from the list
    },
    ...mapActions("general", ["showModal"]),


    changeSize() {
      this.$store.state.general.showOverlay = true;
      this.changePage(1)
      this.$store.state.dtAeo.duplicateForm = Object.assign({}, this.form);
      window.scrollTo(0, 0);
    },
    format_date(value) {
      return moment(value).format("DD.MM.YYYY");
    },
    changePage(pageNumber) {
      this.$store.state.general.showOverlay = true;
      this.form.currentPage = pageNumber;
      this.listAllNotApproved();
      this.$store.state.dtAeo.duplicateForm = Object.assign({}, this.form);
      window.scrollTo(0, 0);
    },
    listAllNotApproved() {
      this.$store.state.general.showOverlay = true;
      this.getAnnouncementSearchPaginated(this.form)
          .then((res) => {
            this.$store.state.general.showOverlay = false;
            if (res.endpointResult.data) {
              this.showError = false;
            } else {
              this.$store.state.dtAeo = Object.assign({});
              this.errorMessage = res.endpointResult.errorMessage;
              this.showError = true;
            }
          })
          .catch(error => {
            this.$store.state.general.showOverlay = false;
            this.showModal({
              title: "Hata!",
              message: error,
              type: "danger",
            });
          });
    },
    customLabel({options_grouping}) {
      return `${options_grouping.lang}`;
    },
    ...mapActions('dtAeo', ["getAnnouncementSearchPaginated", "approvePages"]),
    ...mapActions("general", ["whatIsMyDuty", "syncAnnouncementFromOldPortal"])

  },
  watch: {
    selected() {
      this.allSelected = this.selected.length === this.$store.state.dtAeo.AnnouncementPaginatedSearchResult.item.length;
    },
    "$store.state.general.userDetail":
        function () {
          let userDetail = this.$store.state.general.userDetail;
          console.log(userDetail);
          if (userDetail.directionUrl != null) {
            this.form.approvedBy = userDetail.personId;
            this.listAllNotApproved();
          }
        }
  },
}
</script>
<style>
.form-check-label {
  display: flex;
  align-items: center;
}

.form-check-input {
  margin-right: 5px;
}

</style>