<template>
  <div>
    <b-overlay :show="showOverlay" rounded="sm" class="d-flex flex-fill w-100"></b-overlay>
    <b-modal
        id="modal-user-edit"
        size="lg"
        centered
        :title="modalTitle"
        ok-title="Kaydet"
        ok-variant="success"
        cancel-title="İptal"
        cancel-variant="outline-secondary"
        :busy="showOverlay"
        @ok.prevent="save"
        @hidden="clear"
    >
{{this.form}}
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label> TC Kimlik No</label>
            <input type="text" class="form-control" v-model="form.personelIdNumber"/>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <div class="form-group">
            <label>Ad</label>
            <input type="text" class="form-control" v-model="form.name"/>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label>Soyad</label>
            <input type="text" class="form-control" v-model="form.surname"/>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label>Diğer İsim</label>
            <input type="text" class="form-control" v-model="form.otherName"/>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <div class="form-group">
            <label>UserName</label>
            <input type="text" class="form-control" v-model="form.userName"/>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label>e-mail</label>
            <input type="text" class="form-control" v-model="form.eMail"/>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label>Gender</label>
            <select
                v-select
                class="form-control"
                v-model="form.genderId"

            >
              <option
                  :value="item.id"
                  v-for="(item, index) in $store.state.dtAeo.gender"
                  :key="index"
              >
                {{ item.text }}
              </option>
            </select>
          </div>
        </div>

      </div>
      <div class="row">
        <div class="col-4">
          <div class="form-group">
            <label>Title</label>
            <select
                v-select
                class="form-control"
                v-model="form.titleId"

            >
              <option
                  :value="item.id"
                  v-for="(item, index) in $store.state.dtAeo.title"
                  :key="index"
              >
                {{ item.text }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label>Doğum Tarihi</label>
            <date-picker
                :popup-style="{ top: '100%', left: 0}"
                :append-to-body="false"
                v-model="form.birthDate"
                format="YYYY-MM-DD"
                type="date"
                valueType="format"
                :open.sync="open"
            ></date-picker>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label>Phone Number</label>
            <input type="text" class="form-control" v-model="form.cellular"/>
          </div>
        </div>

      </div>
      <div class="row">

        <div class="col">

          <div class="form-group">
            <label>Roller</label>
          </div>
        </div>
      </div>


      <div v-if="this.$store.state.general.roles.length>0">
        <div
            class="row"
            v-for="rowIdx in Math.ceil(roles.length / 3)">
          <div class="one-third column"
               style="margin-left: 50px"
               v-for="item in roles.slice(3 * (rowIdx - 1), 3 * rowIdx)">
            <div class="form-group">
              <label class="d-block">{{ item.name }}</label>
              <label class="label_switch">
                <input type="checkbox" v-model="item.selected"
                />
                <span class="mr-2"></span>
              </label>
            </div>
          </div>
        </div>
      </div>


    </b-modal>
    <div class="row"
    >

      <div class="col-4">
        <label>Adı</label>
      </div>
      <div class="col-4">
        <label>Soyadı</label>
      </div>

    </div>
    <div class="row">
      <div class="col-4">

        <div class="form-group">
          <input
              type="text"
              class="form-control"
              v-model="searchByName"
              maxlength="100"
          />
        </div>
      </div>
      <div class="col-4">

        <div class="form-group">
          <input
              type="text"
              class="form-control"
              v-model="searchBySurName"
              maxlength="100"
          />
        </div>
      </div>
      <div class="col-2">
        <div class="form-group">
          <button class="btn btn-outline-secondary block" @click="clear()">
            {{ $t("clear") }}
          </button>
        </div>
      </div>
      <div class="col-2">
        <div class="form-group">
          <button class="btn btn-secondary block" @click="changePage(1)">{{ $t("list") }}</button>
        </div>
      </div>
    </div>


    <div class="table-responsive">
      <table class="table table-hover">
        <thead>
        <tr>
          <th class="fit">Kullanıcı Adı</th>
          <th class="w-25">Adı</th>
          <th class="w-25">Soyadı</th>
          <th class="w-25">E-Mail</th>

          <th class="fit text-right">
            <button class="btn btn-sm" @click.stop="addPage">
              <PlusCircleIcon/>
            </button>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="(item, index) in $store.state.general.DTPaginatedUsers.item"
            :key="index"
            class="cursor-pointer"
            @click="showEditModal(item)"
        >
          <td class="fit">{{ item.userName }}</td>
          <td class="w-25">{{ item.name }}</td>
          <td class="w-25">{{ item.surName }}</td>
          <td class="w-25">{{ item.eMail }}</td>

          <td class="fit text-right">

          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="alert alert-danger text-center" v-if="this.$store.getters['general/getUserCount']<=0">
      {{ "Aradığınız kriterlere uygun kayıt bulunamadı." }}
    </div>
    <div class="row" v-if="$store.state.general.DTPaginatedUsers.item.length > 0">
      <div class="col">
        <i class="text-secondary"
        >{{
            $store.state.general.DTPaginatedUsers.dtPagination.totalItems
          }}
          kayıttan {{ (form.currentPage - 1) * form.pageSize + 1 }} -
          {{ form.currentPage * form.pageSize }} arası gösterilmektedir.</i
        >
      </div>
      <div class="col">
        <div class="row">
          <div class="col d-flex justify-content-end">
            <nav>
              <paginate
                  v-if="$store.state.general.DTPaginatedUsers.item.length"
                  :page-count="
                  $store.state.general.DTPaginatedUsers.dtPagination.totalPages
                "
                  :click-handler="changePage"
                  :prev-text="'Önceki'"
                  :next-text="'Sonraki'"
                  :active-class="'active'"
                  :container-class="'pagination'"
                  v-model="form.currentPage"
              >
              </paginate>
            </nav>
          </div>
          <div class="col-2">
            <select
                v-select
                class="form-control"
                v-model="form.pageSize"
                @change="changeSize"
            >
              >
              <option value="25" selected>25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PlusCircleIcon from "@/components/PlusCircleIcon";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/tr";
import {mapActions, mapGetters} from "vuex";
import {required, requiredIf} from "vuelidate/lib/validators";
import {forEach} from "lodash";
import DatePicker from "vue2-datepicker";

export default {
  data() {
    return {
      searchByName:null,
      searchBySurName:null,
      selectedEmployee: null,
      openingType: "",
      showList: true,
      showError: false,
      selectedId: null,
      modalTitle: "Kullanıcı Bilgisi Güncelleme",
      isEditing: false,
      showOverlay: false,
      pageGroups: [],
      temp: {},
      roles: [],
      open: false,
      form: {
        userId: null,
        cellular: "",
        userName: "",
        name: "",
        surname: "",
        otherName: null,
        eMail: "",
        roles: [],
        titleId: null,
        genderId: null,
        birthDate: null,
        personelIdNumber: "",
        currentPage: 1,
        pageSize: 25
      },
      selectedRoleItemGroups: [],
      selectedRoles: [],
      updateItemGroupDTO: {}
    };
  },
  components: {
    DatePicker,
    PlusCircleIcon,
  },

  computed: {
    ...mapGetters("general", ["getMessage"]),
    ...mapGetters("general", ["getUserCount"]),

  },

  methods: {
    async addPage() {
      this.isEditing = false;
      await this.showEditModal(null);
    },
    list: function () {
      this.$store.state.general.showOverlay = true;
      this.form.Name = this.searchByName
      this.form.SurName = this.searchBySurName
      this.filterUsers(this.form)
          .then((res) => {
            this.$store.state.general.showOverlay = false;
            if (res.endpointResult.data) {
              this.showError = false;
            } else {
              this.$store.state.general.users = Object.assign({});
              this.errorMessage = "this.getMessage(118);"
              this.showError = true;
            }
          })
          .catch(() => {
            this.$store.state.general.showOverlay = false;
          });
    },

    changeSize() {
      this.$store.state.general.showOverlay = true;
      this.changePage(1)
      this.$store.state.general.duplicateForm = Object.assign({}, this.form);
      window.scrollTo(0, 0);
    },
    changePage(pageNumber) {
      this.$store.state.general.showOverlay = true;
      this.form.currentPage = pageNumber;
      this.list();
      this.$store.state.general.duplicateForm = Object.assign({}, this.form);
      window.scrollTo(0, 0);
    },




    async save() {
      this.showOverlay = true;
      if (this.isEditing === true) {
        //Get All Selected İşlem Grups

        for (let i = 0; i < this.roles.length; i++) {
          if (this.roles[i].selected == true) {
            this.selectedRoles.push(this.roles[i].id.toString())
          }
        }
        // Put in an object
        this.updateRoleItemGroupDTO = {
          "userId": this.form.userId.toString(),
          "name": this.form.name,
          "surname": this.form.surname,
          "userName": this.form.userName,
          "eMail": this.form.eMail,
          "selectedRoles": this.selectedRoles,
          "genderId": this.form.genderId,
          "titleId": this.form.titleId,
          "otherName": this.form.otherName,
          "birthDate": this.form.birthDate,
          "cellular": this.form.cellular?.toString(),
          "personelIdNumber": this.form.personelIdNumber.toString(),
        }
        this.$nextTick(() => {
          this.$bvModal.hide("modal-user-edit");
        })
        this.$store.state.general.showOverlay = true;
        // Send to api

        await this.updateUser(this.updateRoleItemGroupDTO).then((res) => {
          if (res.hasError) {
            this.showModal({
              title: "Hata!",
              message: res.errorMessage,
              type: "danger",
            });
          } else {
            this.$store.state.general.showOverlay = false;
            this.showOverlay = false;
            this.showModal({
              title: "İşlem Başarılı",
              message: "Kayıt Başarıyla Güncellendi",
              type: "success",
            });
          }
        });
      } else {
        // Yeni Kayıt
        for (let i = 0; i < this.roles.length; i++) {
          if (this.roles[i].selected === true) {
            this.selectedRoles.push(this.roles[i].id.toString())
          }
        }
        this.updateRoleItemGroupDTO = {
          "name": this.form.name,
          "personelIdNumber": this.form.personelIdNumber.toString(),
          "surname": this.form.surname,
          "userName": this.form.userName,
          "eMail": this.form.eMail,
          "selectedRoles": this.selectedRoles,
          "genderId": this.form.genderId,
          "titleId": this.form.titleId,
          "otherName": this.form.otherName,
          "birthDate": this.form.birthDate,
          "cellular": this.form.cellular,
        }
        this.$nextTick(() => {
          this.$bvModal.hide("modal-user-edit");
        })
        this.$store.state.general.showOverlay = true;
        // Send to api
        await this.addUser(this.updateRoleItemGroupDTO).then((res) => {
          if (res.hasError) {

            this.showModal({
              title: "Hata!",
              message: res.errorMessage,
              type: "danger",
            });
          } else {
            this.showModal({
              title: "İşlem Başarılı",
              message: "Kayıt Başarıyla Güncellendi",
              type: "success",
            });
          }
        });
      }
      this.$store.state.general.showOverlay = false;
      this.showOverlay = false;
      this.roles = this.$store.state.general.roles;
      for (let i = 0; i < this.roles.length; i++) {
        this.roles[i].selected = false;
      }

      this.clear();


    },
    async showEditModal(item) {
      this.clear();
      this.selectedRoles = [];
      if (item !== null) {
        this.isEditing = true;

        this.modalTitle = "User Yetki/Bilgi Güncelleme";

        this.$store.state.general.showOverlay = true;
        this.showOverlay = true;
        await this.getUserDetail(item.userId);
        this.$store.state.general.showOverlay = false;
        this.temp = this.$store.state.general.CurrentUser;

        this.form.userId = this.temp.userPerson.userId;
        this.form.userName = this.temp.userPerson.userName;
        this.form.name = this.temp.userPerson.name;
        this.form.surname = this.temp.userPerson.surName;
        this.form.eMail = this.temp.userPerson.eMail;
        this.form.roles = this.temp.roles;
        this.form.personelIdNumber = this.temp.userPerson.personelIdNumber;
        this.form.birthDate = this.temp.userPerson.birthDate;
        this.form.titleId = this.temp.userPerson.titleId;
        this.form.genderId = this.temp.userPerson.genderId;
        this.form.cellular = this.temp.userPerson.cellular;
        this.form.otherName = this.temp.userPerson.otherName;
        for (let i = 0; i < this.form.roles.length; i++) {
          for (let j = 0; j < this.roles.length; j++) {
            if (this.roles[j].name === this.form.roles[i]) {
              this.roles[j].selected = true;
            }
          }
        }

      }


      this.$bvModal.show("modal-user-edit");
      this.showOverlay = false;

    },

    ...mapActions("general", ["showModal", "filterRoles",
      "filterUsers", "addUser", "updateUser", "getUserDetail"]),

    ...mapActions("dtAeo", ["getTitle", "getGender"]),
    clear() {
      this.isEditing = false;
      this.showError = false;
      this.searchByName = "";
      this.searchBySurName = "";
      this.form = Object.assign(
          {},
          {
            userId: null,
            name: "",
            surname: "",
            userName: "",
            eMail: "",
            personelIdNumber: "",
            titleId: null,
            genderId: null,
            birthDate: null,
            otherName: null,
            roles: [],
            currentPage: 1,
            pageSize: 25
          }
      );

      for (let i = 0; i < this.roles.length; i++) {
        this.roles[i].selected = false;
      }
      this.list();

    },
  },


  async created() {
    this.$store.state.general.showOverlay = true;
    this.showOverlay = true;
    await this.filterRoles('');

    await this.filterUsers(this.form);
    await this.getTitle();
    await this.getGender();

    // await this.getCompanies();
    this.roles = this.$store.state.general.roles;
    for (let i = 0; i < this.roles.length; i++) {
      this.roles[i].selected = false;
    }

    this.$store.state.general.showOverlay = false;
    this.showOverlay = false;
  }
};
</script>


<style scoped>

</style>