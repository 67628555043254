<template>
  <div class="standart-page">
    <div class="card-component mb-4">
      <div class="body">
        {{this.form}}
        <div class="row">
          <div class="col-md">
            <div class="form-group">
              <label>Açıklama</label>
              <input
                  :class="{ 'is-invalid': $v.form.description.$error }"
                  type="text"
                  class="form-control"
                  v-model="form.description"
                  maxlength="100"
              />
              <template v-if="$v.form.description.$error">
                <small
                    class="text-danger"
                    v-if="!$v.form.description.requiredIf"
                >Dosya yüklendiği için bu alan zorunludur.</small
                >
              </template>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-3">
            <input
                type="file"
                ref="sfile"
                class="d-none"
                @change="onChangeFileUpload($event, false)"
            />
            <button
                class="btn btn-secondary w-100"
                @click="$refs.sfile.click()"
            >
              Fotoğraf Seç
            </button>
          </div>
          <div class="col-md-9">
            <div class="form-group">
              <input
                  type="text"
                  class="form-control"
                  disabled
                  v-model="form.fileName"
              />
            </div>
          </div>
        </div>

        <div class="row mt-3 justify-content-end">
          <div class="col-md-4">
            <div class="form-group w-100">
              <button
                  @click="save"
                  class="btn btn-danger w-100 align-content-center">
                Dosyayı Kaydet
              </button>
            </div>
          </div>
        </div>
        <table
            v-if="this.personFileList.length > 0"
            class="table table-hover">
          <thead>
          <tr>
            <th class="fit">Description</th>
            <th class="fit">Dosya İsmi</th>
            <th class="fit">İndir</th>

          </tr>
          </thead>
          <tbody>
          <tr
              @click="showEditModal(item)"
              v-for="(item, index) in this.personFileList"
              :key="index"
              class="cursor-pointer"
          >
            <td class="fit">{{ item.description }}</td>
            <td class="fit">{{ item.fileExtension }}</td>
            <td class="fit"> indir</td>
          </tr>
          </tbody>
        </table>
        <div v-if="personFileList.length>0" class="row mt-3 justify-content-end">
          <div class="col-md-4">
            <div class="form-group w-100">
              <button
                  @click="updatePersonRegistrationState"
                  class="btn btn-danger w-100 align-content-center">
                Bir sonraki adım'a geç
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {requiredIf} from "vuelidate/lib/validators";
import {mapActions} from "vuex";

export default {
  name: "TahTesTut",
  delimiters: ['${', '}'], // Avoid Twig conflicts
  tabIndex: 0,
  data() {
    return {
      personFileList: [],
      form: {
        id: null,
        personId: null,
        memberRegistrationStateTypeId: 0,
        memberId: null,
        file: null,
        fileName: null,
        description: null,
      }
    };
  },

  validations: {
    form: {
      description: {
        requiredIf: requiredIf(function (form) {
          return form.file !== null;
        }),
      }
    }
  },

  methods: {
    ...mapActions("membershipAeo", ["updatePersonState", "getPersonFile",
      "updatePersonFile", "savePersonFile"]),
    ...mapActions("general", ["showModal"]),

    showEditModal(item) {
      const {
        id,
        languageLevelId,
        languageId,
      } = item;
      Object.assign(this.form, {
        id: id,
        languageLevelId: languageLevelId,
        languageId: languageId,
      });
      this.$bvModal.show("modal-personLanguage-list-edit");
    },
    save: function () {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.form.memberRegistrationStateTypeId === -1) {
          this.showModal({
            title: "Hata!",
            message: "Bir sonraki aşamaya geçilemiyor!",
            type: "danger",
          });
          return;
        }
        this.$store.state.general.showOverlay = true;
        const formData = new FormData();
        formData.append("memberId", this.form.memberId);
        formData.append("file", this.form.file);
        formData.append("description", this.form.description);


        this.savePersonFile(formData).then(res => {
          if (res.hasError) {
            this.showModal({
              title: "Hata!",
              message: res.errorMessage,
              type: "danger",
            });
          } else {
            this.showModal({
              title: "Başarıylı!",
              message: "Dosya başarıyla kayıt edildi.",
              type: "success",
            });
            this.personFileList = res.data;

            this.clearForm();
          }
        }).catch((reason) => {
          this.showModal({
            title: "Hata!",
            message: reason.toString(),
            type: "danger",
          });
        }).finally(() => {
          this.$store.state.general.showOverlay = false;

        });
      }
    },
    clearForm() {
      const {description, file, fileName} = this.form;
      const clearedProps = {
        description: null,
        file: null,
        fileName: null,
      };
      this.form = Object.assign({}, this.form, clearedProps);
      this.$v.$reset();
    },
    updatePersonRegistrationState() {
      this.$router.push("member-detail/" + this.form.personId);
    },
    onChangeFileUpload(e) {
      this.form.file = this.$refs.sfile.files[0];
      this.form.fileName = this.$refs.sfile.files[0].name;
    }
  },
  watch: {
    "$store.state.membershipAeo.selectedPerson":
        function () {
          if (this.$store.state.membershipAeo.selectedPerson.person != null) {
            this.form.personId = this.$store.state.membershipAeo.selectedPerson.person.id;
            this.form.memberId = this.$store.state.membershipAeo.selectedPerson.member.id;
            this.getPersonFile(this.form.memberId).then((res) => {
              if (res.hasError) {
                this.showModal({
                  title: "Hata!",
                  message: "Kişiye ait dosya bilgilerine erişilemedi. Lütfen sayfayı yenileyin",
                  type: "danger",
                });
              } else {
                this.personFileList = Object.assign([], res.data)
                console.log(this.personFileList)
              }
            });
          }
        },

  },
}
</script>
