import axios from "axios";
import authHeader from "@/services/auth-header";

class AcademyAeoService {

    getAcademyUserWaitingApproval(payload) {
        const url = `${process.env.VUE_APP_PORTAL_API_URL}Academy/get-academy-user-waiting-approval-paginated`;

        return axios.post(url, {
                Name: payload.name,
                SurName: payload.surName,
                PersonelIdNumber: payload.personelIdNumber,
                PageNumber: payload.currentPage,
                PageLength: payload.pageSize,
            },
            {
                headers: authHeader()
            },);
    }

    getAcademyUserApproved(payload) {
        const url = `${process.env.VUE_APP_PORTAL_API_URL}Academy/get-academy-user-approved-paginated`;
        const params = {
            Name: payload.name,
            Surname: payload.surname,
            PageNumber: payload.currentPage,
            PageLength: payload.pageSize,
        };
        return axios.post(url, {
                params
            },
            {
                headers: authHeader()
            },);
    }

    approveAcademyUser(payload) {
        const url = `${process.env.VUE_APP_PORTAL_API_URL}Academy/approve-academy-user`;
        const params = {
            personelIdNumber: payload,
        }
        console.log(params)
        return axios.get(url, {
            params: params,
            headers: authHeader(),
        },);
    }
}

export default new AcademyAeoService();
