<template>
  <div class="standart-page">
    <b-modal
        id="modal-workplace-add"
        size="lg"
        centered
        :title="modalTitle"
        ok-title="Kaydet"
        ok-variant="success"
        cancel-title="İptal"
        cancel-variant="outline-secondary"
        @ok.prevent="save"
        @hidden="clear">
      <AddWorkplace></AddWorkplace>
    </b-modal>
    <div class="card-component mb-4">
      <div class="body">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label>Kurum Adı</label>
              <select
                  v-select
                  class="form-control"
                  v-model="form.workPlaceId"
              >
                <option :value="null">Seçiniz</option>
                <option
                    :value="item.id"
                    v-for="(item, index) in this.workplaceList"
                    :key="index"
                >
                  {{ item.name }}
                </option>
              </select>

            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <button class="btn btn-sm" @click.stop="addWorkplace">
                <PlusCircleIcon/>
              </button>
              <label> Demo Amaçlıdır</label>

            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label>Pozisyon Adı</label>
              <!--:class="{ 'is-invalid': $v.form.documentTypeId.$error }"-->
              <select
                  v-select
                  class="form-control"
                  v-model="form.positionId"
              >
                <option :value="null">Seçiniz</option>
                <option
                    :value="item.id"
                    v-for="(item, index) in this.positionList"
                    :key="index"
                >
                  {{ item.text }}
                </option>
              </select>

            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>İşe Giriş Tarihi</label>

              <input
                  type="date"
                  class="form-control"
                  v-model="form.startDate"
                  maxlength="100"
              />


            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>İşten Çıkış Tarihi</label>
              <!--
                                :class="{ 'is-invalid': $v.form.endDate.$error }"
               -->
              <input

                  type="date"
                  class="form-control"
                  v-model="form.endDate"
                  maxlength="100"
              />


            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>Diğer İsim</label>
              <input
                  type="text"
                  class="form-control"
                  v-model="form.description"
                  maxlength="100"
              />

            </div>
          </div>
        </div>

        <div class="row mt-3 justify-content-end">
          <div class="col-md-4">
            <div class="form-group w-100">
              <button
                  @click="save"
                  class="btn btn-danger w-100 align-content-center">
                Çalışma Geçmişi Kaydet
              </button>
            </div>
          </div>
        </div>
        <table
            v-if="this.personWorkplaceList.length > 0"
            class="table table-hover">
          <thead>
          <tr>
            <th class="fit">İş Yeri Adı</th>
            <th class="fit">Pozisyon</th>
            <th class="fit">Başlangıç Tarihi</th>
            <th class="fit">Ayrılış Tarihi</th>

          </tr>
          </thead>
          <tbody>
          <tr
              @click="showEditModal(item)"
              v-for="(item, index) in this.personWorkplaceList"
              :key="index"
              class="cursor-pointer"
          >
            <td class="fit">{{
              $store.state.associationAeo.workplace.find(
              (d) => d.id === item.workplaceId
              ).name
              }}
            </td>
            <td class="fit">{{
              $store.state.dtAeo.positions.find(
              (d) => d.id === item.positionId
              ).text
              }}
            </td>
            <td class="fit">{{format_date(item.startDate)}}</td>
            <td class="fit">{{format_date(item.endDate)}}</td>
          </tr>
          </tbody>
        </table>
        <div v-if="personWorkplaceList.length>0" class="row mt-3 justify-content-end">
          <div class="col-md-4">
            <div class="form-group w-100">
              <button
                  @click="updatePersonRegistrationState"
                  class="btn btn-danger w-100 align-content-center">
                Bir sonraki adım'a geç
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {VueEditor} from "vue2-editor"
import {mapActions, mapGetters} from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/tr";
import moment from "moment";
import {minLength, required} from "vuelidate/lib/validators";
import PlusCircleIcon from "@/components/PlusCircleIcon.vue";
import AddWorkplace from "@/views/workplace/AddWorkplace.vue";

export default {
  name: "TahTesTut",
  components: {
    AddWorkplace,
    PlusCircleIcon,
    VueEditor,
    DatePicker,
    moment,
  },
  delimiters: ['${', '}'], // Avoid Twig conflicts
  tabIndex: 0,
  data() {
    return {
      personWorkplaceList: [],
      workplaceList: [],
      positionList: [],
      modalTitle: null,
      form: {
        id: null,
        memberRegistrationStateTypeId: 0,
        personId: null,
        memberId: null,
        workPlaceId: null,
        startDate: null,
        endDate: null,
        description: null,
        positionId: null,
      },
    };
  },
  created() {

    if (this.getterPositions().length === 0) {
      this.getPositions();
    }
    if (this.getterWorkplace().length === 0) {
      this.getWorkplace();
    }
  },

  validations: {},

  methods: {
    ...mapActions("membershipAeo", ["updatePersonState", "getPersonWorkplace", "savePersonWorkplace", "updatePersonWorkplace"]),
    ...mapActions("dtAeo", ["getPositions"]),
    ...mapGetters("dtAeo", ["getterPositions"]),
    ...mapGetters("associationAeo", ["getterWorkplace"]),
    ...mapActions("associationAeo", ["getWorkplace"]),
    ...mapActions("general", ["showModal"]),

    showEditModal(item) {
      const {
        id,
        workPlaceId,
        startDate,
        endDate,
        description,
        positionId,
      } = item;
      Object.assign(this.form, {
        id: id,
        workPlaceId: workPlaceId,
        startDate: startDate,
        endDate: endDate,
        description: description,
        positionId: positionId,
      });
      this.$bvModal.show("modal-personWorkplace-list-edit");
    },
    save: function () {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.form.memberRegistrationStateTypeId === -1) {
          this.showModal({
            title: "Hata!",
            message: "Bir sonraki aşamaya geçilemiyor!",
            type: "danger",
          });
          return;
        }
        this.$store.state.general.showOverlay = true;
        this.savePersonWorkplace(this.form).then(res => {
          if (res.hasError) {
            this.showModal({
              title: "Hata!",
              message: res.errorMessage,
              type: "danger",
            });
          } else {
            this.showModal({
              title: "Başarıylı!",
              message: "İş Geçmiş Bilgisi kayıt edildi.",
              type: "success",
            });
            this.personWorkplaceList = res.data;
            if (this.form.personId == null) {
              this.form.personId = this.personWorkplaceList[0].personId;
            }
            this.clearForm();
          }
        }).catch((reason) => {
          this.showModal({
            title: "Hata!",
            message: reason.toString(),
            type: "danger",
          });
        }).finally(() => {
          this.$store.state.general.showOverlay = false;

        });
      }
    },
    update: function () {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store.state.general.showOverlay = true;
        this.updatePersonWorkplace(this.form).then(res => {
          if (res.hasError) {
            this.showModal({
              title: "Hata!",
              message: res.errorMessage,
              type: "danger",
            });
          } else {
            this.showModal({
              title: "Başarıylı!",
              message: "Dil Bilgisi güncellendi.",
              type: "success",
            });
            this.personWorkplaceList = res.data;
            if (this.form.personId == null) {
              this.form.personId = this.personWorkplaceList[0].personId;
            }
            this.clearForm();
          }

        }).catch((reason) => {
          this.showModal({
            title: "Hata!",
            message: reason.toString(),
            type: "danger",
          });
        }).finally(() => {
          this.$store.state.general.showOverlay = false;
          //this.$nextTick(()=>{}); if necessary
          this.$bvModal.hide("modal-personLanguage-list-edit");
        })
      }
    },
    clear() {
      this.modalTitle = "Add Workplace";
      this.$v.$reset();
    },
    addWorkplace(){
      this.$bvModal.show("modal-workplace-add");
    },
    clearForm() {
      const {
        workPlaceId,
        startDate,
        endDate,
        description,
        positionId,
      } = this.form;
      const clearedProps = {
        workPlaceId: null,
        startDate: null,
        endDate: null,
        description: null,
        positionId: null,
      };
      this.form = Object.assign({}, this.form, clearedProps);
      this.$v.$reset();
    },
    updatePersonRegistrationState() {
      let tabIndex = this.$store.state.membershipAeo.tabIndex;
      console.log(this.$store.state.membershipAeo.memberRegistrationStates);
      console.log(this.$store.state.membershipAeo.tabIndex)
      let index =
          this.$store.state.membershipAeo.memberRegistrationStates.findIndex(x => x.index === tabIndex + 1)
      this.form.memberRegistrationStateTypeId = this.$store.state.membershipAeo.memberRegistrationStates[index].id;

      this.updatePersonState(this.form).then(res => {
        if (!res.hasError)
          this.$emit("update-tab-index",
              this.$store.state.membershipAeo.memberRegistrationStates[index].index);
        else {
          this.showModal({
            title: "FAIL!",
            message: res.errorMessage,
            type: "danger",
          });
        }
      }).catch((reason) => {
        this.showModal({
          title: "Hata!",
          message: reason.toString(),
          type: "danger",
        });
      }).finally(() => {
        this.$store.state.general.showOverlay = false;
      });
    },
    format_date(value) {
      return moment(value).format("DD.MM.YYYY");
    },

  },
  watch: {
    "$store.state.associationAeo.workplace":
        function () {
          this.workplaceList = this.getterWorkplace();
        },
    "$store.state.dtAeo.positions":
        function () {
          this.positionList = this.getterPositions();
        },
    "$store.state.membershipAeo.selectedPerson":
        function () {
          if (this.$store.state.membershipAeo.selectedPerson.person != null) {
            this.form.personId = this.$store.state.membershipAeo.selectedPerson.person.id;
            this.form.memberId = this.$store.state.membershipAeo.selectedPerson.member.id;
            this.getPersonWorkplace(this.form.memberId).then((res) => {
              if (res.hasError) {
                this.showModal({
                  title: "Hata!",
                  message: "Kişinin iş geçmiş bilgileri çekilemedi. Lütfen sayfayı yenileyin",
                  type: "danger",
                });
              } else {
                this.personWorkplaceList = Object.assign([], res.data)
                console.log(this.personWorkplaceList)
              }
            });
          }
        },
  },
}
</script>
