<template>
  <div>
    <b-modal
        id="modal-workplace-add"
        size="lg"
        centered
        :title="modalTitle"
        ok-title="Kaydet"
        ok-variant="success"
        cancel-title="İptal"
        cancel-variant="outline-secondary"
        @hidden="clear">
      <AddWorkplace></AddWorkplace>
    </b-modal>
    <b-modal
        id="modal-personWorkplace-list-edit"
        size="lg"
        centered
        title="Kişi İş Geçmişini düzenle"
        ok-title="Kaydet"
        ok-variant="success"
        cancel-title="İptal"
        cancel-variant="outline-secondary"
        @ok.prevent="update"
        @hidden="clear">
      <div class="row">
        {{this.form}}
        <div class="col-md-4">
          <div class="form-group">
            <label>Kurum Adı</label>
            <select
                v-select
                class="form-control"
                v-model="form.workPlaceId"
            >
              <option :value="null">Seçiniz</option>
              <option
                  :value="item.id"
                  v-for="(item, index) in this.workplaceList"
                  :key="index"
              >
                {{ item.name }}
              </option>
            </select>

          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <button class="btn btn-sm" @click.stop="addWorkplace">
              <PlusCircleIcon/>
            </button>
            <label> Demo Amaçlıdır</label>

          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label>Pozisyon Adı</label>
            <!--:class="{ 'is-invalid': $v.form.documentTypeId.$error }"-->
            <select
                v-select
                class="form-control"
                v-model="form.positionId"
            >
              <option :value="null">Seçiniz</option>
              <option
                  :value="item.id"
                  v-for="(item, index) in this.positionList"
                  :key="index"
              >
                {{ item.text }}
              </option>
            </select>

          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>İşe Giriş Tarihi</label>

            <input
                type="date"
                class="form-control"
                v-model="form.startDate"
                maxlength="100"
            />


          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>İşten Çıkış Tarihi</label>
            <input

                type="date"
                class="form-control"
                v-model="form.endDate"
                maxlength="100"
            />


          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Diğer İsim</label>
            <input
                type="text"
                class="form-control"
                v-model="form.description"
                maxlength="100"
            />

          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label class="d-block">Aktif</label>
            <label class="label_switch">
              <input type="checkbox" v-model="form.isInUse"/>
              <span class="mr-2"></span>
            </label>
          </div>
        </div>

      </div>

    </b-modal>

    <table
        v-if="this.personWorkplaceList.length > 0"
        class="table table-hover">
      <thead>
      <tr>
        <th class="fit">İş Yeri Adı</th>
        <th class="fit">Pozisyon</th>
        <th class="fit">Başlangıç Tarihi</th>
        <th class="fit">Ayrılış Tarihi</th>
        <th class="fit text-right">Aktif</th>
        <th class="fit text-right">
          <PlusCircleIcon v-b-modal.modal-personWorkplace-list-edit/>
        </th>
        <th class="fit text-right">
          <ShowHistoryIcon @iconClicked="showHistoryClicked" :disabled="disableHistoryButton"/>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
          @click="showEditModal(item)"
          v-for="(item, index) in this.personWorkplaceList"
          :key="index"
          class="cursor-pointer"
      >
        <td class="fit">{{
          $store.state.associationAeo.workplace.find(
          (d) => d.id === item.workplaceId
          ).name
          }}
        </td>
        <td class="fit">{{
          $store.state.dtAeo.positions.find(
          (d) => d.id === item.positionId
          ).text
          }}
        </td>
        <td class="fit">{{format_date(item.startDate)}}</td>
        <td class="fit">{{format_date(item.endDate)}}</td>
        <td class="fit text-right">
          <font-awesome-icon icon="check" size="lg" class="text-danger" v-if="item.isInUse"/>
          <font-awesome-icon icon="ban" size="lg" class="text-danger" v-else/>
        </td>
        <td class="fit text-right"></td>
        <td class="fit text-right"></td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {VueEditor} from "vue2-editor"
import {mapActions, mapGetters} from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/tr";
import moment from "moment";
import {minLength, required} from "vuelidate/lib/validators";
import PlusCircleIcon from "@/components/PlusCircleIcon.vue";
import AddWorkplace from "@/views/workplace/AddWorkplace.vue";
import ShowHistoryIcon from "@/components/ShowHistoryIcon.vue";

export default {
  props: {
    memberId: {
      required: true
    }
  },
  name: "MemberDetail",
  components: {
    ShowHistoryIcon,
    AddWorkplace,
    PlusCircleIcon,
    VueEditor,
    DatePicker,
    moment,
  },
  delimiters: ['${', '}'], // Avoid Twig conflicts
  tabIndex: 0,
  data() {
    return {
      prevIsInUse: true,
      isEditing: false,
      disableHistoryButton: false,
      personWorkplaceList: [],
      workplaceList: [],
      positionList: [],
      modalTitle: null,
      form: {
        id: null,
        memberRegistrationStateTypeId: 0,
        personId: null,
        memberId: null,
        workPlaceId: null,
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        description: null,
        positionId: null,
        isInUse: null,
      },
    };
  },
  created() {
    this.form.memberId = this.memberId;
    if (this.getterPositions().length === 0) {
      this.getPositions();
    } else {
      this.positionList = this.getterPositions();
    }
    if (this.getterWorkplace().length === 0) {
      this.getWorkplace();
    } else {
      this.workplaceList = this.getterWorkplace();
    }

    if (this.getterPositions().length && this.getterWorkplace().length) {
      this.getPersonWorkplaces(true);
    }

  },

  validations: {},

  methods: {

    showHistoryClicked() {
      this.prevIsInUse = !this.prevIsInUse;
      this.getPersonWorkplaces(this.prevIsInUse)
    },

    ...mapActions("membershipAeo", ["updatePersonState", "getPersonWorkplace", "savePersonWorkplace", "updatePersonWorkplace"]),
    ...mapActions("dtAeo", ["getPositions"]),
    ...mapGetters("dtAeo", ["getterPositions"]),
    ...mapGetters("associationAeo", ["getterWorkplace"]),
    ...mapActions("associationAeo", ["getWorkplace"]),
    ...mapActions("general", ["showModal"]),


    getPersonWorkplaces(isInUse) {
      this.disableHistoryButton = !this.disableHistoryButton
      this.$store.state.general.showOverlay = true;
      let payload = {
        isInUse: isInUse,
        memberId: this.form.memberId
      };
      this.getPersonWorkplace(payload).then(res => {
        if (res.hasError) {
          this.showModal({
            title: "Hata!",
            message: res.errorMessage,
            type: "danger",
          });
        } else {
          if (res.data.length > 0) {
            this.personWorkplaceList = res.data;
            if (this.form.personId == null || this.form.memberId == null) {
              this.form.personId = this.personWorkplaceList[0].personId;
              this.form.memberId = this.personWorkplaceList[0].memberId;
            }
          } else {
            this.prevIsInUse = !this.prevIsInUse;
            this.showModal({
              title: "Uyarı!",
              message: "İş Geçmiş kaydı bulunamadı.",
              type: "danger",
            });
          }
        }
      }).catch((reason) => {
        this.showModal({
          title: "Hata!",
          message: reason.toString(),
          type: "danger",
        });
      }).finally(() => {
        this.$store.state.general.showOverlay = false;
        this.disableHistoryButton = !this.disableHistoryButton
      });
    },


    showEditModal(item) {
      this.isEditing = true;
      const {
        id,
        workplaceId,
        startDate,
        endDate,
        description,
        positionId,
        isInUse,
      } = item;
      Object.assign(this.form, {
        id: id,
        workPlaceId: workplaceId,
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
        description: description,
        positionId: positionId,
        isInUse: isInUse,
      });
      this.$bvModal.show("modal-personWorkplace-list-edit");
    },


    update: function () {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store.state.general.showOverlay = true;
        if (this.isEditing) {
          this.updatePersonWorkplace(this.form).then(res => {
            if (res.hasError) {
              this.showModal({
                title: "Hata!",
                message: res.errorMessage,
                type: "danger",
              });
            } else {
              this.showModal({
                title: "Başarıylı!",
                message: "İş Geçmiş Bilgisi güncellendi.",
                type: "success",
              });
              this.personWorkplaceList = res.data;
              if (this.form.personId == null || this.form.memberId == null) {
                this.form.personId = this.personWorkplaceList[0].personId;
                this.form.memberId = this.personWorkplaceList[0].memberId;
              }
              this.clearForm();
            }

          }).catch((reason) => {
            this.showModal({
              title: "Hata!",
              message: reason.toString(),
              type: "danger",
            });
          }).finally(() => {
            this.$store.state.general.showOverlay = false;
            //this.$nextTick(()=>{}); if necessary
            this.$bvModal.hide("modal-personWorkplace-list-edit");
          })
        } else {
          this.savePersonWorkplace(this.form).then(res => {
            if (res.hasError) {
              this.showModal({
                title: "Hata!",
                message: res.errorMessage,
                type: "danger",
              });
            } else {
              this.showModal({
                title: "Başarıylı!",
                message: "İş Geçmiş Bilgisi kayıt edildi.",
                type: "success",
              });
              this.personWorkplaceList = res.data;
              if (this.form.personId == null || this.form.memberId == null) {
                this.form.personId = this.personWorkplaceList[0].personId;
                this.form.memberId = this.personWorkplaceList[0].memberId;
              }
              this.clearForm();
            }
          }).catch((reason) => {
            this.showModal({
              title: "Hata!",
              message: reason.toString(),
              type: "danger",
            });
          }).finally(() => {
            this.$store.state.general.showOverlay = false;
            this.$bvModal.hide("modal-personWorkplace-list-edit");

          });

        }
        this.isEditing = false;
      }
    },
    clear() {
      this.modalTitle = "Add Workplace";
      this.$v.$reset();
    },
    addWorkplace() {
      this.$bvModal.show("modal-workplace-add");
    },
    clearForm() {
      this.isEditing = false;
      const {
        workPlaceId,
        startDate,
        endDate,
        description,
        positionId,
        isInUse,
      } = this.form;
      const clearedProps = {
        workPlaceId: null,
        startDate: null,
        endDate: null,
        description: null,
        positionId: null,
        isInUse: null,
      };
      this.form = Object.assign({}, this.form, clearedProps);
      this.$v.$reset();
    },
    format_date(value) {
      return moment(value).format("DD.MM.YYYY");
    },

  },
  watch: {
    "$store.state.associationAeo.workplace":
        function () {
          this.workplaceList = this.getterWorkplace();
          if (this.getterPositions().length) {
            this.getPersonWorkplaces(true);
          }
        },
    "$store.state.dtAeo.positions":
        function () {
          this.positionList = this.getterPositions();
          if (this.getterWorkplace().length) {
            this.getPersonWorkplaces(true);
          }
        },
  },
}
</script>
