import GeneralService from "@/services/general.service";
import MembershipAeoService from "@/services/membership.aeo.service";

const initialState = {
    memberRegistrationStates: [],
    listOfStateTypes: [false, true, true, true, true],
    tabIndex: 0,
    selectedPerson: {person: null, member: null},
}
export const membershipAeo = {
    namespaced: true,
    state: initialState,
    actions: {
        savePerson({commit}, payload) {
            return MembershipAeoService.savePerson(payload).then(res => {
                if (res.data) commit('setSelectedPerson', res.data.data);
                return Promise.resolve(res.data);
            });
        },

        savePersonUniversity({commit}, payload) {
            return MembershipAeoService.savePersonUniversity(payload).then(res => {
                return Promise.resolve(res.data);
            });
        },
        getPersonAddress({commit}, payload) {
            return MembershipAeoService.getPersonAddress(payload).then(res => {
                return Promise.resolve(res.data);
            });
        },
        savePersonAddress({commit}, payload) {
            return MembershipAeoService.savePersonAddress(payload).then(res => {
                return Promise.resolve(res.data);
            });
        },
        updatePersonAddress({commit}, payload) {
            return MembershipAeoService.updatePersonAddress(payload).then(res => {
                return Promise.resolve(res.data);
            });
        },

        getPersonNote({commit}, payload) {
            return MembershipAeoService.getPersonNote(payload).then(res => {
                return Promise.resolve(res.data);
            });
        },
        savePersonNote({commit}, payload) {
            return MembershipAeoService.savePersonNote(payload).then(res => {
                return Promise.resolve(res.data);
            });
        },
        updatePersonNote({commit}, payload) {
            return MembershipAeoService.updatePersonNote(payload).then(res => {
                return Promise.resolve(res.data);
            });
        },
        savePersonLanguage({commit}, payload) {
            return MembershipAeoService.savePersonLanguage(payload).then(res => {
                return Promise.resolve(res.data);
            });
        },
        updatePersonUniversity({commit}, payload) {
            return MembershipAeoService.updatePersonUniversity(payload).then(res => {
                return Promise.resolve(res.data);
            });
        },
        updatePersonLanguage({commit}, payload) {
            return MembershipAeoService.updatePersonLanguage(payload).then(res => {
                return Promise.resolve(res.data);
            });
        },

        updatePersonState({commit}, payload) {
            return MembershipAeoService.updatePersonState(payload).then(res => {
                return Promise.resolve(res.data);
            });
        },

        searchPersonByPersonalIdNumber({commit}, payload) {
            return MembershipAeoService.searchPersonByPersonalIdNumber(payload).then(res => {
                if (res.data) commit('setSelectedPerson', res.data.data);
                return Promise.resolve(res.data);
            });
        },

        getMemberRegistrationStates({commit}) {
            return MembershipAeoService.getMemberRegistrationStates().then(res => {
                if (res.data) commit('setMemberRegistrationStates', res.data.data);
                return Promise.resolve(res.data);
            });
        },

        getPersonUniversities({commit}, payload) {
            return MembershipAeoService.getPersonUniversities(payload).then(res => {
                return Promise.resolve(res.data);
            });
        },
        getPersonWorkplace({commit}, payload) {
            return MembershipAeoService.getPersonWorkplace(payload).then(res => {
                return Promise.resolve(res.data);
            });
        },
        savePersonWorkplace({commit}, payload) {
            return MembershipAeoService.savePersonWorkplace(payload).then(res => {
                return Promise.resolve(res.data);
            });
        },
        updatePersonWorkplace({commit}, payload) {
            return MembershipAeoService.updatePersonWorkplace(payload).then(res => {
                return Promise.resolve(res.data);
            });
        },

        getPersonLanguage({commit}, payload) {
            return MembershipAeoService.getPersonLanguage(payload).then(res => {
                return Promise.resolve(res.data);
            });
        },
        resetListOfStateTypes({commit}, payload) {
            commit('UPDATE_LIST_OF_STATE_TYPES', payload);
        },


        //Member File

        getPersonFile({commit}, payload) {
            return MembershipAeoService.getPersonFile(payload).then(res => {
                return Promise.resolve(res.data);
            });
        },
        savePersonFile({commit}, payload) {
            return MembershipAeoService.savePersonFile(payload).then(res => {
                return Promise.resolve(res.data);
            });
        },
        updatePersonFile({commit}, payload) {
            return MembershipAeoService.updatePersonFile(payload).then(res => {
                return Promise.resolve(res.data);
            });
        },

        //End Of Member File


    },
    getters: {},
    mutations: {
        setMemberRegistrationStates(state, payload) {
            state.memberRegistrationStates = Object.assign([], payload);
        },
        SET_TAB_INDEX(state, payload) {
            state.tabIndex = payload;
        },
        UPDATE_LIST_OF_STATE_TYPES(state, payload) {
            if (state.selectedPerson.member != null) {
                state.listOfStateTypes = [true, true, true, true, true, true, true];
            } else {
                state.listOfStateTypes = [true, true, true, true, true];
            }
            state.listOfStateTypes[payload] = false;
        },
        setSelectedPerson(state, payload) {
            state.selectedPerson = payload;
        }
    },
};