<template>
  <div class="standart-page">
    <b-modal
        id="modal-personUniversity-list-edit"
        size="lg"
        centered
        title="Kişi Üniversite Bilgisini Düzenle"
        ok-title="Kaydet"
        ok-variant="success"
        cancel-title="İptal"
        cancel-variant="outline-secondary"
        @ok.prevent="update"
        @hidden="clearForm">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Universite Bilgisi</label>
            <!--:class="{ 'is-invalid': $v.form.documentTypeId.$error }"-->
            <select
                :class="{ 'is-invalid': $v.form.universityId.$error }"
                v-select
                class="form-control"
                v-model="form.universityId"
            >
              <option :value="null">Seçiniz</option>
              <option
                  :value="item.id"
                  v-for="(item, index) in this.universityList"
                  :key="index"
              >
                {{ item.text }}
              </option>
            </select>
            <template v-if="$v.form.universityId.$error">
              <small class="text-danger" v-if="!$v.form.universityId.required">
                Bu alan boş bırakılamaz.
              </small>
            </template>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Bölüm / Fakülte</label>
            <input
                type="text"
                :class="{ 'is-invalid': $v.form.specialization.$error }"

                class="form-control"
                v-model="form.specialization"
                maxlength="100"
            />
            <template v-if="!this.$v.form.specialization.minLength">
              <small class="text-danger" v-if="!$v.form.specialization.minLength">
                Yetkinlik alanı adı en az 10 karakter içermelidir.</small
              >
            </template>

            <template v-if="$v.form.specialization.$error">
              <small class="text-danger" v-if="!$v.form.specialization.required">
                Bu alan boş bırakılamaz.
              </small>
            </template>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Başlangıç Tarihi</label>

            <input
                :class="{ 'is-invalid': $v.form.beginDate.$error }"
                type="date"
                class="form-control"
                v-model="form.beginDate"
                maxlength="100"
            />
            <template v-if="$v.form.beginDate.$error">
              <small class="text-danger" v-if="!$v.form.beginDate.required">
                Bu alan boş bırakılamaz.
              </small>
            </template>

          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Mezuniyet Tarihi</label>
            <input
                :class="{ 'is-invalid': $v.form.graduationDate.$error }"

                type="date"
                class="form-control"
                v-model="form.graduationDate"
                maxlength="100"
            />
            <template v-if="$v.form.graduationDate.$error">
              <small class="text-danger" v-if="!$v.form.graduationDate.required">
                Bu alan boş bırakılamaz.
              </small>
            </template>

          </div>
        </div>


      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Diploma Number</label>
            <input
                :class="{ 'is-invalid': $v.form.diplomaNumber.$error }"

                type="number"
                class="form-control"
                v-model="form.diplomaNumber"
                maxlength="100"
            />
            <template v-if="$v.form.diplomaNumber.$error">
              <small class="text-danger" v-if="!$v.form.diplomaNumber.required">
                Bu alan boş bırakılamaz.
              </small>
            </template>

          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label>Diploma Soyadı</label>
            <input
                :class="{ 'is-invalid': $v.form.diplomaSurname.$error }"

                type="text"
                class="form-control"
                v-model="form.diplomaSurname"
                maxlength="100"
            />
            <template v-if="$v.form.diplomaSurname.$error">
              <small class="text-danger" v-if="!$v.form.diplomaSurname.required">
                Bu alan boş bırakılamaz.
              </small>
            </template>
            <template v-if="!this.$v.form.diplomaSurname.minLength">
              <small class="text-danger" v-if="!$v.form.diplomaSurname.minLength">
                Diploma soy adı en az 2 karakter içermelidir.</small
              >
            </template>


          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Diploma Notu</label>
            <input
                :class="{ 'is-invalid': $v.form.diplomaGrade.$error }"

                type="text"
                class="form-control"
                v-model="form.diplomaGrade"
                maxlength="100"
            />
            <template v-if="$v.form.diplomaGrade.$error">
              <small class="text-danger" v-if="!$v.form.diplomaGrade.required">
                Bu alan boş bırakılamaz.
              </small>
            </template>


          </div>
        </div>
      </div>
    </b-modal>
    <div class="card-component mb-4">
      <div class="body">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Universite Bilgisi</label>
              <!--:class="{ 'is-invalid': $v.form.documentTypeId.$error }"-->
              <select
                  :class="{ 'is-invalid': $v.form.universityId.$error }"
                  v-select
                  class="form-control"
                  v-model="form.universityId"
              >
                <option :value="null">Seçiniz</option>
                <option
                    :value="item.id"
                    v-for="(item, index) in this.universityList"
                    :key="index"
                >
                  {{ item.text }}
                </option>
              </select>
              <template v-if="$v.form.universityId.$error">
                <small class="text-danger" v-if="!$v.form.universityId.required">
                  Bu alan boş bırakılamaz.
                </small>
              </template>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Bölüm / Fakülte</label>
              <input
                  type="text"
                  :class="{ 'is-invalid': $v.form.specialization.$error }"

                  class="form-control"
                  v-model="form.specialization"
                  maxlength="100"
              />
              <template v-if="!this.$v.form.specialization.minLength">
                <small class="text-danger" v-if="!$v.form.specialization.minLength">
                  Yetkinlik alanı adı en az 10 karakter içermelidir.</small
                >
              </template>

              <template v-if="$v.form.specialization.$error">
                <small class="text-danger" v-if="!$v.form.specialization.required">
                  Bu alan boş bırakılamaz.
                </small>
              </template>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Başlangıç Tarihi</label>

              <input
                  :class="{ 'is-invalid': $v.form.beginDate.$error }"

                  type="date"
                  class="form-control"
                  v-model="form.beginDate"
                  maxlength="100"
              />
              <template v-if="$v.form.beginDate.$error">
                <small class="text-danger" v-if="!$v.form.beginDate.required">
                  Bu alan boş bırakılamaz.
                </small>
              </template>

            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Mezuniyet Tarihi</label>
              <input
                  :class="{ 'is-invalid': $v.form.graduationDate.$error }"

                  type="date"
                  class="form-control"
                  v-model="form.graduationDate"
                  maxlength="100"
              />
              <template v-if="$v.form.graduationDate.$error">
                <small class="text-danger" v-if="!$v.form.graduationDate.required">
                  Bu alan boş bırakılamaz.
                </small>
              </template>

            </div>
          </div>


        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Diploma Number</label>
              <input
                  :class="{ 'is-invalid': $v.form.diplomaNumber.$error }"

                  type="number"
                  class="form-control"
                  v-model="form.diplomaNumber"
                  maxlength="100"
              />
              <template v-if="$v.form.diplomaNumber.$error">
                <small class="text-danger" v-if="!$v.form.diplomaNumber.required">
                  Bu alan boş bırakılamaz.
                </small>
              </template>

            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label>Diploma Soyadı</label>
              <input
                  :class="{ 'is-invalid': $v.form.diplomaSurname.$error }"

                  type="text"
                  class="form-control"
                  v-model="form.diplomaSurname"
                  maxlength="100"
              />
              <template v-if="$v.form.diplomaSurname.$error">
                <small class="text-danger" v-if="!$v.form.diplomaSurname.required">
                  Bu alan boş bırakılamaz.
                </small>
              </template>
              <template v-if="!this.$v.form.diplomaSurname.minLength">
                <small class="text-danger" v-if="!$v.form.diplomaSurname.minLength">
                  Diploma soy adı en az 2 karakter içermelidir.</small
                >
              </template>


            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Diploma Notu</label>
              <input
                  :class="{ 'is-invalid': $v.form.diplomaGrade.$error }"

                  type="text"
                  class="form-control"
                  v-model="form.diplomaGrade"
                  maxlength="100"
              />
              <template v-if="$v.form.diplomaGrade.$error">
                <small class="text-danger" v-if="!$v.form.diplomaGrade.required">
                  Bu alan boş bırakılamaz.
                </small>
              </template>


            </div>
          </div>
        </div>


        <div class="row mt-3 justify-content-end">
          <div class="col-md-4">
            <div class="form-group w-100">
              <button
                  @click="save"
                  class="btn btn-danger w-100 align-content-center ">
                Üniversite Kaydet
              </button>
            </div>
          </div>
        </div>
        <table
            v-if="this.personUniversityList.length > 0"
            class="table table-hover">
          <thead>
          <tr>
            <th class="fit">Üniversite Adı</th>
            <th class="fit">Başlangıç Tarihi</th>
            <th class="fit">Mezuniyet Tarihi</th>
            <th class="fit">Diploma Numarası</th>
            <th class="fit text-right">Notu</th>

          </tr>
          </thead>
          <tbody>
          <tr
              @click="showEditModal(item)"
              v-for="(item, index) in this.personUniversityList"
              :key="index"
              class="cursor-pointer"
          >
            <td class="fit">{{
              $store.state.dtAeo.universities.find(
              (d) => d.id === item.universityId
              ).text
              }}
            </td>
            <td class="fit">{{ format_date(item.beginDate) }}</td>
            <td class="fit">{{ format_date(item.graduationDate) }}</td>
            <td class="fit">{{ item.diplomaNumber }}</td>
            <td class="fit text-right">{{ item.diplomaGrade }}</td>
          </tr>
          </tbody>
        </table>
        <div v-if="personUniversityList.length>0" class="row mt-3 justify-content-end">
          <div class="col-md-4">
            <div class="form-group w-100">
              <button
                  @click="updatePersonRegistrationState"
                  class="btn btn-danger w-100 align-content-center">
                Bir sonraki adım'a geç
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {VueEditor} from "vue2-editor"
import {mapActions, mapGetters} from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/tr";
import moment from "moment";
import {minLength, required, requiredIf} from "vuelidate/lib/validators";

export default {
  name: "TahTesTut",
  components: {
    VueEditor,
    DatePicker,
    moment,
  },
  delimiters: ['${', '}'], // Avoid Twig conflicts
  tabIndex: 0,
  data() {
    return {
      personUniversityList: [],
      universityList: [],
      shouldDisable: true,
      form: {
        id: null,
        memberRegistrationStateTypeId: 1,
        personId: null,
        universityId: null,
        diplomaSurname: null,
        graduationDate: moment().format('YYYY-MM-DD'),
        beginDate: moment().format('YYYY-MM-DD'),
        diplomaNumber: null,
        diplomaGrade: null,
      },

    };
  },
  created() {

    if (this.getterUniversities().length === 0) {
      this.getUniversities();
    }
    else
      this.universityList = this.getterUniversities();
  },

  validations: {
    form: {
      universityId: {
        required,
      }, beginDate: {
        required,
      }, graduationDate: {
        required,
      }, diplomaSurname: {
        required,
        minLength: minLength(2)

      }, diplomaNumber: {
        required,
      }, diplomaGrade: {
        required,
      },
      specialization: {
        required,
        minLength: minLength(11)
      },

    }
  },

  methods: {
    format_date(value) {
      return moment(value).format("DD.MM.YYYY");
    },
    ...mapActions("membershipAeo", ["savePersonUniversity", "updatePersonState",
      "getPersonUniversities", "updatePersonUniversity"]),
    ...mapActions("dtAeo", ["getUniversities"]),
    ...mapGetters("dtAeo", ["getterUniversities"]),
    ...mapActions("general", ["showModal"]),

    showEditModal(item) {
      const {
        id,
        universityId,
        beginDate,
        graduationDate,
        diplomaSurname,
        diplomaNumber,
        diplomaGrade,
        specialization
      } = item;
      Object.assign(this.form, {
        id: id,
        universityId: universityId,
        specialization: specialization,
        graduationDate: moment(graduationDate).format('YYYY-MM-DD'),
        beginDate: moment(beginDate).format('YYYY-MM-DD'),
        diplomaSurname: diplomaSurname,
        diplomaNumber: diplomaNumber,
        diplomaGrade: diplomaGrade
      });
      this.$bvModal.show("modal-personUniversity-list-edit");
    },
    save: function () {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.form.memberRegistrationStateTypeId === -1) {
          this.showModal({
            title: "Hata!",
            message: "Bir sonraki aşamaya geçilemiyor!",
            type: "danger",
          });
          return;
        }
        this.$store.state.general.showOverlay = true;
        this.savePersonUniversity(this.form).then(res => {
          if (res.hasError) {
            this.showModal({
              title: "Hata!",
              message: res.errorMessage,
              type: "danger",
            });
          } else {
            this.showModal({
              title: "Başarıylı!",
              message: "Okul Bilgisi kayıt edildi.",
              type: "success",
            });
            this.personUniversityList = res.data;
            if (this.form.personId == null) {
              this.form.personId = this.personUniversityList[0].personId;
            }
            console.log(this.personUniversityList)
            this.clearForm();
          }
        }).catch((reason) => {
          this.showModal({
            title: "Hata!",
            message: reason.toString(),
            type: "danger",
          });
        }).finally(() => {
          this.$store.state.general.showOverlay = false;

        });
      }
    },
    update: function () {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store.state.general.showOverlay = true;
        this.updatePersonUniversity(this.form).then(res => {
          if (res.hasError) {
            this.showModal({
              title: "Hata!",
              message: res.errorMessage,
              type: "danger",
            });
          } else {
            this.showModal({
              title: "Başarıylı!",
              message: "Okul Bilgisi kayıt edildi.",
              type: "success",
            });
            this.personUniversityList = res.data;
            if (this.form.personId == null) {
              this.form.personId = this.personUniversityList[0].personId;
            }
            console.log(this.personUniversityList)
            this.clearForm();
          }

        }).catch((reason) => {
          this.showModal({
            title: "Hata!",
            message: reason.toString(),
            type: "danger",
          });
        }).finally(() => {
          this.$store.state.general.showOverlay = false;
          //this.$nextTick(()=>{}); if necessary
          this.$bvModal.hide("modal-personUniversity-list-edit");
        })
      }
    },
    clearForm() {
      const {universityId, beginDate, graduationDate, diplomaNumber, diplomaGrade, diplomaSurname} = this.form;
      const clearedProps = {
        universityId: null,
        beginDate: null,
        graduationDate: null,
        diplomaNumber: null,
        diplomaGrade: null,
        diplomaSurname: null
      };
      this.form = Object.assign({}, this.form, clearedProps);
      this.$v.$reset();
    },
    updatePersonRegistrationState() {
      let tabIndex = this.$store.state.membershipAeo.tabIndex;
      let index =
          this.$store.state.membershipAeo.memberRegistrationStates.findIndex(x => x.index === tabIndex + 1)
      this.form.memberRegistrationStateTypeId = this.$store.state.membershipAeo.memberRegistrationStates[index].id;

      this.updatePersonState(this.form).then(res => {
        if (!res.hasError)
          this.$emit("update-tab-index",
              this.$store.state.membershipAeo.memberRegistrationStates[index].index);
        else {
          this.showModal({
            title: "FAIL!",
            message: res.errorMessage,
            type: "danger",
          });
        }
      }).catch((reason) => {
        this.showModal({
          title: "Hata!",
          message: reason.toString(),
          type: "danger",
        });
      }).finally(() => {
        this.$store.state.general.showOverlay = false;
      });
    }

  },
  watch: {
    "$store.state.dtAeo.universities":
        function () {
          this.universityList = this.getterUniversities();
        },
    "$store.state.membershipAeo.selectedPerson":
        function () {
          if (this.$store.state.membershipAeo.selectedPerson.person != null) {
            this.form.personId = this.$store.state.membershipAeo.selectedPerson.person.id;
            this.getPersonUniversities(this.form.personId).then((res) => {
              if (res.hasError) {
                this.showModal({
                  title: "Hata!",
                  message: "Kişinin üniversite bilgileri çekilemedi. Lütfen sayfayı yenileyin",
                  type: "danger",
                });
              } else {
                this.personUniversityList = Object.assign([], res.data)
              }
            });
          }
        }
  },
}
</script>
