<template>
  <div>
    <div class="">
      <div class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid">
          <img class="navbar_logo" src="../menu/logo.jpg" alt=""/>

          <router-link to="/" tag="a" class="navbar-brand text-danger me-5" href="#">
            ANKARA ECZACI ODASI
          </router-link
          >
          <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#nav"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class=" collapse navbar-collapse" id="nav">
            <ul
                class="navbar-nav"
                v-for="data in menuJson"
            >
              <li class="nav-item dropdown me-5 fw-bold">
                <a
                    href=""
                    class="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                >{{ data.name }}</a
                >
                <ul class="dropdown-menu p-3  level_one">
                  <li v-for="data in data.menuSubItems"
                      class=" ">
                    <router-link tag="a" :to="data.menuLinkUrl" href=""
                                 class="dropdown-item mb-2 fw-bold level_one_item">{{
                        data.name
                      }}
                    </router-link>
                  </li>
                  <li v-for="data in data.subMenu">
                    <a

                        href=""
                        class="dropdown-item mb-2 fw-bold dropdown-toggle
                        level_one_item"
                    >{{ data.name }}</a
                    >
                    <ul class="dropdown-menu submenu">
                      <li
                          v-for="data in data.menuSubItems"

                      >
                        <router-link :to="data.menuLinkUrl" href="" class="dropdown-item">{{ data.name }}</router-link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <div class="d-flex align-items-center justify-content-end">
          <button class="btn btn-danger me-3" @click="logOut">Logout</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuJson: null,
      showClass: "d-none",
      dropdown1: false,
      dropdown2: false,
    };
  },
  methods: {
    logOut(){
      console.log("anan")
      localStorage.removeItem('token');
      location.reload();

    }
  },
  created() {
    this.menuJson = JSON.parse(localStorage.getItem('allAuthorizedMenu'))
  },
  watch: {
    "$store.state.auth.menu":
        function () {
          this.menuJson = JSON.parse(localStorage.getItem('allAuthorizedMenu'))
        },
  }
};
</script>

<style scoped>

.level_one_item:hover {
  transform: translate(5px);
  transition: all .2s ease-in-out;
  background-color: #fff;
}

.level_one {
  border-left: 3px solid rgb(136, 136, 136);
  border-radius: 5px;
  padding-left: 15px;
  transform: translate(0px, 10px);
}

.level_one:hover {
  border-left: 3px solid #c51e2f;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.nav-item {
  border-bottom: 2px solid #f8f8f8;
  font-size: 1.1rem;
}

.nav-item:hover {
  border-bottom: 2px solid #c51e2f;
  color: #c51e2f;
  transition: all 1.2s;
}

.navbar_logo {
  width: 50px;
  margin-right: 5px;
}

.dropdown-menu li {
  position: relative;
}

.dropdown-menu .submenu {
  display: none;
  position: absolute;
  left: 110%;
  top: -5px;
}

.dropdown-menu > li:hover > .submenu {
  display: block;
}

@media (max-width: 992px) {
  .dropdown-menu .submenu {
    left: 0;
    top: 20px;
  }
}
</style>
