<template>
  <div class="standart-page">
    <div class="card-component mb-4">
      <div class="body">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Text</label>
              <!--:class="{ 'is-invalid': $v.form.text.$error }"-->
              <input
                  type="text"
                  class="form-control"
                  v-model="form.text"
                  maxlength="50"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Description</label>
              <!--:class="{ 'is-invalid': $v.form.description.$error }"-->
              <input
                  type="text"
                  class="form-control"
                  v-model="form.description"
                  maxlength="50"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">

            <div class="form-group">
              <label>Color Code</label>
              <slider-picker v-model="form.colorCode"/>
            </div>

          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Komşu 1. Bölge</label>
              <!--:class="{ 'is-invalid': $v.form.description.$error }"-->
              <select
                  v-select
                  class="form-control"
                  v-model="form.neighbor1RegionId"
              >
                <option :value="null">Seçiniz</option>
                <option
                    :value="item.id"
                    v-for="(item, index) in regionList.filter(item => (item.id !== form.neighbor2RegionId && item.id !== form.id ))"
                    :key="index"
                >
                  {{ item.text }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Komşu 2. Bölge</label>
              <!--:class="{ 'is-invalid': $v.form.description.$error }"-->
              <select
                  v-select
                  class="form-control"
                  v-model="form.neighbor2RegionId"
              >
                <option :value="null">Seçiniz</option>
                <option
                    :value="item.id"
                    v-for="(item, index) in regionList.filter(item => (item.id !== form.neighbor1RegionId && item.id !== form.id ))"
                    :key="index"
                >
                  {{ item.text }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row mt-3 justify-content-end">
          <div class="col-md-4">
            <div class="form-group w-100">
              <button
                  @click="save"
                  class="btn btn-danger w-100 align-content-center">
                Bölge Kaydet
              </button>
            </div>
          </div>
        </div>
        <table
            v-if="this.regionList.length > 0"
            class="table table-hover">
          <thead>
          <tr>
            <th class="fit">Bölge Adı</th>
            <th class="fit">Komşu Bölge 1</th>
            <th class="fit">Komşu Bölge 2</th>
            <th class="fit">Komşu Bölge 2</th>
          </tr>
          </thead>
          <tbody>
          <tr
              @click="showEditModal(item)"
              v-for="(item, index) in this.regionList"
              :key="index"
              class="cursor-pointer"
          >
            <td>{{format_date(item.created_at)}}</td>
            <td>{{item.text}}</td>

          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

import {VueEditor} from "vue2-editor"
import {mapActions,mapGetters} from "vuex";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/tr";
import moment from "moment";
import {minLength, required, requiredIf} from "vuelidate/lib/validators";

export default {
  name: "TahTesTut",
  components: {
    VueEditor,
    DatePicker,
    moment,
  },
  delimiters: ['${', '}'], // Avoid Twig conflicts
  tabIndex: 0,
  data() {
    return {
      regionList: [],

      form: {
        id: null,
        personId: null,
        memberRegistrationStateTypeId: 0,
        text: null,
        description: null,
        colorCode: '#194D33A8',
        neighbor1RegionId:null,
        neighbor2RegionId:null,

      },

    };
  },
  created() {
  },

  validations: {
    form: {
      text: {
        required,
        minLength: minLength(5),
      }
    }
  },

  methods: {
    format_date(value) {
      return moment(value).format("DD.MM.YYYY");
    },
    ...mapActions("membershipAeo", ["updatePersonState", "getPersonNote",
      "updatePersonNote", "savePersonNote"]),


    ...mapActions("associationAeo", ["getRegions"]),
    ...mapGetters("associationAeo", ["getterRegions"]),

    ...mapActions("general", ["showModal"]),

    showEditModal(item) {
      const {
        id,
        text,
      } = item;
      Object.assign(this.form, {
        id: id,
        text: text,
      });
      this.$bvModal.show("modal-personNote-list-edit");
    },
    save: function () {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.form.memberRegistrationStateTypeId === -1) {
          this.showModal({
            title: "Hata!",
            message: "Bir sonraki aşamaya geçilemiyor!",
            type: "danger",
          });
          return;
        }
        this.$store.state.general.showOverlay = true;
        this.savePersonNote(this.form).then(res => {
          if (res.hasError) {
            this.showModal({
              title: "Hata!",
              message: res.errorMessage,
              type: "danger",
            });
          } else {
            this.showModal({
              title: "Başarıylı!",
              message: "Dil Bilgisi kayıt edildi.",
              type: "success",
            });
            this.personNoteList = res.data;
            if (this.form.personId == null) {
              this.form.personId = this.personNoteList[0].personId;
            }
            this.clearForm();
          }
        }).catch((reason) => {
          this.showModal({
            title: "Hata!",
            message: reason.toString(),
            type: "danger",
          });
        }).finally(() => {
          this.$store.state.general.showOverlay = false;

        });
      }
    },
    update: function () {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store.state.general.showOverlay = true;
        this.updatePersonNote(this.form).then(res => {
          if (res.hasError) {
            this.showModal({
              title: "Hata!",
              message: res.errorMessage,
              type: "danger",
            });
          } else {
            this.showModal({
              title: "Başarıylı!",
              message: "Not Bilgisi güncellendi.",
              type: "success",
            });
            this.personNoteList = res.data;
            if (this.form.personId == null) {
              this.form.personId = this.personNoteList[0].personId;
            }
            this.clearForm();
          }

        }).catch((reason) => {
          this.showModal({
            title: "Hata!",
            message: reason.toString(),
            type: "danger",
          });
        }).finally(() => {
          this.$store.state.general.showOverlay = false;
          //this.$nextTick(()=>{}); if necessary
          this.$bvModal.hide("modal-personNote-list-edit");
        })
      }
    },
    clearForm() {
      const {text} = this.form;
      const clearedProps = {
        text: null,
      };
      this.form = Object.assign({}, this.form, clearedProps);
      this.$v.$reset();
    },

  },
  watch: {
    "$store.state.membershipAeo.selectedPerson":
        function () {
          this.form.personId = this.$store.state.membershipAeo.selectedPerson.person.id;
          this.getPersonNote(this.form.personId).then((res) => {
            if (res.hasError) {
              this.showModal({
                title: "Hata!",
                message: "Kişinin not bilgileri çekilemedi. Lütfen sayfayı yenileyin",
                type: "danger",
              });
            } else {
              this.personNoteList = Object.assign([], res.data)
            }
          });
        },

  },
}
</script>
