<template>
  <div className="d-content">
    <img src="@/assets/img/icons/budget-offers-active.png" alt="" className="cursor-pointer"
         :class="{ 'disabled': disabled }" @click="handleIconClick">
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    handleIconClick() {
      // Emit a custom event to the parent component only if the component is not disabled
      if (!this.disabled) {
        this.$emit('iconClicked');
      }
    }
  }
}
</script>

<style scoped>
.disabled {
  pointer-events: none; /* Disables pointer events on the image */
  opacity: 0.5; /* Optionally, you can apply a visual style to indicate the disabled state */
}
</style>
