<template>
  <div class="dashboard">
    <div
        class="standart-page d-flex flex-fill align-items-center justify-content-center flex-column"
    >
      <div
          class="col-md-6 bg-light p-5 d-flex justify-content-center align-items-center"
      >
        <div class="">
          <h1 class="text-center">Ankara Eczacı Odası</h1>
          <br/>
          <br/>
          <h3 class="text-center">
            Web Sitesi Yönetim Otomasyonuna
            <br/>
            Hoşgeldiniz.
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {general} from "@/store/general.module";

export default {
  name: "Home",
  components: {},
  methods: {
    ...mapActions("general", ["whatIsMyDuty"])
  },
  created() {
    this.$store.state.general.showMenu = true;
    this.$store.state.general.showOverlay = true;

    //TODO: Redirect user to its duty associated with user

    this.whatIsMyDuty();


  },
  watch: {
    "$store.state.general.userDetail":
        function () {
          let userDetail = this.$store.state.general.userDetail;
          if (userDetail.directionUrl != null) {
            this.$router.push(userDetail.directionUrl);
          }
        }
  }
};
</script>
