<template>
  <div>
    <b-modal
        id="modal-personLanguage-list-edit"
        size="lg"
        centered
        title="Kişi Dil Bilgisini Düzenle"
        ok-title="Kaydet"
        ok-variant="success"
        cancel-title="İptal"
        cancel-variant="outline-secondary"
        @ok.prevent="update"
        @hidden="clearForm">
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label>Dil Bilgisi</label>
            <!--:class="{ 'is-invalid': $v.form.documentTypeId.$error }"-->
            <select
                :class="{ 'is-invalid': $v.form.languageId.$error }"
                v-select
                class="form-control"
                v-model="form.languageId"
            >
              <option :value="null">Seçiniz</option>
              <option
                  :value="item.id"
                  v-for="(item, index) in this.languageList"
                  :key="index"
              >
                {{ item.text }}
              </option>
            </select>
            <template v-if="$v.form.languageId.$error">
              <small class="text-danger" v-if="!$v.form.languageId.required">
                Bu alan boş bırakılamaz.
              </small>
            </template>
          </div>
        </div>
        <div class="col-md-5">
          <div class="form-group">
            <label>Yetkinlik Seviyesi</label>
            <!--:class="{ 'is-invalid': $v.form.documentTypeId.$error }"-->
            <select
                :class="{ 'is-invalid': $v.form.languageLevelId.$error }"
                v-select
                class="form-control"
                v-model="form.languageLevelId"
            >
              <option :value="null">Seçiniz</option>
              <option
                  :value="item.id"
                  v-for="(item, index) in this.languageLevelList"
                  :key="index"
              >
                {{ item.text }}
              </option>
            </select>
            <template v-if="$v.form.languageLevelId.$error">
              <small class="text-danger" v-if="!$v.form.languageLevelId.required">
                Bu alan boş bırakılamaz.
              </small>
            </template>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label class="d-block">Aktif</label>
            <label class="label_switch">
              <input type="checkbox" v-model="form.isInUse"/>
              <span class="mr-2"></span>
            </label>
          </div>
        </div>

      </div>
    </b-modal>
    <table
        v-if="this.personLanguageList.length > 0"
        class="table table-hover">
      <thead>
      <tr>
        <th class="fit">Dil Adı</th>
        <th class="fit">Yetkinlik Seviyesi</th>
        <th class="fit"></th>
        <th class="fit"></th>
        <th class="fit"></th>
        <th class="fit text-right">Aktif</th>
        <th class="fit text-right">
          <PlusCircleIcon v-b-modal.modal-personLanguage-list-edit/>
        </th>
        <th class="fit text-right">
          <ShowHistoryIcon @iconClicked="showHistoryClicked" :disabled="disableHistoryButton"/>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
          @click="showEditModal(item)"
          v-for="(item, index) in this.personLanguageList"
          :key="index"
          class="cursor-pointer"
      >
        <td class="fit">{{
          $store.state.dtAeo.language.find(
          (d) => d.id === item.languageId
          ).text
          }}
        </td>
        <td class="fit">{{
          $store.state.dtAeo.languageLevel.find(
          (d) => d.id === item.languageLevelId
          ).text
          }}
        </td>
        <td class="fit"></td>
        <td class="fit"></td>
        <td class="fit"></td>
        <td class="fit text-right">
          <font-awesome-icon icon="check" size="lg" class="text-danger" v-if="item.isInUse"/>
          <font-awesome-icon icon="ban" size="lg" class="text-danger" v-else/>
        </td>
        <td class="fit text-right"></td>
        <td class="fit text-right"></td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {VueEditor} from "vue2-editor"
import {mapActions, mapGetters} from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/tr";
import moment from "moment";
import {minLength, required, requiredIf} from "vuelidate/lib/validators";
import PlusCircleIcon from "@/components/PlusCircleIcon.vue";
import ShowHistoryIcon from "@/components/ShowHistoryIcon.vue";

export default {
  name: "TahTesTut",
  components: {
    ShowHistoryIcon,
    PlusCircleIcon,
    VueEditor,
    DatePicker,
    moment,
  },
  delimiters: ['${', '}'], // Avoid Twig conflicts
  tabIndex: 0,
  data() {
    return {
      prevIsInUse: true,
      isEditing: false,
      disableHistoryButton: false,
      personLanguageList: [],
      languageLevelList: [],
      languageList: [],
      form: {
        id: null,
        personId: null,
        memberRegistrationStateTypeId: 0,
        languageLevelId: null,
        languageId: null,
        isInuse: null,
      },

    };
  },
  created() {


    this.languageLevelList = this.getterLanguageLevels().length === 0
        ? this.getLanguageLevel()
        : this.getterLanguageLevels();

    this.languageList = this.getterLanguages().length === 0
        ? this.getLanguage()
        : this.getterLanguages();

    if (this.getterLanguageLevels().length && this.getterLanguages().length) {
      this.getPersonLanguages(true);
    }
  },

  validations: {
    form: {
      languageId: {
        required,
      }, languageLevelId: {
        required,
      }
    }
  },

  methods: {
    showHistoryClicked() {
      this.prevIsInUse = !this.prevIsInUse;
      this.getPersonLanguages(this.prevIsInUse)
    },
    ...mapActions("membershipAeo", ["updatePersonState", "getPersonLanguage",
      "updatePersonLanguage", "savePersonLanguage"]),
    ...mapActions("dtAeo", ["getLanguage", "getLanguageLevel"]),
    ...mapGetters("dtAeo", ["getterLanguages", "getterLanguageLevels"]),
    ...mapActions("general", ["showModal"]),

    showEditModal(item) {
      this.isEditing = true;
      const {
        id,
        languageLevelId,
        languageId,
        isInUse,
      } = item;
      Object.assign(this.form, {
        id: id,
        languageLevelId: languageLevelId,
        languageId: languageId,
        isInUse: isInUse,
      });
      this.$bvModal.show("modal-personLanguage-list-edit");
    },
    getPersonLanguages(isInUse) {
      this.disableHistoryButton = !this.disableHistoryButton
      this.$store.state.general.showOverlay = true;
      let payload = {
        isInUse: isInUse,
        personId: this.$route.params.personId
      };
      this.getPersonLanguage(payload).then(res => {
        if (res.hasError) {
          this.showModal({
            title: "Hata!",
            message: res.errorMessage,
            type: "danger",
          });
        } else {
          if (res.data.length > 0) {
            this.personLanguageList = res.data;
            if (this.form.personId == null) {
              this.form.personId = this.personLanguageList[0].personId;
            }
          } else {
            this.prevIsInUse = !this.prevIsInUse;
            this.showModal({
              title: "Uyarı!",
              message: "Kayıt bulunamadı.",
              type: "danger",
            });
          }
        }
      }).catch((reason) => {
        this.showModal({
          title: "Hata!",
          message: reason.toString(),
          type: "danger",
        });
      }).finally(() => {
        this.$store.state.general.showOverlay = false;
        this.disableHistoryButton = !this.disableHistoryButton
      });
    },
    update: function () {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store.state.general.showOverlay = true;
        if (this.isEditing) {
          this.updatePersonLanguage(this.form).then(res => {
            if (res.hasError) {
              this.showModal({
                title: "Hata!",
                message: res.errorMessage,
                type: "danger",
              });
            } else {
              this.showModal({
                title: "Başarıylı!",
                message: "Dil Bilgisi güncellendi.",
                type: "success",
              });
              this.personLanguageList = res.data;
              if (this.form.personId == null) {
                this.form.personId = this.personLanguageList[0].personId;
              }
              this.clearForm();
            }

          }).catch((reason) => {
            this.showModal({
              title: "Hata!",
              message: reason.toString(),
              type: "danger",
            });
          }).finally(() => {
            this.$store.state.general.showOverlay = false;
            //this.$nextTick(()=>{}); if necessary
            this.$bvModal.hide("modal-personLanguage-list-edit");
          })
        } else {
          this.savePersonLanguage(this.form).then(res => {
            if (res.hasError) {
              this.showModal({
                title: "Hata!",
                message: res.errorMessage,
                type: "danger",
              });
            } else {
              this.showModal({
                title: "Başarıylı!",
                message: "Dil Bilgisi kayıt edildi.",
                type: "success",
              });
              this.personLanguageList = res.data;
              if (this.form.personId == null) {
                this.form.personId = this.personLanguageList[0].personId;
              }
            }
          }).catch((reason) => {
            this.showModal({
              title: "Hata!",
              message: reason.toString(),
              type: "danger",
            });
          }).finally(() => {
            this.$store.state.general.showOverlay = false;
            this.$bvModal.hide("modal-personLanguage-list-edit");
          });

        }
        this.isEditing = false;


      }
    },
    clearForm() {
      this.isEditing = false;
      const {languageLevelId, languageId, isInUse} = this.form;
      const clearedProps = {
        languageLevelId: null,
        languageId: null,
        isInUse: null,
      };
      this.form = Object.assign({}, this.form, clearedProps);
      this.$v.$reset();
    },

  },
  watch: {
    "$store.state.dtAeo.language":
        function () {
          this.languageList = this.getterLanguages();
          if (this.getterLanguageLevels().length && this.getterLanguages().length) {
            this.getPersonLanguages(true);
          }
        },
    "$store.state.dtAeo.languageLevel":
        function () {
          this.languageLevelList = this.getterLanguageLevels();
          if (this.getterLanguageLevels().length && this.getterLanguages().length) {
            this.getPersonLanguages(true);
          }
        },
  },
}
</script>
