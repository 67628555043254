var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"standart-page"},[_c('b-card',{staticStyle:{"background":"unset"},attrs:{"no-body":""}},[_c('b-tabs',{attrs:{"card":"","nav-class":"custom top col-12 mx-auto","align":"center","justified":""},model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},[_c('b-tab',{attrs:{"disabled":_vm.$store.state.membershipAeo.
        listOfStateTypes[0],"title":"Özet Bilgiler"}},[_c('MemberPerson',{on:{"update-tab-index":_vm.handleUpdateTabIndex}})],1),_c('b-tab',{attrs:{"disabled":_vm.$store.state.membershipAeo.
        listOfStateTypes[1],"title":"Eğitim Bilgisi"}},[_c('MemberPersonUniversity',{on:{"update-tab-index":_vm.handleUpdateTabIndex}})],1),_c('b-tab',{attrs:{"disabled":_vm.$store.state.membershipAeo.
        listOfStateTypes[2],"title":"Dil Bilgisi"}},[_c('MemberPersonLanguage',{on:{"update-tab-index":_vm.handleUpdateTabIndex}})],1),_c('b-tab',{attrs:{"disabled":_vm.$store.state.membershipAeo.
        listOfStateTypes[3],"title":"Adres Bilgisi"}},[_c('MemberPersonAddress',{on:{"update-tab-index":_vm.handleUpdateTabIndex}})],1),_c('b-tab',{attrs:{"disabled":_vm.$store.state.membershipAeo.
        listOfStateTypes[4],"title":"Not"}},[_c('MemberPersonNote',{on:{"update-tab-index":_vm.handleUpdateTabIndex}})],1),_c('b-tab',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.membershipAeo.listOfStateTypes.length > 5),expression:"$store.state.membershipAeo.listOfStateTypes.length > 5"}],attrs:{"disabled":_vm.$store.state.membershipAeo.
        listOfStateTypes[5],"title":"Üye İşyerleri"}},[_c('MemberWorkplace',{on:{"update-tab-index":_vm.handleUpdateTabIndex}})],1),_c('b-tab',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.membershipAeo.listOfStateTypes.length > 5),expression:"$store.state.membershipAeo.listOfStateTypes.length > 5"}],attrs:{"disabled":_vm.$store.state.membershipAeo.
        listOfStateTypes[6],"title":"Üye Dosyaları"}},[_c('MemberFile',{on:{"update-tab-index":_vm.handleUpdateTabIndex}})],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }