import axios from "axios";
import authHeader from "@/services/auth-header";

class AssociationAeoService {
    savePerson(payload) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Person/save-person`,
            {
                Text: payload.text,
                Description: payload.description,
                ColorCode: payload.colorCode.hex,
                Neighbor1RegionId: payload.neighbor1RegionId,
                Neighbor2RegionId: payload.neighbor2RegionId,
                orderNo: payload.orderNo,
                DutyRepeatPeriod: payload.dutyRepeatPeriod,
                DutyAlgorithmTypeId: payload.dutyAlgorithmTypeId,
                MarkerUrl: payload.markerUrl,
                IsCityCenter: payload.isCityCenter,
                TebRegionId: payload.tebRegionId
            }, {
                headers: authHeader()
            });
    }


    getPersonAddress(payload) {
        return axios.get(`${process.env.VUE_APP_PORTAL_API_URL}Person/get-person-address?personId=` + payload,
            {
                headers: authHeader()
            });
    }

    getWorkplaceStatus() {
        return axios.get(`${process.env.VUE_APP_PORTAL_API_URL}WorkplaceStatus/get-all`,
            {
                headers: authHeader()
            });
    }

    getDutyStatus() {
        return axios.get(`${process.env.VUE_APP_PORTAL_API_URL}DutyStatus/get-all`,
            {
                headers: authHeader()
            });
    }

    getRegions() {
        return axios.get(`${process.env.VUE_APP_PORTAL_API_URL}Region/get-all`,
            {
                headers: authHeader()
            });
    }

    getWorkplaceType() {
        return axios.get(`${process.env.VUE_APP_PORTAL_API_URL}WorkplaceType/get-all`,
            {
                headers: authHeader()
            });
    }

    getWorkplace() {
        return axios.get(`${process.env.VUE_APP_PORTAL_API_URL}Workplace/get-all`,
            {
                headers: authHeader()
            });
    }


    updatePersonAddress(payload) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Person/update-person-address`,
            {
                Id: payload.id,
                Text: payload.text,
                Description: payload.description,
                ColorCode: payload.colorCode.hex,
                Neighbor1RegionId: payload.neighbor1RegionId,
                Neighbor2RegionId: payload.neighbor2RegionId,
                orderNo: payload.orderNo,
                DutyRepeatPeriod: payload.dutyRepeatPeriod,
                DutyAlgorithmTypeId: payload.dutyAlgorithmTypeId,
                MarkerUrl: payload.markerUrl,
                IsCityCenter: payload.isCityCenter,
                TebRegionId: payload.tebRegionId
            }, {
                headers: authHeader()
            });

    }


    addWorkplace(payload) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Workplace/add`,
            {
                UpId: payload.upId,
                TopId: payload.topId,
                Name: payload.name,
                Address: payload.address,
                AddressDescription: payload.addressDescription,
                Phone: payload.phone,
                Email: payload.email,
                WorkplaceTypeId: payload.workplaceTypeId,
                WorkplaceStatusId: payload.workplaceStatusId,
                RegionId: payload.regionId,
                DutyCode: payload.dutyCode,
                DutyStatusId: payload.dutyStatusId,
                DistrictId: payload.districtId,
                CityId: payload.cityId,
                Lat: payload.lat,
                Lng: payload.lng
            }, {
                headers: authHeader()
            });
    }

    getPersonDetail(payload) {
        return axios.get(`${process.env.VUE_APP_PORTAL_API_URL}Person/get-person?personId=` + payload,
            {
                headers: authHeader()
            });
    }

    getMemberDetail(payload) {
        return axios.get(`${process.env.VUE_APP_PORTAL_API_URL}Person/get-person-member?personId=` + payload,
            {
                headers: authHeader()
            });
    }

    updatePerson(payload) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Person/update-personal-information-detail`,
            {
                Id: payload.id,
                UserId: payload.userId,
                TitleId: payload.titleId,
                PersonelIdNumber: payload.personelIdNumber,
                GenderId: payload.genderId,
                Name: payload.name,
                Surname: payload.surName,
                Username: payload.username,
                OtherName: payload.otherName,
                BirthDate: payload.birthDate,
                MemberRegistrationStateTypeId: payload.memberRegistrationStateTypeId,
                Cellular: payload.cellular,
                EMail: payload.eMail,
                MembershipStartDate: payload.aeoRegisterDate,
                MembershipNumber: payload.aeoRegisterNumber,
                IsCurrent: true,
                IsInUse: true,
                CreatedAt: payload.CreatedAt,
                CreatedBy: payload.CreatedBy,
                RecordId: payload.RecordId,
            }, {
                headers: authHeader()
            });
    }
}

export default new AssociationAeoService();
