<template>
  <div class="standart-page">
    <div class="row">
      <div class="col-md-12"><h3 class="title">Çarşaf Liste Bastır</h3></div>
    </div>
    <div class="card-component mb-4">
      <div class="body">
        <div class="row">
          <div class="col-md-12">
            <form action="" id="form1" name="form1">
              <table class="table">
                <tbody>
                  <tr class="row">
                    <td
                      class="col-md-4 col-lg-3 col-xl-2"
                      v-for="data in Bolgeler"
                      :key="data"
                    >
                      <div class="radio border p-3 text-center">
                        <input type="radio" name="bolgeRadio" id="bolgeRadio" />
                        <label for="bolgeRadio" class="fw-bold d-inline">
                          {{ data.title }}
                        </label>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label>Başlangıç ve Bitiş Tarihi</label>
              <date-picker
                :popup-style="{ top: '100%', left: 0 }"
                :append-to-body="false"
                v-model="form.dateInterval"
                format="DD.MM.YYYY"
                range
                type="date"
                valueType="format"
                :open.sync="open"
              ></date-picker>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Çalışma Zamanları</label>
              <multiselect
                :multiple="true"
                group-values="childSubjectTypes"
                selectLabel="Sadece bu itemi seçmek için tıkla"
                selectGroupLabel="Bu grubun tamamını seçmek için tıkla"
                selectedLabel="Seçili"
                @open="openSubjectType()"
                deselectLabel="Bu itemi silmek için tıkla"
                deselectGroupLabel="Bu grubun tamamını silmek için tıkla"
                group-label="parentSubjectType"
                :group-select="true"
                placeholder="Konu Ara"
                track-by="text"
                label="text"
                ><span slot="noResult"
                  >Aradığın kriterlere uygun kayıt bulunamadı.</span
                >
              </multiselect>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <button class="btn btn-secondary">Çarşaf Liste Hazırla</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { VueEditor } from "vue2-editor";
import { mapActions } from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/tr";
import moment from "moment";
import Bolgeler from "../../bolgeler.json";

export default {
  name: "BorcTakip",
  components: {
    VueEditor,
    DatePicker,
    moment,
  },
  delimiters: ["${", "}"], // Avoid Twig conflicts
  data() {
    return {
      Bolgeler: Bolgeler.data,
      images: [],
      index: null,
      open: false,
      day_difference: null,

      announcement_head_pic_file_local: null,
      form: {
        announcement_title: null,
        announcement_html:
          "<h4>Aşağıdaki önizleme butonuna basarsan, burada yazılanların duyurun onaylandığında nasıl gözükeceklerini görebilirsin.</h4>",
        announcement_head_pic: null,
        announcement_head_pic_file: null,
        announcement_start_date: null,
        announcement_type: null,
        subjectGroups: [],
        publishPlaces: [],
        announcement_charged_person: null,
      },
    };
  },
  created() {
    this.getPageType();
    this.getPublishPlace();
    this.$store.dispatch("general/changeMenu", "CrisisMenu");
  },
  methods: {
    customLabel({ options_grouping }) {
      return `${options_grouping.lang}`;
    },

    openPublishPlaces() {
      if (this.$store.state.dtAeo.publishPlaces.length === 0) {
        this.getPublishPlace();
      }
    },

    openSubjectType() {
      if (this.$store.state.dtAeo.subjectTypeGrouped.length === 0) {
        this.getSubjectTypeGrouped();
      }
    },

    Up(item) {
      //TODO: Böyle bir workaround yok. :)
      let test = Object.assign([], this.images);
      let item0 = test.findIndex((s) => s.fileName === item.fileName);
      if (item0 != null) {
        let temp = test[item0 - 1]; //1 üsttekinin item'ını aldık.
        test[item0 - 1] = item; //1 üste 1 aşağısındakini koyduk
        test[item0] = temp;
        this.images = Object.assign([], test);
      }
    },
    Down(item) {
      //TODO: Böyle bir workaround yok. :)
      let test = Object.assign([], this.images);
      let item0 = test.findIndex((s) => s.fileName === item.fileName);
      if (item0 != null) {
        let temp = test[item0 + 1]; //1 üsttekinin item'ını aldık.
        test[item0 + 1] = item; //1 üste 1 aşağısındakini koyduk
        test[item0] = temp;
        this.images = Object.assign([], test);
      }
    },
    ...mapActions("general", ["showModal"]),
    ...mapActions("dtAeo", [
      "getPublishPlace",
      "getSubjectTypeGrouped",
      "getPageType",
    ]),

    onChangeFileUpload(e, isMultiple) {
      // console.log(isMultiple)
      if (isMultiple) {
        const tmpFiles = e.target.files;
        //console.log(tmpFiles)
        for (let f of tmpFiles) {
          // console.log(f);
          if (f.name.match(/.(jpg|jpeg|png)$/i)) {
            let obj = {
              file: f,
              fileName: f.name,
              path: URL.createObjectURL(f),
            };
            this.images.push(obj);
          }
        }
      } else {
        if (!this.$refs.sfile.files[0].name.match(/.(jpg|jpeg|png)$/i)) {
          this.showModal({
            title: "Hata!",
            message:
              "Sadece *.jpeg |  *.jpg | *.png uzantılı dosyalar yükleyebilirsin.",
            type: "danger",
          });
          this.announcement_head_pic_file_local = null;
        } else {
          this.form.announcement_head_pic_file = this.$refs.sfile.files[0];
          this.form.announcement_head_pic = this.$refs.sfile.files[0].name;
        }
      }
    },
    handleChange(value) {
      if (value[0] == null) {
        this.day_difference = null;
        return;
      }
      let finish_day = moment(String(value[1]), "DD.MM.YYYY");
      let start_day = moment(String(value[0]), "DD.MM.YYYY");
      this.day_difference = Math.ceil(
        (finish_day - start_day) / (1000 * 60 * 60 * 24)
      );
    },

    onChange(tmpFiles) {
      for (let f of tmpFiles) {
        // console.log(f);
        if (f.name.match(/.(jpg|jpeg|png)$/i)) {
          let obj = {
            file: f,
            fileName: f.name,
            path: URL.createObjectURL(f),
          };
          this.images.push(obj);
        }
      }
    },

    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.mfile.files = event.dataTransfer.files;
      this.onChange(this.$refs.mfile.files); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
  },
};
</script>

<style scoped>
.radio:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
</style>
