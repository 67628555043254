<template>
  <div>
    <b-modal
        id="modal-personFile-list-edit"
        size="lg"
        centered
        title="Üye Dosya Bilgisini Düzenle"
        ok-title="Kaydet"
        ok-variant="success"
        cancel-title="İptal"
        cancel-variant="outline-secondary"
        @ok.prevent="update"
        @hidden="clearForm">
      <div v-if="!this.isEditing" class="row">
        <div class="col-md">
          <div class="form-group">
            <label>Açıklama</label>
            <input
                :class="{ 'is-invalid': $v.form.description.$error }"
                type="text"
                class="form-control"
                v-model="form.description"
                maxlength="100"
            />
            <template v-if="$v.form.description.$error">
              <small
                  class="text-danger"
                  v-if="!$v.form.description.requiredIf"
              >Dosya yüklendiği için bu alan zorunludur.</small
              >
            </template>
          </div>
        </div>
      </div>
      <div v-if="!this.isEditing" class="row mb-3">
        <div class="col-md-3">
          <input
              type="file"
              ref="sfile"
              class="d-none"
              @change="onChangeFileUpload($event, false)"
          />
          <button
              class="btn btn-secondary w-100"
              @click="$refs.sfile.click()"
          >
            Fotoğraf Seç
          </button>
        </div>
        <div class="col-md-9">
          <div class="form-group">
            <input
                type="text"
                class="form-control"
                disabled
                v-model="form.fileName"
            />
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div v-if="!this.isEditing" class="col-md-4">
          <div class="form-group w-100">
            <button
                @click="update"
                class="btn btn-danger w-100 align-content-center">
              Dosyayı Kaydet
            </button>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label class="d-block">Aktif</label>
            <label class="label_switch">
              <input type="checkbox" v-model="form.isInUse"/>
              <span class="mr-2"></span>
            </label>
          </div>
        </div>

      </div>
    </b-modal>

    <table
        v-if="this.personFileList.length > 0"
        class="table table-hover">
      <thead>
      <tr>
        <th class="fit">Description</th>
        <th class="fit">Dosya İsmi</th>
        <th class="fit">İndir</th>
        <th class="fit text-right">Aktif</th>
        <th class="fit text-right">
          <PlusCircleIcon v-b-modal.modal-personFile-list-edit/>
        </th>
        <th class="fit text-right">
          <ShowHistoryIcon @iconClicked="showHistoryClicked" :disabled="disableHistoryButton"/>
        </th>

      </tr>
      </thead>
      <tbody>
      <tr
          @click="showEditModal(item)"
          v-for="(item, index) in this.personFileList"
          :key="index"
          class="cursor-pointer"
      >
        <td class="fit">{{ item.description }}</td>
        <td class="fit">{{ item.fileExtension }}</td>
        <td class="fit"> indir</td>
        <td class="fit text-right">
          <font-awesome-icon icon="check" size="lg" class="text-danger" v-if="item.isInUse"/>
          <font-awesome-icon icon="ban" size="lg" class="text-danger" v-else/>
        </td>
        <td class="fit text-right"></td>
        <td class="fit text-right"></td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {requiredIf} from "vuelidate/lib/validators";
import {mapActions} from "vuex";
import PlusCircleIcon from "@/components/PlusCircleIcon.vue";
import ShowHistoryIcon from "@/components/ShowHistoryIcon.vue";

export default {
  props: {
    memberId: {
      required: true
    }
  },
  name: "MemberDetail_File",
  components: {ShowHistoryIcon, PlusCircleIcon},
  delimiters: ['${', '}'], // Avoid Twig conflicts
  tabIndex: 0,
  data() {
    return {
      personFileList: [],
      prevIsInUse: true,
      isEditing: false,
      disableHistoryButton: false,
      form: {
        id: null,
        personId: null,
        memberRegistrationStateTypeId: 0,
        memberId: null,
        file: null,
        fileName: null,
        description: null,
        isInUse: false,
      }
    };
  },

  validations: {
    form: {
      description: {
        requiredIf: requiredIf(function (form) {
          return form.file !== null;
        }),
      }
    }
  },
  created() {
    this.form.memberId = this.memberId;
    console.log("this.form.memberId")
    this.getPersonFiles(true);
  },
  methods: {
    showHistoryClicked() {
      this.prevIsInUse = !this.prevIsInUse;
      this.getPersonFiles(this.prevIsInUse)
    },

    getPersonFiles(isInUse) {
      this.disableHistoryButton = !this.disableHistoryButton
      this.$store.state.general.showOverlay = true;
      console.log()
      let payload = {
        isInUse: isInUse,
        memberId: this.memberId
      };
      this.getPersonFile(payload).then(res => {
        if (res.hasError) {
          this.showModal({
            title: "Hata!",
            message: res.errorMessage,
            type: "danger",
          });
        } else {
          if (res.data.length > 0) {
            this.personFileList = res.data;
            if (this.form.personId == null || this.form.memberId == null) {
              this.form.personId = this.personFileList[0].personId;
              this.form.memberId = this.personFileList[0].memberId;
            }
          } else {
            this.prevIsInUse = !this.prevIsInUse;
            this.showModal({
              title: "Uyarı!",
              message: "Kayıt bulunamadı.",
              type: "danger",
            });
          }
        }
      }).catch((reason) => {
        this.showModal({
          title: "Hata!",
          message: reason.toString(),
          type: "danger",
        });
      }).finally(() => {
        this.$store.state.general.showOverlay = false;
        this.disableHistoryButton = !this.disableHistoryButton
      });
    },


    ...mapActions("membershipAeo", ["updatePersonState", "getPersonFile",
      "updatePersonFile", "savePersonFile"]),
    ...mapActions("general", ["showModal"]),

    showEditModal(item) {
      this.isEditing = true;
      const {
        id,
        languageLevelId,
        languageId,
        isInUse,
      } = item;
      Object.assign(this.form, {
        id: id,
        languageLevelId: languageLevelId,
        languageId: languageId,
        isInUse: isInUse,
      });
      this.$bvModal.show("modal-personFile-list-edit");
    },

    update: function () {
      this.$v.$touch();
      if (!this.$v.$invalid) {

        this.$store.state.general.showOverlay = true;
        const formData = new FormData();
        if (this.isEditing)
          formData.append("id", this.form.id);
        formData.append("memberId", this.form.memberId);
        formData.append("file", this.form.file);
        formData.append("description", this.form.description);
        formData.append("isInUse", this.form.isInUse);

        if (this.isEditing) {
          this.updatePersonFile(formData).then(res => {
            if (res.hasError) {
              this.showModal({
                title: "Hata!",
                message: res.errorMessage,
                type: "danger",
              });
            } else {
              this.showModal({
                title: "Başarıylı!",
                message: "Dosya başarıyla güncellendi.",
                type: "success",
              });
              this.personFileList = res.data;

              this.clearForm();
            }
          }).catch((reason) => {
            this.showModal({
              title: "Hata!",
              message: reason.toString(),
              type: "danger",
            });
          }).finally(() => {
            this.$store.state.general.showOverlay = false;
            this.$bvModal.hide("modal-personFile-list-edit");
            this.isEditing = false;

          });

        } else {
          this.savePersonFile(formData).then(res => {
            if (res.hasError) {
              this.showModal({
                title: "Hata!",
                message: res.errorMessage,
                type: "danger",
              });
            } else {
              this.showModal({
                title: "Başarıylı!",
                message: "Dosya başarıyla kayıt edildi.",
                type: "success",
              });
              this.personFileList = res.data;

              this.clearForm();
            }
          }).catch((reason) => {
            this.showModal({
              title: "Hata!",
              message: reason.toString(),
              type: "danger",
            });
          }).finally(() => {
            this.$store.state.general.showOverlay = false;
            this.$bvModal.hide("modal-personFile-list-edit");
            this.isEditing = false;

          });
        }
      }
    },


    clearForm() {
      this.isEditing = false;
      const {description, file, fileName, isInUse} = this.form;
      const clearedProps = {
        description: null,
        file: null,
        fileName: null,
        isInUse: null,
      };
      this.form = Object.assign({}, this.form, clearedProps);
      this.$v.$reset();
    },
    onChangeFileUpload(e) {
      this.form.file = this.$refs.sfile.files[0];
      this.form.fileName = this.$refs.sfile.files[0].name;
    }
  },
}
</script>
