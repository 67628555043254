<template>
  <div>
    <b-modal
        id="modal-personNote-list-edit"
        size="lg"
        centered
        title="Kişi Not Bilgisini Düzenle"
        ok-title="Kaydet"
        ok-variant="success"
        cancel-title="İptal"
        cancel-variant="outline-secondary"
        @ok.prevent="update"
        @hidden="clearForm">
      <div class="row">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Not</label>
              <input
                  type="text"
                  :class="{ 'is-invalid': $v.form.text.$error }"
                  class="form-control"
                  v-model="form.text"
                  maxlength="100"
              />
              <template v-if="!this.$v.form.text.minLength">
                <small class="text-danger" v-if="!$v.form.text.minLength">
                  Adres adı adı en az 5 karakter içermelidir.</small
                >
              </template>

              <template v-if="$v.form.text.$error">
                <small class="text-danger" v-if="!$v.form.text.required">
                  Bu alan boş bırakılamaz.
                </small>
              </template>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="d-block">Aktif</label>
              <label class="label_switch">
                <input type="checkbox" v-model="form.isInUse"/>
                <span class="mr-2"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <table
        v-if="this.personNoteList.length > 0"
        class="table table-hover">
      <thead>
      <tr>
        <th class="fit">Eklenme Tarihi</th>
        <th class="fit">Not</th>
        <th class="fit text-right">Aktif</th>
        <th class="fit text-right">
          <PlusCircleIcon v-b-modal.modal-personNote-list-edit/>
        </th>
        <th class="fit text-right">
          <ShowHistoryIcon @iconClicked="showHistoryClicked" :disabled="disableHistoryButton"/>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
          @click="showEditModal(item)"
          v-for="(item, index) in this.personNoteList"
          :key="index"
          class="cursor-pointer"
      >
        <td>{{format_date(item.created_at)}}</td>
        <td>{{item.text}}</td>
        <td class="fit text-right">
          <font-awesome-icon icon="check" size="lg" class="text-danger" v-if="item.isInUse"/>
          <font-awesome-icon icon="ban" size="lg" class="text-danger" v-else/>
        </td>
        <td class="fit text-right"></td>
        <td class="fit text-right"></td>

      </tr>
      </tbody>
    </table>
    <div v-else class="alert-secondary p-5 d-flex justify-content-between">
      Kullanıcıya ait bir not bulunmamaktadır. Eklemek için tıkla
      <PlusCircleIcon v-b-modal.modal-personNote-list-edit/>
    </div>


  </div>
</template>

<script>
import {VueEditor} from "vue2-editor"
import {mapActions, mapGetters} from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/tr";
import moment from "moment";
import {minLength, required, requiredIf} from "vuelidate/lib/validators";
import ShowHistoryIcon from "@/components/ShowHistoryIcon.vue";
import PlusCircleIcon from "@/components/PlusCircleIcon.vue";

export default {
  name: "TahTesTut",
  components: {
    PlusCircleIcon,
    ShowHistoryIcon,
    VueEditor,
    DatePicker,
    moment,
  },
  delimiters: ['${', '}'], // Avoid Twig conflicts
  tabIndex: 0,
  data() {
    return {
      prevIsInUse: true,
      isEditing: false,
      disableHistoryButton: false,
      personNoteList: [],

      form: {
        id: null,
        personId: null,
        memberRegistrationStateTypeId: 0,
        text: null,
        isInuse: null,

      },

    };
  },
  created() {
    this.getPersonNotes(true);
  },

  validations: {
    form: {
      text: {
        required,
        minLength: minLength(5),
      }
    }
  },

  methods: {
    showHistoryClicked() {
      this.prevIsInUse = !this.prevIsInUse;
      this.getPersonNotes(this.prevIsInUse)
    },
    format_date(value) {
      return moment(value).format("DD.MM.YYYY");
    },
    ...mapActions("membershipAeo", ["updatePersonState", "getPersonNote",
      "updatePersonNote", "savePersonNote"]),
    ...mapActions("general", ["showModal"]),

    showEditModal(item) {
      this.isEditing = true;
      const {
        id,
        text,
        isInUse,
      } = item;
      Object.assign(this.form, {
        id: id,
        text: text,
        isInUse: isInUse,
      });
      this.$bvModal.show("modal-personNote-list-edit");
    },
    getPersonNotes(isInUse) {
      this.disableHistoryButton = !this.disableHistoryButton
      this.$store.state.general.showOverlay = true;
      let payload = {
        isInUse: isInUse,
        personId: this.$route.params.personId
      };
      this.getPersonNote(payload).then(res => {
        if (res.hasError) {
          this.showModal({
            title: "Hata!",
            message: res.errorMessage,
            type: "danger",
          });
        } else {
          if (res.data.length > 0) {
            this.personNoteList = res.data;
            if (this.form.personId == null) {
              this.form.personId = this.personNoteList[0].personId;
            }
          } else {
            this.prevIsInUse = !this.prevIsInUse;
            this.showModal({
              title: "Uyarı!",
              message: "Kayıt bulunamadı.",
              type: "danger",
            });
          }
        }
      }).catch((reason) => {
        this.showModal({
          title: "Hata!",
          message: reason.toString(),
          type: "danger",
        });
      }).finally(() => {
        this.$store.state.general.showOverlay = false;
        this.disableHistoryButton = !this.disableHistoryButton
      });
    },

    update: function () {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store.state.general.showOverlay = true;
        if (this.isEditing) {
          this.updatePersonNote(this.form).then(res => {
            if (res.hasError) {
              this.showModal({
                title: "Hata!",
                message: res.errorMessage,
                type: "danger",
              });
            } else {
              this.showModal({
                title: "Başarıylı!",
                message: "Not Bilgisi güncellendi.",
                type: "success",
              });
              this.personNoteList = res.data;
              if (this.form.personId == null) {
                this.form.personId = this.personNoteList[0].personId;
              }
              this.clearForm();
            }

          }).catch((reason) => {
            this.showModal({
              title: "Hata!",
              message: reason.toString(),
              type: "danger",
            });
          }).finally(() => {
            this.$store.state.general.showOverlay = false;
            this.$nextTick(() => {
              this.$bvModal.hide("modal-personNote-list-edit");
            });
          })
        } else {
          this.savePersonNote(this.form).then(res => {
            if (res.hasError) {
              this.showModal({
                title: "Hata!",
                message: res.errorMessage,
                type: "danger",
              });
            } else {
              this.showModal({
                title: "Başarıylı!",
                message: "Not Bilgisi kayıt edildi.",
                type: "success",
              });
              this.personNoteList = res.data;
              if (this.form.personId == null) {
                this.form.personId = this.personNoteList[0].personId;
              }
              this.clearForm();
            }
          }).catch((reason) => {
            this.showModal({
              title: "Hata!",
              message: reason.toString(),
              type: "danger",
            });
          }).finally(() => {
            this.$store.state.general.showOverlay = false;
            this.$bvModal.hide("modal-personNote-list-edit");
          });
        }
        this.isEditing = false;
      }
    },
    clearForm() {
      this.isEditing = false
      const {text, isInUse} = this.form;
      const clearedProps = {
        text: null,
        isInUse: null,
      };
      this.form = Object.assign({}, this.form, clearedProps);
      this.$v.$reset();
    },

  },
}
</script>
