<template>
  <div class="standart-page">
    <div class="card-component mb-4">
      <div class="body">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label>İş Yeri İsmi</label>
              <input
                  type="text"
                  class="form-control"
                  v-model="form.name"
                  maxlength="100"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Telefon Numarası</label>
              <input
                  type="text"
                  class="form-control"
                  v-model="form.phone"
                  maxlength="100"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>E-Mail</label>
              <input
                  type="text"
                  class="form-control"
                  v-model="form.email"
                  maxlength="100"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <div class="form-group">
              <label>Adres Açıklaması</label>
              <input
                  type="text"
                  class="form-control"
                  v-model="form.addressDescription"
                  maxlength="100"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Adres</label>
              <input
                  type="text"
                  class="form-control"
                  v-model="form.address"
                  maxlength="100"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Şehir</label>
              <select
                  v-select
                  class="form-control"
                  v-model="form.cityId"
              >
                <option :value="null">Seçiniz</option>
                <option
                    :value="item.id"
                    v-for="(item, index) in this.cityList"
                    :key="index"
                >
                  {{ item.text }}
                </option>
              </select>

            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Semt</label>
              <select
                  :disabled="form.cityId === null"
                  v-select
                  class="form-control"
                  v-model="form.districtId"
              >
                <option :value="null">Seçiniz</option>
                <option
                    :value="item.id"
                    v-for="(item, index) in filteredDistrictList"
                    :key="index"
                >
                  {{ item.text }}
                </option>
              </select>

            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>İş Yeri Tipi</label>
              <select
                  v-select
                  class="form-control"
                  v-model="form.workplaceTypeId"
              >
                <option :value="null">Seçiniz</option>
                <option
                    :value="item.id"
                    v-for="(item, index) in this.workplaceTypeList"
                    :key="index"
                >
                  {{ item.text }}
                </option>
              </select>

            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>İş Yeri Durum</label>
              <select
                  v-select
                  class="form-control"
                  v-model="form.workplaceStatusId"
              >
                <option :value="null">Seçiniz</option>
                <option
                    :value="item.id"
                    v-for="(item, index) in this.workplaceStatusList"
                    :key="index"
                >
                  {{ item.text }}
                </option>
              </select>

            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Üst Kurum (UP ID)</label>
              <select
                  v-select
                  class="form-control"
                  v-model="form.upId"
              >
                <option :value="null">Seçiniz</option>
                <option
                    :value="item.id"
                    v-for="(item, index) in this.workplaceUp"
                    :key="index"
                >
                  {{ item.name }}
                </option>
              </select>

            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Merkez Kurum (TOP ID)</label>
              <select
                  v-select
                  class="form-control"
                  v-model="form.topId"
              >
                <option :value="null">Seçiniz</option>
                <option
                    :value="item.id"
                    v-for="(item, index) in this.workplaceTop"
                    :key="index"
                >
                  {{ item.name }}
                </option>
              </select>

            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label>Bölge</label>
              <select
                  v-select
                  class="form-control"
                  v-model="form.regionId"
              >
                <option :value="null">Seçiniz</option>
                <option
                    :value="item.id"
                    v-for="(item, index) in this.regionList"
                    :key="index"
                >
                  {{ item.text }}
                </option>
              </select>

            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Görev Durumu</label>
              <select
                  v-select
                  class="form-control"
                  v-model="form.dutyStatusId"
              >
                <option :value="null">Seçiniz</option>
                <option
                    :value="item.id"
                    v-for="(item, index) in this.dutyStatusList"
                    :key="index"
                >
                  {{ item.text }}
                </option>
              </select>

            </div>

          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Görev Kodu</label>
              <input
                  type="text"
                  class="form-control"
                  v-model="form.dutyCode"
                  maxlength="100"
              />
            </div>
          </div>
        </div>
        <div class="row mt-3 justify-content-end">
          <div class="col-md-4">
            <div class="form-group w-100">
              <button
                  @click="save"
                  class="btn btn-danger w-100 align-content-center">
                İş yeri Kaydet
              </button>
            </div>
          </div>
        </div>
        <div>
          <div v-if="locationMarkers.length===0">
            <h2>İş Yerinin Adresini İşaretle</h2>
            <label>
              <gmap-autocomplete @place_changed="initMarker">

              </gmap-autocomplete>
              <button @click="addLocationMarker">Add</button>
            </label>
            <br/>

          </div>
          <br>
          <gmap-map
              :zoom="14"
              :center="center"
              style="width:100%;  height: 600px;"
          >
            <gmap-marker
                :draggable="true"
                :clickable="true"
                :key="index"
                v-for="(m, index) in locationMarkers"
                :position="m.position"
                @click="center=m.position"
                @drag="updateCoordinates"
            ></gmap-marker>
          </gmap-map>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {VueEditor} from "vue2-editor"
import {mapActions, mapGetters} from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/tr";
import moment from "moment";
import {minLength, required, requiredIf} from "vuelidate/lib/validators";
import {associationAeo} from "@/store/association.aeo.module";

export default {
  name: "AddWorkplace",
  components: {
    VueEditor,
    DatePicker,
    moment,
  },
  delimiters: ['${', '}'], // Avoid Twig conflicts
  tabIndex: 0,
  data() {
    return {
      center: {
        lat: 39.7837304,
        lng: -100.4458825
      },
      locationMarkers: [],
      locPlaces: [],
      existingPlace: null,
      workplaceStatusList: [],
      workplaceTypeList: [],
      regionList: [],
      filteredDistrictList: [],
      cityList: [],
      districtList: [],
      workplaceUp: [],
      workplaceTop: [],
      dutyStatusList: [],
      form: {
        lat: null,
        lng: null,
        upId: null,
        topId: null,
        name: null,
        address: null,
        addressDescription: null,
        phone: null,
        email: null,
        workplaceTypeId: null,
        workplaceStatusId: null,
        regionId: null,
        dutyCode: null,
        dutyStatusId: null,
        districtId: null,
        cityId: null,
      }
    };
  },
  created() {
    if (this.getterWorkplaceType().length === 0) {
      this.getWorkplaceType();
    } else {
      this.workplaceTypeList = this.getterWorkplaceType();
    }
    if (this.getterWorkplace().length === 0) {
      this.getWorkplace();
    } else {
      this.workplaceUp = this.getterWorkplace();
      this.workplaceTop = this.getterWorkplace();
    }


    if (this.getterWorkplaceStatus().length === 0) {
      this.getWorkplaceStatus();
    } else {
      this.workplaceStatusList = this.getWorkplaceStatus();
    }


    if (this.getterRegions().length === 0) {
      this.getRegions();
    } else {
      this.regionList = this.getterRegions();
    }


    if (this.getterDutyStatus().length === 0) {
      this.getDutyStatus();
    } else {
      this.dutyStatusList = this.getterDutyStatus();
    }


    if (this.getterCities().length === 0) {
      this.getCity();
    } else {
      this.cityList = this.getterCities();
    }


    if (this.getterDistricts().length === 0) {
      console.log("doldurduk")

      this.getDistrict();
    } else {
      this.districtList = this.getterDistricts();
      console.log("hello")
    }
  },

  mounted() {
    this.locateGeoLocation();
  },

  validations: {
    form: {
      /* name: {
         required,
         minLength: minLength(5),
       }
       */
    }
  },

  methods: {
    ...mapGetters("associationAeo", ["getterWorkplaceStatus", "getterWorkplaceType", "getterWorkplace", "getterRegions", "getterDutyStatus"]),
    ...mapGetters("dtAeo", ["getterCities", "getterDistricts"]),
    ...mapActions("dtAeo", ["getCity", "getDistrict"]),
    ...mapActions("general", ["showModal"]),
    ...mapActions("associationAeo", ["getWorkplaceType", "getWorkplaceStatus", "getWorkplace", "getRegions", "getDutyStatus", "addWorkplace"]),
    initMarker(loc) {
      this.existingPlace = loc;
    },
    addLocationMarker() {
      if (this.existingPlace) {
        const marker = {
          lat: this.existingPlace.geometry.location.lat(),
          lng: this.existingPlace.geometry.location.lng()
        };
        this.locationMarkers.push({position: marker});
        this.locPlaces.push(this.existingPlace);
        this.center = marker;
        this.existingPlace = null;
      }
    },
    updateCoordinates(location) {
      this.locationMarkers[0] = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      };
    },
    locateGeoLocation: function () {
      navigator.geolocation.getCurrentPosition(res => {
        this.center = {
          lat: res.coords.latitude,
          lng: res.coords.longitude
        };
      });
    },
    format_date(value) {
      return moment(value).format("DD.MM.YYYY");
    },


    save: function () {
      //Diğer validationları ekle kanka
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.locationMarkers[0] == null) {
          this.showModal({
            title: "Hata!",
            message: "Lütfen haritadan bir konum seçiniz.",
            type: "danger",
          });
          return;
        }
        this.form.lat = this.locationMarkers[0].position.lat
        this.form.lng = this.locationMarkers[0].position.lng
        this.$store.state.general.showOverlay = true;
        //TODO: Eğer UpId seçiliyse, TopId otomatik seçilme eklenmeli.
        this.addWorkplace(this.form).then(res => {
          if (res.hasError) {
            this.showModal({
              title: "Hata!",
              message: res.errorMessage,
              type: "danger",
            });
          } else {
            this.showModal({
              title: "Başarıylı!",
              message: "Şirket bilgisi kayıt edildi.",
              type: "success",
            });

            this.clearForm();
          }
        }).catch((reason) => {
          this.showModal({
            title: "Hata!",
            message: reason.toString(),
            type: "danger",
          });
        }).finally(() => {
          this.$store.state.general.showOverlay = false;

        });
      }
    },

    clearForm() {
      const {
        upId,
        topId,
        name,
        address,
        addressDescription,
        phone,
        email,
        workplaceTypeId,
        workplaceStatusId,
        regionId,
        dutyCode,
        dutyStatusId,
        districtId,
        cityId,
      } = this.form;
      const clearedProps = {
        upId: null,
        topId: null,
        name: null,
        address: null,
        addressDescription: null,
        phone: null,
        email: null,
        workplaceTypeId: null,
        workplaceStatusId: null,
        regionId: null,
        dutyCode: null,
        dutyStatusId: null,
        districtId: null,
        cityId: null,
      };
      this.form = Object.assign({}, this.form, clearedProps);
      this.$v.$reset();
      this.$bvModal.hide("modal-workplace-add");
    },


  },
  watch: {
    "$store.state.associationAeo.workplaceType":
        function () {
          this.workplaceTypeList = this.getterWorkplaceType();
        },
    "$store.state.associationAeo.workplaceStatus":
        function () {
          this.workplaceStatusList = this.getterWorkplaceStatus();
        },
    "$store.state.associationAeo.workplace":
        function () {
          this.workplaceTop = this.getterWorkplace();
          this.workplaceUp = this.getterWorkplace();
        },

    "$store.state.dtAeo.cities":
        function () {
          this.cityList = this.getterCities();
          console.log(this.cityList)

        },

    "$store.state.dtAeo.district":
        function () {
          this.districtList = this.getterDistricts();
          console.log(this.districtList)
        },

    "$store.state.associationAeo.regions":
        function () {
          this.regionList = this.getterRegions();
        },
    "$store.state.associationAeo.dutyStatus":
        function () {
          this.dutyStatusList = this.getterDutyStatus();
        },

    'form.cityId': function (newCityId) {
      console.log("hello")
      this.filteredDistrictList = this.districtList.filter(x => x.cityId === newCityId);
      console.log(this.filteredDistrictList)
      console.log(this.districtList)
    }
  },
}
</script>

<style>
.pac-container {
  z-index: 10000 !important;
}
</style>