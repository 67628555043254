<template>
 <div class="standart-page">
  
    <div class="row">

      <div class="col-md-12"><h3 class="title">Üye Ara</h3></div>
 
    </div>
    <div class="card-component mb-4">
      <div class="body">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label>TC Kimlik No</label>
              <input
                  type="text"
                  class="form-control"
                  v-model="form.keyword"
                  maxlength="100"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Oda Kayıt No</label>
              <input
                  type="text"
                  class="form-control"
                  v-model="form.keyword"
                  maxlength="100"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Ad Soyad</label>
              <input
                  type="text"
                  class="form-control"
                  v-model="form.keyword"
                  maxlength="100"
              />
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-4">
            <div class="form-group w-100">
              <button class="btn btn-secondary w-100">
                Ara
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="table-responsive">
      <table class="table table-hover">
        <thead>
        <tr>
          <th>Yayın Tarihi</th>
          <th>Duyuru Başlığı</th>
          <th class="fit">Yayın Yeri</th>
          <th class="fit">Okunma Adedi</th>
          <th class="fit text-right">2 Buton koyaceik</th>
        </tr>
        </thead>
        <tbody>
        <tr class="cursor-pointer" @click="showEditModal(item)"
            v-for="(item, index) in $store.state.dtAeo.AnnouncementPaginatedSearchResult.item" :key="index">
          <td>{{ item.startDate }} - {{ item.endDate }}</td>
          <td>{{item.header}}</td>
          <td class="fit text-center">{{item.publishPlace}}</td>
          <td class="fit text-right">{{item.readQuantity}}</td>
          <td class="fit text-right">Edit ve Delete Butonları?</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="alert alert-danger text-center" v-if="this.$store.getters['dtAeo/getAnnouncementSearchCount']<=0">
      {{ "Aradığınız kriterlere uygun kayıt bulunamadı." }}
    </div>
    <div class="row" v-if="$store.state.dtAeo.AnnouncementPaginatedSearchResult.item.length > 0">
      <div class="col">
        <i class="text-secondary"
        >{{
          $store.state.dtAeo.AnnouncementPaginatedSearchResult.dtPagination.totalItems
          }}
          kayıttan {{ (form.currentPage - 1) * form.pageSize + 1 }} -
          {{ form.currentPage * form.pageSize }} arası gösterilmektedir.</i
        >
      </div>
      <div class="col">
        <div class="row">
          <div class="col d-flex justify-content-end">
            <nav>
              <paginate
                  v-if="$store.state.dtAeo.AnnouncementPaginatedSearchResult.item.length"
                  :page-count="
                  $store.state.dtAeo.AnnouncementPaginatedSearchResult.dtPagination.totalPages
                "
                  :click-handler="changePage"
                  :prev-text="'Önceki'"
                  :next-text="'Sonraki'"
                  :active-class="'active'"
                  :container-class="'pagination'"
                  v-model="form.currentPage"
              >
              </paginate>
            </nav>
          </div>
          <div class="col-2">
            <select
                v-select
                class="form-control"
                v-model="form.pageSize"
                @change="changeSize"
            >
              >
              <option value="25" selected>25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
      </div>
    </div> -->

  </div>
</template>
<script>
import {VueEditor} from "vue2-editor"
import {mapActions} from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/tr";
import moment from "moment";

export default {
  name: "TahTesTut",
  components: {
    VueEditor,
    DatePicker,
    moment,
  },
  delimiters: ['${', '}'], // Avoid Twig conflicts
  data() {
    return {


      images: [],
      index: null,
      open: false,
      day_difference: null,


      announcement_head_pic_file_local: null,
      form: {
        announcement_title: null,
        announcement_html: "<h4>Aşağıdaki önizleme butonuna basarsan, burada yazılanların duyurun onaylandığında nasıl gözükeceklerini görebilirsin.</h4>",
        announcement_head_pic: null,
        announcement_head_pic_file: null,
        announcement_start_date: null,
        announcement_type: null,
        subjectGroups: [],
        publishPlaces: [],
        announcement_charged_person: null
      },

    };
  },
  created() {
    this.getPageType();
    this.getPublishPlace();
    this.$store.dispatch("general/changeMenu", "MembershipMenu");

  },
  methods: {

    customLabel({options_grouping}) {
      return `${options_grouping.lang}`
    },

    openPublishPlaces() {
      if (this.$store.state.dtAeo.publishPlaces.length === 0) {
        this.getPublishPlace();
      }
    },

    openSubjectType() {
      if (this.$store.state.dtAeo.subjectTypeGrouped.length === 0) {
        this.getSubjectTypeGrouped();
      }
    },


    Up(item) {
      //TODO: Böyle bir workaround yok. :)
      let test = Object.assign([], this.images)
      let item0 = test.findIndex(s => s.fileName === item.fileName);
      if (item0 != null) {
        let temp = test[item0 - 1];//1 üsttekinin item'ını aldık.
        test[item0 - 1] = item; //1 üste 1 aşağısındakini koyduk
        test[item0] = temp;
        this.images = Object.assign([], test);
      }
    },
    Down(item) {
      //TODO: Böyle bir workaround yok. :)
      let test = Object.assign([], this.images)
      let item0 = test.findIndex(s => s.fileName === item.fileName);
      if (item0 != null) {
        let temp = test[item0 + 1];//1 üsttekinin item'ını aldık.
        test[item0 + 1] = item; //1 üste 1 aşağısındakini koyduk
        test[item0] = temp;
        this.images = Object.assign([], test);
      }
    },
    ...mapActions("general", ["showModal"]),
    ...mapActions("dtAeo", ["getPublishPlace", "getSubjectTypeGrouped", "getPageType"]),

    onChangeFileUpload(e, isMultiple) {
      // console.log(isMultiple)
      if (isMultiple) {
        const tmpFiles = e.target.files
        //console.log(tmpFiles)
        for (let f of tmpFiles) {
          // console.log(f);
          if (f.name.match(/.(jpg|jpeg|png)$/i)) {
            let obj = {
              file: f,
              fileName: f.name,
              path: URL.createObjectURL(f)
            }
            this.images.push(obj);
          }
        }
      } else {
        if (
            !this.$refs.sfile.files[0].name.match(
                /.(jpg|jpeg|png)$/i
            )
        ) {
          this.showModal({
            title: "Hata!",
            message: "Sadece *.jpeg |  *.jpg | *.png uzantılı dosyalar yükleyebilirsin.",
            type: "danger",
          });
          this.announcement_head_pic_file_local = null;
        } else {
          this.form.announcement_head_pic_file = this.$refs.sfile.files[0];
          this.form.announcement_head_pic = this.$refs.sfile.files[0].name;
        }
      }

    },
    handleChange(value) {
      if (value[0] == null) {
        this.day_difference = null
        return;
      }
      let finish_day = moment(String(value[1]), "DD.MM.YYYY")
      let start_day = moment(String(value[0]), "DD.MM.YYYY")
      this.day_difference = Math.ceil((finish_day - start_day) / (1000 * 60 * 60 * 24));
    },


    onChange(tmpFiles) {
      for (let f of tmpFiles) {
        // console.log(f);
        if (f.name.match(/.(jpg|jpeg|png)$/i)) {
          let obj = {
            file: f,
            fileName: f.name,
            path: URL.createObjectURL(f)
          }
          this.images.push(obj);
        }
      }
    },

    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('bg-green-300')) {
        event.currentTarget.classList.remove('bg-gray-100');
        event.currentTarget.classList.add('bg-green-300');
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    },
    drop(event) {
      event.preventDefault();
      this.$refs.mfile.files = event.dataTransfer.files;
      this.onChange(this.$refs.mfile.files); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    }
  }
}
</script>

<style scoped>
