import axios from 'axios';

import authHeader from './auth-header';
import {config} from "@fortawesome/fontawesome-svg-core";


class DtAeoService {

    getAnnouncementSearchPaginated(payload) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Page/filter-announcements-paginated`,
            {
                Keyword: payload?.keyword,
                PublishPlaceIds: payload?.checkedPublishPlaces,
                PageTypeId: payload?.pageTypes,
                SubjectTypeIds: payload?.checkedSubjectTypes,
                DateInterval: payload?.dateInterval,
                IsApproved: payload?.isApproved,
                ApprovedBy: payload?.approvedBy,
                SearchForApproval: payload?.searchForApproval,
                PageNumber: payload.currentPage, PageLength: payload.pageSize
            },
            {
                headers: authHeader()
            }
        )
    }

    getPageByIdAsync(payload) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Page/get-page-detail`,
            {
                Id: payload,
            },
            {
                headers: authHeader()
            }
        )
    }

    updateImageGalleryItem(payload) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Page/update-image-gallery-item`,
            payload,
            {
                headers: authHeader()
            }
        )
    }

    approvePages(payload) {
        //console.log(payload)
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Page/approve-pages`,

            {
                pageIds: payload.pageIds,
                willBeMessageSend: payload.willBeMessageSend
            },
            {
                headers: authHeader()
            }
        )
    }

    addAnnouncement(data) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Page/add-new-announcement`,
            data,
            {
                headers: {
                    Token: authHeader().Token,
                    'Content-Type': 'multipart/form-data'
                }
            });
    }

    updatePageDetail(payload) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Page/update-page-detail`,
            {
                Id: payload.id,
                Header: payload.header,
                Content: payload.content,
                StartDate: payload.startDate,
                EndDate: payload.endDate,
                PageTypeId: payload.pageTypeId,
                PublishPlacesList: payload.publishPlacesList,
                SubjectTypesList: payload.subjectTypesList
            },
            {
                headers: {
                    Token: authHeader(),
                }
            });
    }

    deleteImageFromImageGallery(payload) {
        console.log(payload)
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Page/delete-image-from-image-gallery`,
            payload,
            {
                headers: {
                    Token: authHeader(),
                }
            });
    }

    updateImageGallery(data) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Page/update-image`,
            data,
            {
                headers: {
                    Token: authHeader().Token,
                    'Content-Type': 'multipart/form-data'
                }
            });
    }

    updateMainIconPath(data) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Page/update-page-icon-path`,
            data,
            {
                headers: {
                    Token: authHeader().Token,
                    'Content-Type': 'multipart/form-data'
                }
            });
    }

    //COUNTRY
    getCountryPaginated(payload) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Country/get-all-paginated`,
            {
                Text: payload.Text, PageNumber: payload.currentPage, PageLength: payload.pageSize
            },
            {
                headers: authHeader()
            }
        )

    }

    getCountry() {
        return axios.get(`${process.env.VUE_APP_PORTAL_API_URL}Country/get-all`, {
            headers: authHeader()
        });
    }

    addCountry(data) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Country/add`,
            {Text: data.Text, IsInUse: data.IsInUse}, {
                headers: authHeader()
            }
        );
    }

    //TODO: Ağlayabilir bak buraya
    updateCountry(data) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Country/update`,
            {Text: data.Text, IsInUse: data.IsInUse, Id: data.Id}, {
                headers: authHeader()
            });
    }

    addPosition(data) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Position/add`,
            {Text: data.Text, IsInUse: data.IsInUse}, {
                headers: authHeader()
            }
        );
    }

    //TODO: Ağlayabilir bak buraya
    updatePosition(data) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Position/update`,
            {Text: data.Text, IsInUse: data.IsInUse, Id: data.Id}, {
                headers: authHeader()
            });
    }

    //CITY
    getCityPaginated(payload) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}City/get-all-paginated`,
            {
                Text: payload.Text,
                CountryId: payload.CountryId,
                PageNumber: payload.currentPage,
                PageLength: payload.pageSize
            }, {
                headers: authHeader()
            });
    }

    getCity() {
        return axios.get(`${process.env.VUE_APP_PORTAL_API_URL}City/get-all`, {
            headers: authHeader()
        });

    }

    addCity(data) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}City/add`,
            {Text: data.Text, CountryId: data.CountryId, IsInUse: data.IsInUse}, {
                headers: authHeader()
            }
        );
    }

    updateCity(data) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}City/update`,
            {Text: data.Text, CountryId: data.CountryId, IsInUse: data.IsInUse, Id: data.Id}, {
                headers: authHeader()
            });
    }

    //DISTRICT
    getDistrictPaginated(payload) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}District/get-all-paginated`,
            {
                Text: payload.Text,
                CityId: payload.CityId,
                PageNumber: payload.currentPage,
                PageLength: payload.pageSize
            }, {
                headers: authHeader()
            });
    }

    getDistrict() {
        return axios.get(`${process.env.VUE_APP_PORTAL_API_URL}District/get-all`, {
            headers: authHeader()
        });

    }

    addDistrict(data) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}District/add`,
            {Text: data.Text, CityId: data.CityId, IsInUse: data.IsInUse}, {
                headers: authHeader()
            }
        );
    }

    updateDistrict(data) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}District/update`,
            {Text: data.Text, CityId: data.CityId, IsInUse: data.IsInUse, Id: data.Id}, {
                headers: authHeader()
            });
    }

    //AddressType

    getAddressTypePaginated(payload) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}AddressType/get-all-paginated`,
            {Text: payload.Text, PageNumber: payload.currentPage, PageLength: payload.pageSize}, {
                headers: authHeader()
            });
    }

    getAddressType() {
        return axios.get(`${process.env.VUE_APP_PORTAL_API_URL}AddressType/get-all`, {
            headers: authHeader()
        });

    }

    addAddressType(data) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}AddressType/add`,
            {Text: data.Text, CityId: data.CityId, IsInUse: data.IsInUse}, {
                headers: authHeader()
            }
        );
    }

    updateAddressType(data) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}AddressType/update`,
            {Text: data.Text, CityId: data.CityId, IsInUse: data.IsInUse, Id: data.Id}, {
                headers: authHeader()
            });
    }

    //Gender

    getGenderPaginated(payload) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Gender/get-all-paginated`,
            {Text: payload.Text, PageNumber: payload.currentPage, PageLength: payload.pageSize}, {
                headers: authHeader()
            });
    }

    getGender() {
        return axios.get(`${process.env.VUE_APP_PORTAL_API_URL}Gender/get-all`, {
            headers: authHeader()
        });

    }

    addGender(data) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Gender/add`,
            {Text: data.Text, CityId: data.CityId, IsInUse: data.IsInUse}, {
                headers: authHeader()
            }
        );
    }

    updateGender(data) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Gender/update`,
            {Text: data.Text, CityId: data.CityId, IsInUse: data.IsInUse, Id: data.Id}, {
                headers: authHeader()
            });
    }

    //Language

    getLanguagePaginated(payload) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Language/get-all-paginated`,
            {Text: payload.Text, PageNumber: payload.currentPage, PageLength: payload.pageSize}, {
                headers: authHeader()
            });
    }

    getLanguage() {
        return axios.get(`${process.env.VUE_APP_PORTAL_API_URL}Language/get-all`, {
            headers: authHeader()
        });

    }

    addLanguage(data) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Language/add`,
            {Text: data.Text, CityId: data.CityId, IsInUse: data.IsInUse}, {
                headers: authHeader()
            }
        );
    }

    updateLanguage(data) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Language/update`,
            {Text: data.Text, CityId: data.CityId, IsInUse: data.IsInUse, Id: data.Id}, {
                headers: authHeader()
            });
    }

    //Title

    getTitlePaginated(payload) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Title/get-all-paginated`,
            {Text: payload.Text, PageNumber: payload.currentPage, PageLength: payload.pageSize}, {
                headers: authHeader()
            });
    }

    getTitle() {
        return axios.get(`${process.env.VUE_APP_PORTAL_API_URL}Title/get-all`, {
            headers: authHeader()
        });

    }

    addTitle(data) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Title/add`,
            {Text: data.Text, CityId: data.CityId, IsInUse: data.IsInUse}, {
                headers: authHeader()
            }
        );
    }

    updateTitle(data) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Title/update`,
            {Text: data.Text, CityId: data.CityId, IsInUse: data.IsInUse, Id: data.Id}, {
                headers: authHeader()
            });
    }

    //University

    getUniversityPaginated(payload) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}University/get-all-paginated`,
            {Text: payload.Text, PageNumber: payload.currentPage, PageLength: payload.pageSize}, {
                headers: authHeader()
            });
    }

    getUniversity() {
        return axios.get(`${process.env.VUE_APP_PORTAL_API_URL}University/get-all`, {
            headers: authHeader()
        });

    }

    addUniversity(data) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}University/add`,
            {Text: data.Text, CityId: data.CityId, IsInUse: data.IsInUse}, {
                headers: authHeader()
            }
        );
    }

    updateUniversity(data) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}University/update`,
            {Text: data.Text, CityId: data.CityId, IsInUse: data.IsInUse, Id: data.Id}, {
                headers: authHeader()
            });
    }

    //WorkplaceType

    getWorkplaceTypePaginated(payload) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}WorkplaceType/get-all-paginated`,
            {Text: payload.Text, PageNumber: payload.currentPage, PageLength: payload.pageSize}, {
                headers: authHeader()
            });
    }

    getWorkplaceType() {
        return axios.get(`${process.env.VUE_APP_PORTAL_API_URL}WorkplaceType/get-all`, {
            headers: authHeader()
        });

    }

    addWorkplaceType(data) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}WorkplaceType/add`,
            {Text: data.Text, CityId: data.CityId, IsInUse: data.IsInUse}, {
                headers: authHeader()
            }
        );
    }

    updateWorkplaceType(data) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}WorkplaceType/update`,
            {Text: data.Text, CityId: data.CityId, IsInUse: data.IsInUse, Id: data.Id}, {
                headers: authHeader()
            });
    }

    //PublishPlace

    getPublishPlacePaginated(payload) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}PublishPlace/get-all-paginated`,
            {Text: payload.Text, PageNumber: payload.currentPage, PageLength: payload.pageSize}, {
                headers: authHeader()
            });
    }

    getPublishPlace() {
        return axios.get(`${process.env.VUE_APP_PORTAL_API_URL}PublishPlace/get-all`, {
            headers: authHeader()
        });

    }

    addPublishPlace(data) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}PublishPlace/add`,
            {Text: data.Text, CityId: data.CityId, IsInUse: data.IsInUse}, {
                headers: authHeader()
            }
        );
    }

    updatePublishPlace(data) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}PublishPlace/update`,
            {Text: data.Text, CityId: data.CityId, IsInUse: data.IsInUse, Id: data.Id}, {
                headers: authHeader()
            });
    }

    //PageType

    getPageTypePaginated(payload) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}PageType/get-all-paginated`,
            {Text: payload.Text, PageNumber: payload.currentPage, PageLength: payload.pageSize}, {
                headers: authHeader()
            });
    }

    getPageType() {
        return axios.get(`${process.env.VUE_APP_PORTAL_API_URL}PageType/get-all`, {
            headers: authHeader()
        });

    }

    addPageType(data) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}PageType/add`,
            {Text: data.Text, CityId: data.CityId, IsInUse: data.IsInUse}, {
                headers: authHeader()
            }
        );
    }

    updatePageType(data) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}PageType/update`,
            {Text: data.Text, CityId: data.CityId, IsInUse: data.IsInUse, Id: data.Id}, {
                headers: authHeader()
            });
    }


    //DT-WEB

    getPublishPlace() {
        return axios.get(`${process.env.VUE_APP_PORTAL_API_URL}PublishPlace/get-all`, {
            headers: authHeader()
        });
    }

    getSubjectTypeGrouped() {
        return axios.get(`${process.env.VUE_APP_PORTAL_API_URL}SubjectType/get-all-grouped`, {
            headers: authHeader()
        });
    }

    getSubjectType() {
        return axios.get(`${process.env.VUE_APP_PORTAL_API_URL}SubjectType/get-all`, {
            headers: authHeader()
        });
    }

    getPageType() {
        return axios.get(`${process.env.VUE_APP_PORTAL_API_URL}PageType/get-all`, {
            headers: authHeader()
        });
    }

    removeAnnouncement(payload) {
        return axios.get(`${process.env.VUE_APP_PORTAL_API_URL}Page/remove-announcement?pageId=` + payload, {
            headers: authHeader()
        });
    }

    getUniversities() {
        return axios.get(`${process.env.VUE_APP_PORTAL_API_URL}University/get-all`, {
            headers: authHeader()
        });
    }

    getPositions() {
        return axios.get(`${process.env.VUE_APP_PORTAL_API_URL}Position/get-all`, {
            headers: authHeader()
        });
    }

    getLanguageLevel() {
        return axios.get(`${process.env.VUE_APP_PORTAL_API_URL}LanguageLevel/get-all`, {
            headers: authHeader()
        });
    }
}

export default new DtAeoService();