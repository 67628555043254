<template>
  <div>
    <b-overlay :show="showOverlay" rounded="sm" class="d-flex flex-fill w-100"></b-overlay>
    <b-modal
        id="modal-role-item-group-edit"
        size="lg"
        centered
        :title="modalTitle"
        ok-title="Kaydet"
        ok-variant="success"
        cancel-title="İptal"
        cancel-variant="outline-secondary"
        :busy="showOverlay"
        @ok.prevent="save"
        @hidden="clear"
    >
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label>İşlem Group Adı</label>
            <input type="text" class="form-control" v-model="form.itemName"/>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label>Icon</label>
            <input type="text" class="form-control" v-model="form.description"/>
          </div>
        </div>
      </div>
      <div class="row">

        <div class="col">
          <div class="form-group">
            <label class="d-block">Görünürlük</label>
            <label class="label_switch">
              <input type="checkbox" v-model="form.displayInMenu"/>
              <span class="mr-2"></span>
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">

          <div class="form-group">
            <label>Üst İşlem Grupları</label>
          </div>
        </div>
      </div>
      <div v-if="this.$store.state.general.pageGroups_forSecondPage.length>0">
        <div
            class="row"
            v-for="rowIdx in Math.ceil(pageGroups.length / 3)">
          <div class="one-third column"
               style="margin-left: 50px"
               v-for="item in pageGroups.slice(3 * (rowIdx - 1), 3 * rowIdx)">
            <div class="form-group">
              <label class="d-block">{{ item.itemName }}</label>
              <label class="label_switch">
                <input type="checkbox" v-model="item.selected"
                />
                <span class="mr-2"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">

          <div class="form-group">
            <label>Roller</label>
          </div>
        </div>
      </div>
      <div v-if="this.$store.state.general.roles.length>0">
        <div
            class="row"
            v-for="rowIdx in Math.ceil(roles.length / 3)">
          <div class="one-third column"
               style="margin-left: 50px"
               v-for="item in roles.slice(3 * (rowIdx - 1), 3 * rowIdx)">
            <div class="form-group">
              <label class="d-block">{{ item.name }}</label>
              <label class="label_switch">
                <input type="checkbox" v-model="item.selected"
                />
                <span class="mr-2"></span>
              </label>
            </div>
          </div>
        </div>
      </div>


    </b-modal>
    <table class="table table-hover">
      <thead>
      <tr>
        <th class="fit">Grup Adı</th>
        <th class="w-25">Üst Grup</th>
        <th class="w-25">Icon</th>
        <th class="w-25">Menüde Görünsün</th>

        <th class="fit text-right">
          <button class="btn btn-sm" @click.stop="addPage">
            <PlusCircleIcon v-b-modal.modal-interlocutor-result-add-edit/>
          </button>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
          v-for="(item, index) in $store.state.general.pageGroups"
          :key="index"
          class="cursor-pointer"
          @click="showEditModal(item)"
      >
        <td class="fit">{{ item.itemName }}</td>
        <td class="w-25">{{ item.parentItemName }}</td>
        <td class="w-25">{{ item.description }}</td>
        <td class="w-25">{{ item.displayInMenu }}</td>

        <td class="fit text-right">

        </td>
      </tr>
      </tbody>
    </table>

  </div>

</template>

<script>
import PlusCircleIcon from "@/components/PlusCircleIcon";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/tr";
import {mapActions, mapGetters} from "vuex";
import {required, requiredIf} from "vuelidate/lib/validators";
import {forEach} from "lodash";

export default {
  data() {
    return {
      selectedEmployee: null,
      openingType: "",
      showList: true,
      showError: false,
      selectedId: null,
      modalTitle: "Sayfa Grubu Ekle / Güncelle",
      isEditing: false,
      showOverlay: false,
      pageGroups: [],
      temp: {},
      roles: [],

      form: {
        id: null,
        itemName: "",
        description: "",
        displayInUrl: "",
        roles: [],
        pageGroups: [],
      },
      selectedRoleItemGroups: [],
      selectedRoles: [],
      updateItemGroupDTO: {}
    };
  },
  components: {
    PlusCircleIcon,
  },

  computed: {
    ...mapGetters("general", ["getMessage"]),
  },

  methods: {
    async addPage() {
      this.isEditing = false;
      await this.showEditModal(null);
    },
    async save() {
      this.showOverlay = true;
      if (this.isEditing === true) {
        //Get All Selected İşlem Grups

        for (let i = 0; i < this.pageGroups.length; i++) {
          if (this.pageGroups[i].selected == true) {
            this.selectedRoleItemGroups.push(this.pageGroups[i].authorizationItemKey.toString())
          }
        }
        for (let i = 0; i < this.roles.length; i++) {
          if (this.roles[i].selected == true) {
            this.selectedRoles.push(this.roles[i].id.toString())
          }
        }
        // Put in an object
        this.updateRoleItemGroupDTO = {
          "authorizationItemKey": this.form.id.toString(),
          "name": this.form.itemName,
          "description": this.form.description,
          "displayInMenu": this.form.displayInMenu,
          "linkUrl": "",
          "selectedRoleItemGroups": null,
          "selectedUpperRoleItemGroups": this.selectedRoleItemGroups,
          "selectedRoles": this.selectedRoles
        }
        this.$nextTick(() => {
          this.$bvModal.hide("modal-role-item-group-edit");

        })
        this.$store.state.general.showOverlay = true;
        // Send to api

        await this.updateRoleItemGroupDetail(this.updateRoleItemGroupDTO).then((res) => {
          if (res.hasError) {
            this.showModal({
              title: "Hata!",
              message: res.errorMessage,
              type: "danger",
            });
          } else {
            this.$store.state.general.showOverlay = false;
            this.showOverlay = false;
            this.showModal({
              title: "İşlem Başarılı",
              message: "Kayıt Başarıyla Güncellendi",
              type: "success",
            });
            this.filterPageGroups('');
          }
        });
      } else {
        // Yeni Kayıt
        for (let i = 0; i < this.pageGroups.length; i++) {
          if (this.pageGroups[i].selected == true) {
            this.selectedRoleItemGroups.push(this.pageGroups[i].authorizationItemKey.toString())
          }
        }
        for (let i = 0; i < this.roles.length; i++) {
          if (this.roles[i].selected == true) {
            this.selectedRoles.push(this.roles[i].id.toString())
          }
        }
        this.updateRoleItemGroupDTO = {
          "name": this.form.itemName,
          "description": this.form.description,
          "displayInMenu": this.form.displayInMenu,
          "linkUrl": "",
          "selectedRoleItemGroups": null,
          "selectedUpperRoleItemGroups": this.selectedRoleItemGroups,
          "selectedRoles": this.selectedRoles
        }
        this.$nextTick(() => {
          this.$bvModal.hide("modal-role-item-group-edit");
        })
        this.$store.state.general.showOverlay = true;
        // Send to api
        await this.addRoleItemGroupDetail(this.updateRoleItemGroupDTO).then((res) => {
          if (res.hasError) {

            this.showModal({
              title: "Hata!",
              message: res.errorMessage,
              type: "danger",
            });
          } else {


            this.filterPageGroups('');
            this.showModal({
              title: "İşlem Başarılı",
              message: "Kayıt Başarıyla Güncellendi",
              type: "success",
            });
          }
        });
      }
      this.$store.state.general.showOverlay = false;
      this.showOverlay = false;
      this.roles = this.$store.state.general.roles;
      for (let i = 0; i < this.roles.length; i++) {
        this.roles[i].selected = false;
      }
      this.pageGroups = this.$store.state.general.pageGroups_forSecondPage;
      for (let i = 0; i < this.pageGroups.length; i++) {
        this.pageGroups[i].selected = false;
      }
      this.clear();


    },
    async showEditModal(item) {
      this.clear();
      this.selectedRoleItemGroups = [];
      this.selectedRoles = [];
      //console.log(item);
      if (item !== null) {
        // console.log("here");
        this.isEditing = true;

        this.modalTitle = "Sayfa Grubu Güncelle";

        this.$store.state.general.showOverlay = true;
        this.showOverlay = true;
        await this.getRoleItemGroupDetail(item.authorizationItemKey);
        this.$store.state.general.showOverlay = false;
        this.temp = this.$store.state.general.CurrentRoleItemDetail;
        this.form.id = this.temp.id;
        this.form.itemName = this.temp.name;
        this.form.description = this.temp.description;
        this.form.displayInMenu = this.temp.displayInMenu;
        this.form.roles = this.temp.roles;

        for (let i = 0; i < this.form.roles.length; i++) {
          for (let j = 0; j < this.roles.length; j++) {
            if (this.roles[j].id === this.form.roles[i].aspNetRolesId) {
              this.roles[j].selected = true;
            }
          }
        }
        this.form.pageGroups = this.temp.upperGroups;

        for (let i = 0; i < this.form.pageGroups.length; i++) {
          for (let j = 0; j < this.pageGroups.length; j++) {
            if (this.pageGroups[j].authorizationItemKey === this.form.pageGroups[i].parentAuthorizationKey) {
              this.pageGroups[j].selected = true;
            }
          }
        }

        // console.log(this.form);
        //console.log(this.pageGroups);
        //console.log(this.roles);
        // this.form.upperGroups=this.
      }


      this.$bvModal.show("modal-role-item-group-edit");
      this.showOverlay = false;

    },

    ...mapActions("general", ["showModal", "filterRoles", "filterPageGroups", "getRoleItemGroupDetail", "updateRoleItemGroupDetail", "addRoleItemGroupDetail"]),

    clear() {
      this.isEditing = false;

      this.form = Object.assign(
          {},
          {
            id: null,
            itemName: "",
            description: "",
            displayInUrl: "",
            roles: [],
            pageGroups: [],
          }
      );
      for (let i = 0; i < this.pageGroups.length; i++) {
        this.pageGroups[i].selected = false;
      }
      for (let i = 0; i < this.roles.length; i++) {
        this.roles[i].selected = false;
      }
    },
  },


  async created() {
    this.$store.state.general.showOverlay = true;
    this.showOverlay = true;
    await this.filterRoles('');
    await this.filterPageGroups('');
    this.roles = this.$store.state.general.roles;
    for (let i = 0; i < this.roles.length; i++) {
      this.roles[i].selected = false;
    }
    this.pageGroups = this.$store.state.general.pageGroups_forSecondPage;
    for (let i = 0; i < this.pageGroups.length; i++) {
      this.pageGroups[i].selected = false;
    }
    this.$store.state.general.showOverlay = false;
    this.showOverlay = false;
  }
};
</script>


<style scoped>

</style>