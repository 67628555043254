import Vue from 'vue'
import Vuex from 'vuex'

import {auth} from './auth.module';
import {general} from './general.module';

import {dtAeo} from './dt.aeo.module';
import {membershipAeo} from './membership.aeo.module';
import {associationAeo} from './association.aeo.module';
import {academyAeo} from "./academy.aeo.module";


Vue.use(Vuex)

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        auth,
        general,
        dtAeo,
        membershipAeo,
        associationAeo,
        academyAeo
    }
})
