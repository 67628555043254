import { render, staticRenderFns } from "./BolgeIslem.vue?vue&type=template&id=db840fc8&scoped=true&"
import script from "./BolgeIslem.vue?vue&type=script&lang=js&"
export * from "./BolgeIslem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db840fc8",
  null
  
)

export default component.exports