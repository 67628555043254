import axios from 'axios';
import authHeader from './auth-header';

class AuthService {

    getUserAuthorizedMenu() {
        return axios
            .get(`${process.env.VUE_APP_PORTAL_API_URL}Authorization/get-user-authorized-menu`, {
                headers: authHeader()
            })
    }


    login(user) {
        return axios
            .post(process.env.VUE_APP_PORTAL_API_URL + 'Auth/login', {
                EMail: user.eMail,
                Password: user.password
            });
    }


}

export default new AuthService();