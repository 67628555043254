import Vue from "vue";

Vue.filter('formatMoney', function (val) {
	return new Intl.NumberFormat("tr", { style: "decimal", useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(val);
});

/**
 * @param {number} val Örn. "200000.00"
 * @returns {string} Örn. "200.000,00"
**/
export function formatMoney(val) {
	return new Intl.NumberFormat("tr", { style: "decimal", useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(val);
}
