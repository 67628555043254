<template>
  <div>
    <b-overlay :show="showOverlay" rounded="sm" class="d-flex flex-fill w-100"></b-overlay>

    <b-modal
        id="modal-role-item-edit"
        size="lg"
        centered
        :title="modalTitle"
        ok-title="Kaydet"
        ok-variant="success"
        cancel-title="İptal"
        cancel-variant="outline-secondary"
        :busy="showOverlay"
        @ok.prevent="save"
        @hidden="clear"
    >
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label>İşlem Adı</label>
            <input type="text" class="form-control" v-model="form.itemName"/>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label>Açıklama</label>
            <input type="text" class="form-control" v-model="form.description"/>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label>URL</label>
            <input type="text" class="form-control" v-model="form.menuLinkUrl"/>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label class="d-block">Görünürlük</label>
            <label class="label_switch">
              <input type="checkbox" v-model="form.displayInMenu"/>
              <span class="mr-2"></span>
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">

          <div class="form-group">
            <label>İşlem Grupları</label>
          </div>
        </div>
      </div>
      <div v-if="this.$store.state.general.pageGroups.length>0">
        <div
            class="row"
            v-for="rowIdx in Math.ceil(pageGroups.length / 3)">
          <div class="one-third column"
               style="margin-left: 50px"
               v-for="item in pageGroups.slice(3 * (rowIdx - 1), 3 * rowIdx)">
            <div class="form-group">
              <label class="d-block">{{ item.itemName }}</label>
              <label class="label_switch">
                <input type="checkbox" v-model="item.selected"
                />
                <span class="mr-2"></span>
              </label>
            </div>
          </div>
        </div>
      </div>


    </b-modal>
    <table class="table table-hover">
      <thead>
      <tr>
        <th class="fit">İşlem Adı</th>
        <th class="fit">Açıklama</th>
        <th class="w-25">URL</th>
        <th class="w-25">Menüde Görünsün</th>

        <th class="fit text-right">
          <button class="btn btn-sm" @click.stop="addPage">
            <PlusCircleIcon/>
          </button>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
          v-for="(item, index) in $store.state.general.roleItems"
          :key="index"
          class="cursor-pointer"
          @click="showEditModal(item)"
      >
        <td class="fit">{{ item.itemName }}</td>
        <td class="fit">{{ item.description }}</td>
        <td class="w-25">{{ item.menuLinkUrl }}</td>
        <td class="w-25">{{ item.displayInMenu }}</td>

        <td class="fit text-right">

        </td>
      </tr>
      </tbody>
    </table>

  </div>

</template>

<script>
import PlusCircleIcon from "@/components/PlusCircleIcon";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/tr";
import {mapActions, mapGetters} from "vuex";
import {required, requiredIf} from "vuelidate/lib/validators";
import {forEach} from "lodash";

export default {
  data() {
    return {
      selectedEmployee: null,
      openingType: "",
      showList: true,
      showError: false,
      selectedId: null,
      modalTitle: "Sayfa Ekle / Güncelle",
      isEditing: false,
      showOverlay: false,
      pageGroups: [],
      temp: {},

      form: {
        id: null,
        itemName: "",
        description: "",
        menuLinkUrl: "",
        displayInUrl: "",
        relationList: [],
      },
      selectedRoleItemGroups: [],
      updateRoleItemGroupDTO: {}
    };
  },
  components: {
    PlusCircleIcon,
  },

  computed: {
    ...mapGetters("general", ["getMessage"]),
  },

  methods: {
    async addPage() {
      this.isEditing = false;
      await this.showEditModal(null);
    },
    async save() {
      this.showOverlay = true;
      if (this.isEditing === true) {
        //Get All Selected İşlem Grups

        for (let i = 0; i < this.pageGroups.length; i++) {
          if (this.pageGroups[i].selected == true) {
            this.selectedRoleItemGroups.push(this.pageGroups[i].authorizationItemKey.toString())
          }
        }
        // Put in an object
        this.updateRoleItemGroupDTO = {
          "authorizationItemKey": this.form.id.toString(),
          "name": this.form.itemName,
          "description": this.form.description,
          "displayInMenu": this.form.displayInMenu,
          "linkUrl": this.form.menuLinkUrl,
          "selectedRoleItemGroups": this.selectedRoleItemGroups
        }
        this.$nextTick(() => {
          this.$bvModal.hide("modal-role-item-edit");
        })
        this.$store.state.general.showOverlay = true;
        // Send to api

        await this.updateRoleItemDetail(this.updateRoleItemGroupDTO).then((res) => {
          if (res.hasError) {
            this.showModal({
              title: "Hata!",
              message: res.errorMessage,
              type: "danger",
            });
          } else {
            this.$store.state.general.showOverlay = false;
            this.showOverlay = false;
            this.showModal({
              title: "İşlem Başarılı",
              message: "Kayıt Başarıyla Güncellendi",
              type: "success",
            });
          }
        });
      }
      else {
        // Yeni Kayıt
        for (let i = 0; i < this.pageGroups.length; i++) {
          if (this.pageGroups[i].selected == true) {
            this.selectedRoleItemGroups.push(this.pageGroups[i].authorizationItemKey.toString())
          }
        }
        this.updateRoleItemGroupDTO = {
          "name": this.form.itemName,
          "description": this.form.description,
          "displayInMenu": this.form.displayInMenu,
          "linkUrl": this.form.menuLinkUrl,
          "selectedRoleItemGroups": this.selectedRoleItemGroups
        }
        this.$nextTick(() => {
          this.$bvModal.hide("modal-role-item-edit");
        })
        this.$store.state.general.showOverlay = true;
        // Send to api
        await this.addRoleItemDetail(this.updateRoleItemGroupDTO).then((res) => {
          if (res.hasError) {

            this.showModal({
              title: "Hata!",
              message: res.errorMessage,
              type: "danger",
            });
          } else {


            this.filterRoleItems('');
            this.showModal({
              title: "İşlem Başarılı",
              message: "Kayıt Başarıyla Güncellendi",
              type: "success",
            });
          }
        });
      }
      this.$store.state.general.showOverlay = false;
      this.showOverlay = false;
      this.clear();


    },
    async showEditModal(item) {
      this.clear();
      this.selectedRoleItemGroups = [];
      //console.log(item);
      if (item !== null) {
       // console.log("here");
        this.isEditing = true;

        this.modalTitle = "Sayfa Ekle / Güncelle";

        this.$store.state.general.showOverlay = true;
        this.showOverlay = true;
        await this.getRoleItemDetail(item.authorizationItemKey);
        this.$store.state.general.showOverlay = false;
        this.temp = this.$store.state.general.CurrentRoleItemDetail;
        this.form.id = this.temp.item.id;
        this.form.itemName = this.temp.item.name;
        this.form.description = this.temp.item.description;
        this.form.menuLinkUrl = this.temp.item.linkUrl;
        this.form.displayInMenu = this.temp.item.displayInMenu;
        this.form.relationList = this.temp.relationList;

        for (let i = 0; i < this.form.relationList.length; i++) {
          for (let j = 0; j < this.pageGroups.length; j++) {
            if (this.pageGroups[j].authorizationItemKey === this.form.relationList[i].parentAuthorizationItemKey) {
            //  console.log(true);
              this.pageGroups[j].selected = true;
            }
          }
        }
      }


      this.$bvModal.show("modal-role-item-edit");
      this.showOverlay = false;

    },

    ...mapActions("general", ["showModal", "filterRoleItems", "filterPageGroups", "getRoleItemDetail", "updateRoleItemDetail", "addRoleItemDetail"]),

    clear() {
      this.$v.form.$reset();
      this.isEditing = false;
      this.form = Object.assign(
          {},
          {
            id: null,
            itemName: "",
            description: "",
            menuLinkUrl: "",
            displayInUrl: "",
            relationList: [],
          }
      );
      for (let i = 0; i < this.pageGroups.length; i++) {
        this.pageGroups[i].selected = false;
      }
    },
  },

  validations: {
    form: {
      assignmentEndDate: {
        requiredIf: requiredIf(function (form) {
          return form.IsInUse;
        }),
        checkDate(value, form) {
          if (this.form.IsInUse)
            return (
                this.$moment(value, "DD.MM.YYYY").toDate() >=
                this.$moment(form.assignmentStartDate, "DD.MM.YYYY").toDate()
            );
          return true;
        },
        checkDate_today(value) {
          if (this.form.IsInUse)
            return this.$moment(value, "DD.MM.YYYY").toDate() <= new Date();
          return true;
        },
      },
    },
  },
  async created() {
    this.$store.state.general.showOverlay = true;
    this.showOverlay = true;
    await this.filterRoleItems('');
    await this.filterPageGroups('');
    this.pageGroups = this.$store.state.general.pageGroups;
    for (let i = 0; i < this.pageGroups.length; i++) {
      this.pageGroups[i].selected = false;
    }
    this.$store.state.general.showOverlay = false;
    this.showOverlay = false;
  }
};
</script>


<style scoped>

</style>