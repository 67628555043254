import AssociationAeoService from "@/services/association.aeo.service";

const initialState = {
    regions: [],
    workplaceStatus: [],
    workplaceType: [],
    workplace: [],
    dutyStatus: [],
}
export const associationAeo = {
    namespaced: true,
    state: initialState,
    actions: {


        getRegions({commit}) {
            return AssociationAeoService.getRegions().then(res => {
                if (res.data) commit('setRegions', res.data.data);
                return Promise.resolve(res.data);
            });
        },

        getDutyStatus({commit}) {
            return AssociationAeoService.getDutyStatus().then(res => {
                if (res.data) commit('setDutyStatus', res.data.data);
                return Promise.resolve(res.data);
            });
        },

        getWorkplaceStatus({commit}) {
            return AssociationAeoService.getWorkplaceStatus().then(res => {
                if (res.data) commit('setWorkplaceStatus', res.data.data);
                return Promise.resolve(res.data);
            });
        },

        getWorkplaceType({commit}) {
            return AssociationAeoService.getWorkplaceType().then(res => {
                if (res.data) commit('setWorkplaceType', res.data.data);
                return Promise.resolve(res.data);
            });
        },

        getWorkplace({commit}) {
            return AssociationAeoService.getWorkplace().then(res => {
                if (res.data) commit('setWorkplace', res.data.data);
                return Promise.resolve(res.data);
            });
        },


        getPersonDetail({commit}, payload) {
            return AssociationAeoService.getPersonDetail(payload).then(res => {
                return Promise.resolve(res.data);
            });
        },

        getMemberDetail({commit}, payload) {
            return AssociationAeoService.getMemberDetail(payload).then(res => {
                return Promise.resolve(res.data);
            });
        },

        updatePerson({commit}, payload) {
            return AssociationAeoService.updatePerson(payload).then(res => {
                return Promise.resolve(res.data);
            });
        },


        //CREATE

        addWorkplace({commit}, payload) {
            return AssociationAeoService.addWorkplace(payload).then(res => {
                if (res.data) commit('setWorkplace', res.data.data);
                return Promise.resolve(res.data);
            })
        }


    },
    getters: {
        getterRegions(state) {
            return state.regions;
        },
        getterWorkplaceStatus(state) {
            return state.workplaceStatus;
        },
        getterWorkplaceType(state) {
            return state.workplaceType;
        },
        getterWorkplace(state) {
            return state.workplace;
        },
        getterDutyStatus(state) {
            return state.dutyStatus;
        },
    },
    mutations: {
        setRegions(state, payload) {
            state.regions = Object.assign([], payload);
        },
        setWorkplaceStatus(state, payload) {
            state.workplaceStatus = Object.assign([], payload);
        },
        setWorkplaceType(state, payload) {
            state.workplaceType = Object.assign([], payload);
        },
        setWorkplace(state, payload) {
            state.workplace = Object.assign([], payload);
        },

        setDutyStatus(state, payload) {
            state.dutyStatus = Object.assign([], payload);
        },

        setPersonDetail(state, payload) {
            state.personDetails = Object.assign([], payload);
        },
    },
};