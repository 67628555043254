import GeneralService from "@/services/general.service";

import Main from "@/components/menu/Main";
import Announcement from "@/components/menu/Announcement";
import InfPro from "@/components/menu/InfPro";
import Receiving from "@/components/menu/Receiving";
import ReceivingAdmin from "@/components/menu/ReceivingAdmin";
import Membership from "@/components/menu/Membership";
import Crisis from "@/components/menu/Crisis";
import Applications from "@/components/menu/Applications";


const initialState = {
    menuComponentList: [
        {name: 'Main', value: Main, menuTitle: ''},
        {name: 'AnnouncementMenu', value: Announcement, menuTitle: ''},
        {name: 'InfProMenu', value: InfPro, menuTitle: ''},
        {name: 'ReceivingMenu', value: Receiving, menuTitle: ''},
        {name: 'ReceivingAdminMenu', value: ReceivingAdmin, menuTitle: ''},
        {name: 'MembershipMenu', value: Membership, menuTitle: ''},
        {name: 'CrisisMenu', value: Crisis, menuTitle: ''},
        {name: 'ApplicationMenu', value: Applications, menuTitle: ''},
    ],
    currentMenu: Main,
    menuTitle: '',
    duplicateForm: {},

    subMenu: [],
    connection: null,
    connectionId: null,
    showOverlay: false,
    showPopup: false,
    showVideoPopup: false,
    modal: {},
    videoUrl: null,
    showMenu: false,
    activeMenuId: null,
    systemMessages: [],


    roleItems: [],
    pageGroups: [],
    pageGroups_forSecondPage: [],
    CurrentRoleItemDetail: {},
    CurrentUser: {},
    CurrentRole: {},
    roles: [],
    companies: [],
    users: [],
    DTPaginatedUsers: {dtPagination: {}, item: []},

    userDetail: {}
};

export const general = {
    namespaced: true,
    state: initialState,
    actions: {

        //IMPORTANT CHANGESSS
        filterRoleItems({commit}, payload) {
            return GeneralService.filterRoleItems(payload).then(res => {
                if (res.data.data) {
                    commit('setRoleItems', res.data.data);
                }
                return Promise.resolve(res.data);
            })
        },

        whatIsMyDuty({commit}) {
            return GeneralService.whatIsMyDuty().then(res => {
                if (res.data.data) {
                    //   console.log(res.data.data);
                    commit('setUserDetail', res.data.data);
                }
                return Promise.resolve(res.data);
            })
        },

        syncAnnouncementFromOldPortal({commit}) {
            return GeneralService.syncAnnouncementFromOldPortal().then(res => {
                if (res.data.data) {
                    //   console.log(res.data.data);
                    commit('setUserDetail', res.data.data);
                }
                return Promise.resolve(res.data);
            })
        },

        filterUsers({commit}, payload) {

            return GeneralService.filterUsers(payload).then(res => {
                if (res.data) {
                    commit('setUsers', res.data);
                }
                return Promise.resolve(res.data);
            })
        },

        filterRoles({commit}, payload) {

            return GeneralService.filterRoles(payload).then(res => {
                if (res.data.data) {
                    commit('setRoles', res.data.data);
                }
                return Promise.resolve(res.data);
            })
        },
        getRoleItemDetail({commit}, payload) {

            return GeneralService.getRoleItemDetail(payload).then(res => {
                if (res.data.data) {
                    commit('setCurrentRoleItemDetail', res.data.data);
                }
                return Promise.resolve(res.data);
            })
        },
        getUserDetail({commit}, payload) {
            return GeneralService.getUserDetail(payload).then(res => {
                if (res.data.data) {
                    commit('setCurrentUser', res.data.data);
                }
                return Promise.resolve(res.data);
            })
        },
        getRoleDetail({commit}, payload) {
            return GeneralService.getRoleDetail(payload).then(res => {
                if (res.data.data) {
                    commit('setCurrentRole', res.data.data);
                }
                return Promise.resolve(res.data);
            })
        },
        getCompanies({commit}) {
            return GeneralService.getCompanies().then(res => {
                if (res.data.data) {
                    commit('setCompanies', res.data.data);
                }
                return Promise.resolve(res.data);
            })
        },
        getRoleItemGroupDetail({commit}, payload) {

            return GeneralService.getRoleItemGroupDetail(payload).then(res => {
                if (res.data.data) {
                    commit('setCurrentRoleItemDetail', res.data.data);
                }
                return Promise.resolve(res.data);
            })
        },
        filterPageGroups({commit}, payload) {

            return GeneralService.filterPageGroups(payload).then(res => {
                if (res.data.data) {
                    commit('setPageGroups', res.data.data);
                }
                return Promise.resolve(res.data);
            })
        },


        changeMenu({commit}, menuName) {
            localStorage.setItem('activeMenu', menuName);
            window.memoryStorage.activeMenu = menuName;
            commit('changeMenu', menuName);

        },
        updateMenu({commit}, payload) {
            localStorage.setItem('menu', JSON.stringify(payload));
            commit('updateMenu', payload);
        },
        showModal({commit}, payload) {
            commit('showModal', payload);
        },

        toggleMenu({commit}) {
            commit('toggleMenu');
        },
        getSystemMessages({commit}) {
            return GeneralService.getSystemMessages().then(res => {
                if (res.data.data) {
                    localStorage.setItem('messages', JSON.stringify(res.data.data));
                    commit('setSystemMessages', res.data.data);
                }
                return Promise.resolve(res.data);
            })
        },

        getUsersInRole({commit}, payload) {
            return GeneralService.getUsersInRole(payload).then(res => {
                return Promise.resolve(res.data);
            })
        },

        resetState({commit}) {
            commit('resetState');
        },
        updateRoleItemDetail({commit}, payload) {
            return GeneralService.updateRoleItemDetail(payload).then(res => {
                if (res.data.data) commit('updateRoleItemDetail', res.data.data);
                return Promise.resolve(res.data);
            });

        }, updateRole({commit}, payload) {
            return GeneralService.updateRole(payload).then(res => {
                if (res.data.data) commit('setRoles', res.data.data);
                return Promise.resolve(res.data);
            });

        },
        updateRoleItemGroupDetail({commit}, payload) {
            return GeneralService.updateRoleItemGroupDetail(payload).then(res => {
                if (res.data.data) commit('updateRoleItemGroupDetail', res.data.data);
                return Promise.resolve(res.data);
            });

        },
        addRoleItemDetail({commit}, payload) {
            return GeneralService.addRoleItemDetail(payload).then(res => {
                if (res.data.data) commit('setRoleItems', res.data.data);
                return Promise.resolve(res.data);
            });

        }, addRole({commit}, payload) {
            return GeneralService.addRole(payload).then(res => {
                if (res.data.data) commit('setRoles', res.data.data);
                return Promise.resolve(res.data);
            });

        },
        addRoleItemGroupDetail({commit}, payload) {
            return GeneralService.addRoleItemGroupDetail(payload).then(res => {
                if (res.data.data) commit('setPageGroups', res.data.data);
                return Promise.resolve(res.data);
            });

        },
        addUser({commit}, payload) {
            console.log(payload)
            return GeneralService.addUser(payload).then(res => {
                if (res.data.data) commit('setUsers', res.data.data);
                return Promise.resolve(res.data);
            });

        },
        updateUser({commit}, payload) {
            return GeneralService.updateUser(payload).then(res => {
                if (res.data.data) commit('setUsers', res.data.data);
                return Promise.resolve(res.data);
            });

        },


    },

    mutations: {

        updateRoleItemDetail(state, payload) {
            const prev = state.roleItems.findIndex(s => s.authorizationItemKey == payload.item.id);

            state.roleItems[prev].itemName = payload.item.name;
            state.roleItems[prev].description = payload.item.description;
            state.roleItems[prev].menuLinkUrl = payload.item.linkUrl;
            state.roleItems[prev].displayInMenu = payload.item.displayInMenu;
        },
        updateRoleItemGroupDetail(state, payload) {
            // console.log(payload);
            /* const prev = state.roleItems.findIndex(s => s.authorizationItemKey == payload.item.id);

             state.roleItems[prev].itemName = payload.item.name;
             state.roleItems[prev].description = payload.item.description;
             state.roleItems[prev].menuLinkUrl = payload.item.linkUrl;
             state.roleItems[prev].displayInMenu = payload.item.displayInMenu;*/
        },
        setRoleItems(state, roleItems) {
            state.roleItems = Object.assign([], roleItems);
        },

        setCompanies(state, companies) {
            state.companies = Object.assign([], companies);
        },
        setRoles(state, roles) {
            state.roles = Object.assign([], roles);
        },
        setUsers(state, payload) {
            state.DTPaginatedUsers.item = Object.assign([], payload.endpointResult.data);
            state.DTPaginatedUsers.dtPagination = Object.assign({}, payload.pagination);
        },
        setCurrentRoleItemDetail(state, roleItemDetail) {
            state.CurrentRoleItemDetail = Object.assign({}, roleItemDetail);
        },
        setCurrentUser(state, currUser) {
            state.CurrentUser = Object.assign({}, currUser);
        },
        setCurrentRole(state, currRole) {
            state.CurrentRole = Object.assign({}, currRole);
        },
        setPageGroups(state, PageGroups) {
            state.pageGroups = Object.assign([], PageGroups);
            state.pageGroups_forSecondPage = Object.assign([], PageGroups);
        },
        changeMenu(state, menuName) {
            const find = state.menuComponentList.find(m => m.name === menuName);
            if (find) {
                state.currentMenu = find.value;
                state.menuTitle = find.menuTitle;
                const findSub = state.menu.find(m => m.menuBilesen === find.name);
                if (findSub)
                    state.subMenu = Object.assign([], findSub.altMenuler)

                //console.log(menuName)
            } else {
                state.currentMenu = Main;
                state.menuTitle = '';
            }

        },
        updateMenu(state, payload) {
            state.menu = Object.assign([], payload);
        },
        showModal(state, payload) {
            state.showPopup = true;
            state.modal = payload;
        },
        showVideoModal(state, payload) {
            state.videoUrl = payload;
            state.showVideoPopup = true;
        },
        toggleMenu(state) {
            state.showMenu = !state.showMenu;
        },
        setSystemMessages(state, payload) {
            state.systemMessages = Object.assign([], payload);
        },
        resetState(state) {
            state.showMenu = false;
        },
        setEbysDocuments(state, payload) {
            state.ebysDocuments = Object.assign([], payload);
        },
        setYearsSelect(state, payload) {
            state.yearsSelect = Object.assign([], payload);
        },

        // IMPORTANT!!
        setTopicsByDepartmentId(state, payload) {
            state.departmentTopics = Object.assign([], payload);
        },
        setDepartments(state, payload) {
            state.departments = Object.assign([], payload);
        },
        setUserDetail(state, payload) {
            state.userDetail = Object.assign({}, payload);
        },
    },
    getters: {
        getMessage: state => id => {
            const find = state.systemMessages.find(m => m.id === id);
            if (find)
                return `${find.mesaj} (${find.id}) `
        },
        getUserCount(state) {
            return state.DTPaginatedUsers.item.length
        },
        getMessageWithParams: state => (id, text, replace) => {
            const find = state.systemMessages.find(m => m.id === id);
            if (find)
                return `${find.mesaj.replace(replace, "<b>" + text + "</b>")} (${find.id}) `
        },
    }
};
