import AuthService from '../services/auth.service';


const initialState = {
    status: {loggedIn: false},
    user: {},
    loginForm: {
        eMail: 'acagatayu@gmail.com',
        password: '123Asd123!'
    },
    menu: null,
    showOverlay: false,

}

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {


        getUserAuthorizedMenu({commit}) {
            return AuthService.getUserAuthorizedMenu().then(res => {
                if (res.data.data) {
                    commit('menuReady', res.data.data);
                }
                return Promise.resolve(res.data);
            });
        },
        login({commit}, user) {
            return AuthService.login(user).then(res => {
                if (res.data) {
                    commit('loginSuccess', res.data);
                }
                return Promise.resolve(res.data);
            });
        },


    },
    mutations: {

        menuReady(state, menuData) {
            //console.log(menuData)
            localStorage.setItem('allAuthorizedMenu', JSON.stringify(menuData));
            state.menu = menuData;

        },
        updateToken(state, token) {
            state.user.token = token;
        },
        loginSuccess(state, user) {
           // console.log(user);
            state.status.loggedIn = true;
            state.user = {...state.user, ...user};
            localStorage.setItem('token', (user.token));
            localStorage.setItem('currentUser', JSON.stringify(state.user));
        },
    }
};
