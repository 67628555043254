import { render, staticRenderFns } from "./HaberDuyIst.vue?vue&type=template&id=06a0a3cd&scoped=true&"
import script from "./HaberDuyIst.vue?vue&type=script&lang=js&"
export * from "./HaberDuyIst.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06a0a3cd",
  null
  
)

export default component.exports