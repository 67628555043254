import axios from "axios";
import authHeader from "@/services/auth-header";

class MembershipAeoService {
    savePerson(payload) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Person/save-person`,
            {
                PersonelIdNumber: payload.personelIdNumber,
                GenderId: payload.genderId,
                Name: payload.name,
                Surname: payload.surName,
                Username: payload.username,
                OtherName: payload.otherName,
                BirthDate: payload.birthDate,
                MemberRegistrationStateTypeId: payload.memberRegistrationStateTypeId,
                Cellular: payload.cellular,
                EMail: payload.eMail,
                MembershipStartDate: payload.aeoRegisterDate,
                MembershipNumber: payload.aeoRegisterNumber
            }, {
                headers: authHeader()
            });
    }

    savePersonUniversity(payload) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Person/add-person-university`,
            {
                PersonId: payload.personId,
                UniversityId: payload.universityId,
                BeginDate: payload.beginDate,
                GraduationDate: payload.graduationDate,
                Specialization: payload.specialization,
                DiplomaNumber: parseInt(payload.diplomaNumber),
                DiplomaSurname: payload.diplomaSurname,
                DiplomaGrade: payload.diplomaGrade
            }, {
                headers: authHeader()
            });
    }

    savePersonLanguage(payload) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Person/add-person-language`,
            {
                PersonId: payload.personId,
                LanguageId: payload.languageId,
                LanguageLevelId: payload.languageLevelId,
            }, {
                headers: authHeader()
            });
    }

    getPersonAddress(payload) {
        const url = `${process.env.VUE_APP_PORTAL_API_URL}Person/get-person-address`;
        const params = {
            personId: payload.personId,
            isInUse: payload.isInUse ? 'true' : 'false'
        };

        return axios.get(url, {
            headers: authHeader(),
            params: params
        });
    }

    savePersonAddress(payload) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Person/add-person-address`,
            {
                PersonId: payload.personId,
                AddressTypeId: payload.addressTypeId,
                Name: payload.name,
                Description: payload.description,
                ReceiverName: payload.receiverName,
                ReceiverSurname: payload.receiverSurname,
                Email: payload.eMail,
                MobileNumber: payload.mobileNumber,
                Address: payload.address,
                CityId: payload.cityId,
                CountryId: payload.countryId,
                PostalCode: payload.postalCode,
                District: payload.district,
                CompanyName: payload.companyName,
                TaxNumber: payload.taxNumber,
                TaxOffice: payload.taxOffice,
                Website: payload.webSite,
            }, {
                headers: authHeader()
            });

    }

    updatePersonAddress(payload) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Person/update-person-address`,
            {
                Id: payload.id,
                PersonId: payload.personId,
                AddressTypeId: payload.addressTypeId,
                Name: payload.name,
                Description: payload.description,
                ReceiverName: payload.receiverName,
                ReceiverSurname: payload.receiverSurname,
                Email: payload.eMail,
                MobileNumber: payload.mobileNumber,
                Address: payload.address,
                CityId: payload.cityId,
                CountryId: payload.countryId,
                PostalCode: payload.postalCode,
                District: payload.district,
                CompanyName: payload.companyName,
                TaxNumber: payload.taxNumber,
                TaxOffice: payload.taxOffice,
                Website: payload.webSite,
                IsInUse: payload.isInUse,
            }, {
                headers: authHeader()
            });

    }


    getPersonNote(payload) {
        const url = `${process.env.VUE_APP_PORTAL_API_URL}Person/get-person-note`;
        const params = {
            personId: payload.personId,
            isInUse: payload.isInUse ? 'true' : 'false'
        };

        return axios.get(url, {
            headers: authHeader(),
            params: params
        });
    }

    savePersonNote(payload) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Person/add-person-note`,
            {
                PersonId: payload.personId,
                Text: payload.text,
            }, {
                headers: authHeader()
            });

    }

    updatePersonNote(payload) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Person/update-person-note`,
            {
                Id: payload.id,
                PersonId: payload.personId,
                Text: payload.text,
                IsInUse: payload.isInUse,
            }, {
                headers: authHeader()
            });

    }

    updatePersonUniversity(payload) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Person/update-person-university`,
            {
                Id: payload.id,
                PersonId: payload.personId,
                UniversityId: payload.universityId,
                BeginDate: payload.beginDate,
                GraduationDate: payload.graduationDate,
                Specialization: payload.specialization,
                DiplomaNumber: parseInt(payload.diplomaNumber),
                DiplomaSurname: payload.diplomaSurname,
                DiplomaGrade: payload.diplomaGrade,
                IsInUse: payload.isInUse
            }, {
                headers: authHeader()
            });
    }


    updatePersonLanguage(payload) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Person/update-person-language`,
            {
                Id: payload.id,
                PersonId: payload.personId,
                LanguageId: payload.languageId,
                LanguageLevelId: payload.languageLevelId,
                IsInUse: payload.isInUse,

            }, {
                headers: authHeader()
            });
    }

    updatePersonState(payload) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Person/update-person-registrationState`,
            {
                PersonId: payload.personId,
                MemberRegistrationStateTypeId: payload.memberRegistrationStateTypeId,
            }, {
                headers: authHeader()
            });
    }

    searchPersonByPersonalIdNumber(payload) {
        return axios.get(`${process.env.VUE_APP_PORTAL_API_URL}Person/search-person-by-personel-id-number?personelIdNumber=` + payload,
            {
                headers: authHeader()
            });
    }

    getMemberRegistrationStates() {
        return axios.get(`${process.env.VUE_APP_PORTAL_API_URL}Person/get-member-registration-steps`,
            {
                headers: authHeader()
            });
    }

    getPersonUniversities(payload) {
        const url = `${process.env.VUE_APP_PORTAL_API_URL}Person/get-person-education`;
        const params = {
            personId: payload.personId,
            isInUse: payload.isInUse ? 'true' : 'false'
        };

        return axios.get(url, {
            headers: authHeader(),
            params: params
        });
    }

    getPersonLanguage(payload) {
        const url = `${process.env.VUE_APP_PORTAL_API_URL}Person/get-person-language`;
        const params = {
            personId: payload.personId,
            isInUse: payload.isInUse ? 'true' : 'false'
        };

        return axios.get(url, {
            headers: authHeader(),
            params: params
        });
    }

    getPersonWorkplace(payload) {
        const url = `${process.env.VUE_APP_PORTAL_API_URL}Person/get-person-workplace`;
        const params = {
            memberId: payload.memberId,
            isInUse: payload.isInUse ? 'true' : 'false'
        };

        return axios.get(url, {
            headers: authHeader(),
            params: params
        });
    }

    savePersonWorkplace(payload) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Person/add-person-workplace`,
            {
                PersonId: payload.personId,
                MemberId: payload.memberId,
                WorkplaceId: payload.workPlaceId,
                StartDate: payload.startDate,
                EndDate: payload.endDate,
                Description: payload.description,
                PositionId: payload.positionId,
            }, {
                headers: authHeader()
            });

    }

    updatePersonWorkplace(payload) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}Person/update-person-workplace`,
            {
                Id: payload.id,
                PersonId: payload.personId,
                MemberId: payload.memberId,
                WorkplaceId: payload.workPlaceId,
                StartDate: payload.startDate,
                EndDate: payload.endDate,
                Description: payload.description,
                PositionId: payload.positionId,
                IsInUse: payload.isInUse,
            }, {
                headers: authHeader()
            });

    }


    //Member File
    getPersonFile(payload) {

        const url = `${process.env.VUE_APP_PORTAL_API_URL}Person/get-person-file`;
        const params = {
            memberId: payload.memberId,
            isInUse: payload.isInUse ? 'true' : 'false'
        };

        return axios.get(url, {
            headers: authHeader(),
            params: params
        });
    }

    savePersonFile(data) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}MemberFile/add-member-file`,
            data,
            {
                headers: {
                    Token: authHeader().Token,
                    'Content-Type': 'multipart/form-data'
                }
            });

    }

    updatePersonFile(data) {
        return axios.post(`${process.env.VUE_APP_PORTAL_API_URL}MemberFile/update-member-file`,
            data, {
                headers: authHeader()
            });

    }

    //End Of Member File

}

export default new MembershipAeoService();
