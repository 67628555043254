<template>
  <div>
    <b-overlay
        :show="$store.state.dtAeo.Overlay"
        rounded="sm"
        class="d-flex flex-fill w-100"
    ></b-overlay>
    <b-modal
        id="modal-city-add-edit"
        size="lg"
        centered
        :title="modalTitle"
        ok-title="Kaydet"
        ok-variant="success"
        cancel-title="İptal"
        cancel-variant="outline-secondary"
        :busy="overlay"
        @ok.prevent="save"
        @hidden="clear">
      <div class="row">
        <div class="col-4">
          <div class="form-group">
            <label>{{ $t("common.city") }}</label>
            <!--TODO: Bence bu hata mesajları gözüktüğünde textfield'ı yukarı doğru atmaması gerekiyor-->
            <input type="text" class="form-control" v-model="form.Text"
                   :class="{ 'is-invalid': $v.form.Text.$error }"/>
            <template v-if="$v.form.Text.$error">
              <small class="text-danger" v-if="!$v.form.Text.required">
                Bu alan boş bırakılamaz.
              </small>
            </template>
            <template v-if="!this.$v.form.Text.minLength">
              <small class="text-danger" v-if="!$v.form.Text.minLength">
                Açılış tipi en az 4 Karakter olmalıdır. (4) </small
              >
            </template>
          </div>
        </div>
        <div class="col-4">
          <label>{{ $t("common.country") }}</label>

          <select
              v-select
              class="form-control"
              v-model="form.CountryId"
          >
            <option :value="null">{{ $t("choose") }}</option>
            <option
                :value="item.id"
                v-for="(item, index) in $store.state.dtAeo.countries"
                :key="index"
            >
              {{ item.text }}
            </option>
          </select>
        </div>
        <div class="col-sm-auto">
          <div class="form-group">
            <label class="d-block">Aktif</label>
            <label class="label_switch">
              <input type="checkbox" v-model="form.IsInUse"/>
              <span class="mr-2"></span>
            </label>
          </div>
        </div>
      </div>
    </b-modal>
    <div class="card-component mb-4">
      <div class="body">
        <div class="row"
        >

          <div class="col-4">
            <label>{{ $t("common.cityName") }}</label>
          </div>
          <div class="col-4">
            <label>{{ $t("common.countryName") }}</label>
          </div>

        </div>

        <div class="row">
          <div class="col-4">

            <div class="form-group">
              <input
                  type="text"
                  class="form-control"
                  v-model="searchCity"
                  maxlength="100"
              />
            </div>
          </div>
          <div class="col-4">
            <select
                v-select
                class="form-control"
                v-model="countryId"
            >
              <option :value="null">{{ $t("choose") }}</option>
              <option
                  :value="item.id"
                  v-for="(item, index) in $store.state.dtAeo.countries"
                  :key="index"
              >
                {{ item.text }}
              </option>
            </select>
          </div>
          <div class="col-2">
            <div class="form-group">
              <button class="btn btn-outline-secondary block" @click="clear('AllClear')">
                {{ $t("clear") }}
              </button>
            </div>
          </div>
          <div class="col-2">
            <div class="form-group">
              <button class="btn btn-secondary block" @click="changePage(1)">{{ $t("list") }}</button>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="table-responsive">
    <table class="table table-hover">
      <thead>
      <tr>
        <th>{{ $t("common.city") }}</th>
        <th>{{ $t("common.country") }}</th>
        <th class="fit">{{ $t("common.active") }}</th>
        <th class="fit text-right">
          <PlusCircleIcon v-b-modal.modal-city-add-edit/>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr class="cursor-pointer" @click="showEditModal(item)"
          v-for="(item, index) in $store.state.dtAeo.DTPaginatedCity.item" :key="index">
        <td>{{ item.text }}</td>
        <td v-if="$store.state.dtAeo.countries.length>0">
          {{
            $store.state.dtAeo.countries.find(
                (d) => d.id === item.countryId
            ).text
          }}
        </td>
        <td class="fit text-center">
          <font-awesome-icon icon="check" size="lg" class="text-danger" v-if="item.isInUse"/>
        </td>
        <td class="fit text-right"></td>
      </tr>
      </tbody>
    </table>
  </div>
    <div class="alert alert-danger text-center" v-if="this.$store.getters['dtAeo/getCityCount']<=0">
      {{ "Aradığınız kriterlere uygun kayıt bulunamadı." }}
    </div>
    <div class="row" v-if="$store.state.dtAeo.DTPaginatedCity.item.length > 0">
      <div class="col">
        <i class="text-secondary"
        >{{
            $store.state.dtAeo.DTPaginatedCity.dtPagination.totalItems
          }}
          kayıttan {{ (form.currentPage - 1) * form.pageSize + 1 }} -
          {{ form.currentPage * form.pageSize }} arası gösterilmektedir.</i
        >
      </div>
      <div class="col">
        <div class="row">
          <div class="col d-flex justify-content-end">
            <nav>
              <paginate
                  v-if="$store.state.dtAeo.DTPaginatedCity.item.length"
                  :page-count="
                  $store.state.dtAeo.DTPaginatedCity.dtPagination.totalPages
                "
                  :click-handler="changePage"
                  :prev-text="'Önceki'"
                  :next-text="'Sonraki'"
                  :active-class="'active'"
                  :container-class="'pagination'"
                  v-model="form.currentPage"
              >
              </paginate>
            </nav>
          </div>
          <div class="col-2">
            <select
                v-select
                class="form-control"
                v-model="form.pageSize"
                @change="changeSize"
            >
              >
              <option value="25" selected>25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {mapActions, mapGetters} from "vuex";
import PlusCircleIcon from "@/components/PlusCircleIcon";
import {required, minLength} from "vuelidate/lib/validators";

export default {
  components: {
    PlusCircleIcon,
  },
  computed: {
    ...mapGetters("general", ["getMessage"]),
    ...mapGetters("dtAeo", ["getCityCount"]),

  },
  validations: {
    form: {
      Text: {
        required,
        minLength: minLength(4)
      },
      CountryId: {
        required,
      }
    },

  },
  methods: {
    list: function () {
      this.$store.state.general.showOverlay = true;
      this.form.Text = this.searchCity
      this.form.CountryId = this.countryId
      this.getCityPaginated(this.form)
          .then((res) => {
            this.$store.state.general.showOverlay = false;
            if (res.endpointResult.data) {
              this.showError = false;
            } else {
              this.$store.state.dtAeo.cities = Object.assign({});
              this.errorMessage = "this.getMessage(118);"
              this.showError = true;
            }
          })
          .catch(() => {
            this.$store.state.general.showOverlay = false;
          });
    },
    changeSize() {
      this.$store.state.general.showOverlay = true;
      this.changePage(1)
      this.$store.state.dtAeo.duplicateForm = Object.assign({}, this.form);
      window.scrollTo(0, 0);
    },
    changePage(pageNumber) {
      this.$store.state.general.showOverlay = true;
      this.form.currentPage = pageNumber;
      this.list();
      this.$store.state.dtAeo.duplicateForm = Object.assign({}, this.form);
      window.scrollTo(0, 0);
    },

    save() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.isEditing) {
          this.updateCity(this.form).then((res) => {
            this.clear();
            this.$bvModal.hide("modal-city-add-edit");

          }).catch((reason) => {
            this.showModal({
              title: this.$t("modal.fault"),
              message: "this.getMessage(56)",
              type: this.$t("modal.type.danger")
            });
          });
        } else {
          this.addCity(this.form).then((res) => {
            this.clear();
            this.$bvModal.hide("modal-city-add-edit");

          }).catch((reason) => {
            this.showModal({
              title: this.$t("modal.fault"),
              message: "this.getMessage(56)",
              type: this.$t("modal.type.danger")
            });
          });
        }
      }
    },
    showEditModal(item) {
      this.isEditing = true;
      this.modalTitle = this.$t("common.update_city");
      this.form.Id = item.id;
      this.form.Text = item.text;
      this.form.CountryId = item.countryId
      this.form.IsInUse = item.isInUse;
      this.$bvModal.show("modal-city-add-edit");
    },
    clear() {
      this.modalTitle = this.$t("common.add_city");
      this.$v.$reset();
      this.isEditing = false;
      this.showError = false;
      this.searchCity = "";
      this.countryId = null;
      this.form = Object.assign({}, {
        Id: null,
        Text: "",
        CountryId: null,
        IsInUse: false,
        currentPage: 1,
        pageSize: 25
      })
      this.list();
    },
    ...mapActions("general", ["showModal"]),
    ...mapActions("dtAeo", ["updateCity", "addCity", "getCountry", "getCityPaginated"]),
  },
  data() {
    return {
      countryId: null,
      showOverlay: true,
      modalTitle: this.$t("common.add_city"),
      overlay: false,
      isEditing: false,
      searchCity: "",
      showError: false,
      form: {
        Id: null,
        Text: "",
        CountryId: null,
        IsInUse: false,
        currentPage: 1,
        pageSize: 25
      },
    };
  },
  created() {
    this.$root.$refs.City = this;
    this.showOverlay = false
  },


  watch: {
    lengthOfCity(newCount, oldCount) {
      this.showError = true;
    }
  }
};
</script>

<style>
tr.v-data-table__selected {
  background: #7d92f5 !important;
}
.block {
  display: block;
  width: 100%;
  border: none;
  background-color: #04AA6D;
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
}
</style>