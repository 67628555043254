<template>
  <div class="standart-page">
    <h3 class="title">{{ $t('common.settings').toUpperCase() }}</h3>

    <div class="accordion" role="tablist">
      <b-card no-body>
        <b-card-header header-tag="header" role="tab" class="mr-0" v-b-toggle.accordion-1>
          <b-button class="w-100 text-left d-flex justify-content-between btn btn-link font-weight-bold"
                    variant="null">
            {{ $t('common.cities') }}

            <span class="when-opened"><font-awesome-icon icon="chevron-down"/></span>
            <span class="when-closed"><font-awesome-icon icon="chevron-right"/></span>
          </b-button>
        </b-card-header>
        <b-collapse @show="new SwitchComponent(1)" id="accordion-1" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <City/>
          </b-card-body>
        </b-collapse>
      </b-card>
      <b-card no-body>
        <b-card-header header-tag="header" role="tab" class="mr-0" v-b-toggle.accordion-2>
          <b-button class="w-100 text-left d-flex justify-content-between btn btn-link font-weight-bold"
                    variant="null">
            {{ $t('common.countries') }}

            <span class="when-opened"><font-awesome-icon icon="chevron-down"/></span>
            <span class="when-closed"><font-awesome-icon icon="chevron-right"/></span>
          </b-button>
        </b-card-header>
        <b-collapse @show="new SwitchComponent(2) " id="accordion-2" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <Country/>
          </b-card-body>
        </b-collapse>
      </b-card>
      <b-card no-body>
        <b-card-header header-tag="header" role="tab" class="mr-0" v-b-toggle.accordion-3>
          <b-button class="w-100 text-left d-flex justify-content-between btn btn-link font-weight-bold"
                    variant="null">
            {{ $t('common.districts') }}

            <span class="when-opened"><font-awesome-icon icon="chevron-down"/></span>
            <span class="when-closed"><font-awesome-icon icon="chevron-right"/></span>
          </b-button>
        </b-card-header>
        <b-collapse @show="new SwitchComponent(3) " id="accordion-3" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <District/>
          </b-card-body>
        </b-collapse>
      </b-card>
      <b-card no-body>
        <b-card-header header-tag="header" role="tab" class="mr-0" v-b-toggle.accordion-4>
          <b-button class="w-100 text-left d-flex justify-content-between btn btn-link font-weight-bold"
                    variant="null">
            {{ $t('common.addressType') }}

            <span class="when-opened"><font-awesome-icon icon="chevron-down"/></span>
            <span class="when-closed"><font-awesome-icon icon="chevron-right"/></span>
          </b-button>
        </b-card-header>
        <b-collapse @show="new SwitchComponent(4) " id="accordion-4" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <AddressType/>
          </b-card-body>
        </b-collapse>
      </b-card>
      <b-card no-body>
        <b-card-header header-tag="header" role="tab" class="mr-0" v-b-toggle.accordion-5>
          <b-button class="w-100 text-left d-flex justify-content-between btn btn-link font-weight-bold"
                    variant="null">
            {{ $t('common.gender') }}

            <span class="when-opened"><font-awesome-icon icon="chevron-down"/></span>
            <span class="when-closed"><font-awesome-icon icon="chevron-right"/></span>
          </b-button>
        </b-card-header>
        <b-collapse @show="new SwitchComponent(5) " id="accordion-5" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <Gender/>
          </b-card-body>
        </b-collapse>
      </b-card>
      <b-card no-body>
        <b-card-header header-tag="header" role="tab" class="mr-0" v-b-toggle.accordion-6>
          <b-button class="w-100 text-left d-flex justify-content-between btn btn-link font-weight-bold"
                    variant="null">
            {{ $t('common.language') }}

            <span class="when-opened"><font-awesome-icon icon="chevron-down"/></span>
            <span class="when-closed"><font-awesome-icon icon="chevron-right"/></span>
          </b-button>
        </b-card-header>
        <b-collapse @show="new SwitchComponent(6) " id="accordion-6" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <Language/>
          </b-card-body>
        </b-collapse>
      </b-card>
      <b-card no-body>
        <b-card-header header-tag="header" role="tab" class="mr-0" v-b-toggle.accordion-7>
          <b-button class="w-100 text-left d-flex justify-content-between btn btn-link font-weight-bold"
                    variant="null">
            {{ $t('common.title') }}

            <span class="when-opened"><font-awesome-icon icon="chevron-down"/></span>
            <span class="when-closed"><font-awesome-icon icon="chevron-right"/></span>
          </b-button>
        </b-card-header>
        <b-collapse @show="new SwitchComponent(7) " id="accordion-7" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <Title/>
          </b-card-body>
        </b-collapse>
      </b-card>
      <b-card no-body>
        <b-card-header header-tag="header" role="tab" class="mr-0" v-b-toggle.accordion-8>
          <b-button class="w-100 text-left d-flex justify-content-between btn btn-link font-weight-bold"
                    variant="null">
            {{ $t('common.university') }}

            <span class="when-opened"><font-awesome-icon icon="chevron-down"/></span>
            <span class="when-closed"><font-awesome-icon icon="chevron-right"/></span>
          </b-button>
        </b-card-header>
        <b-collapse @show="new SwitchComponent(8) " id="accordion-8" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <University/>
          </b-card-body>
        </b-collapse>
      </b-card>
      <b-card no-body>
        <b-card-header header-tag="header" role="tab" class="mr-0" v-b-toggle.accordion-9>
          <b-button class="w-100 text-left d-flex justify-content-between btn btn-link font-weight-bold"
                    variant="null">
            {{ $t('common.workplaceType') }}

            <span class="when-opened"><font-awesome-icon icon="chevron-down"/></span>
            <span class="when-closed"><font-awesome-icon icon="chevron-right"/></span>
          </b-button>
        </b-card-header>
        <b-collapse @show="new SwitchComponent(9) " id="accordion-9" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <WorkplaceType/>
          </b-card-body>
        </b-collapse>
      </b-card>
      <b-card no-body>
        <b-card-header header-tag="header" role="tab" class="mr-0" v-b-toggle.accordion-10>
          <b-button class="w-100 text-left d-flex justify-content-between btn btn-link font-weight-bold"
                    variant="null">
            {{ $t('common.publishPlace') }}

            <span class="when-opened"><font-awesome-icon icon="chevron-down"/></span>
            <span class="when-closed"><font-awesome-icon icon="chevron-right"/></span>
          </b-button>
        </b-card-header>
        <b-collapse @show="new SwitchComponent(10) " id="accordion-10" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <PublishPlace/>
          </b-card-body>
        </b-collapse>
      </b-card>
      <b-card no-body>
        <b-card-header header-tag="header" role="tab" class="mr-0" v-b-toggle.accordion-11>
          <b-button class="w-100 text-left d-flex justify-content-between btn btn-link font-weight-bold"
                    variant="null">
            {{ $t('common.pageType') }}

            <span class="when-opened"><font-awesome-icon icon="chevron-down"/></span>
            <span class="when-closed"><font-awesome-icon icon="chevron-right"/></span>
          </b-button>
        </b-card-header>
        <b-collapse @show="new SwitchComponent(11) " id="accordion-11" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <PageType/>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
import City from "@/components/settings/City";
import Country from "@/components/settings/Country";
import District from "@/components/settings/District";
import AddressType from "@/components/settings/AddressType";
import Gender from "@/components/settings/Gender";
import Language from "@/components/settings/Language";
import Title from "@/components/settings/Title";
import University from "@/components/settings/University";
import WorkplaceType from "@/components/settings/WorkplaceType";
import PublishPlace from "@/components/settings/PublishPlace";
import PageType from "@/components/settings/PageType";

import {mapActions} from "vuex";

export default {
  name: "SettingsView.vue",
  components: {
    City,
    Country,
    District,
    AddressType,
    Gender,
    Language,
    Title,
    University,
    WorkplaceType,
    PublishPlace,
    PageType
  },
  data() {
    return {}
  },

  methods: {
    ...mapActions("dtAeo", ["getCountryPaginated", "getCountry"
      , "getCityPaginated", "getCity"
      , "getDistrictPaginated"
      , "getAddressTypePaginated"
      , "getGenderPaginated"
      , "getLanguagePaginated"
      , "getTitlePaginated"
      , "getUniversityPaginated"
      , "getWorkplaceTypePaginated"
      , "getPublishPlacePaginated"
      , "getPageTypePaginated"
    ]),

    async SwitchComponent(id) {
      switch (id) {
        case 1:
          this.$store.state.dtAeo.Overlay = true
          await this.getCountry();
          this.$root.$refs.City.list();
          break;
        case 2:
          this.$store.state.dtAeo.Overlay = true
          this.$root.$refs.Country.list();
          break;
        case 3:
          this.$store.state.dtAeo.Overlay = true
          await this.getCity();
          this.$root.$refs.District.list();
          break;
        case 4:
          this.$store.state.dtAeo.Overlay = true
          this.$root.$refs.AddressType.list();
          break;
        case 5:
          this.$store.state.dtAeo.Overlay = true
          this.$root.$refs.Gender.list();
          break;
        case 6:
          this.$store.state.dtAeo.Overlay = true
          this.$root.$refs.Language.list();
          break;
        case 7:
          this.$store.state.dtAeo.Overlay = true
          this.$root.$refs.Title.list();
          break;
        case 8:
          this.$store.state.dtAeo.Overlay = true
          this.$root.$refs.University.list();
          break;
        case 9:
          this.$store.state.dtAeo.Overlay = true
          this.$root.$refs.WorkplaceType.list();
          break;
        case 10:
          this.$store.state.dtAeo.Overlay = true
          this.$root.$refs.PublishPlace.list();
          break;
        case 11:
          this.$store.state.dtAeo.Overlay = true
          this.$root.$refs.PageType.list();
          break;
      }
    }
  }
}
</script>

<style scoped>

</style>